/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ActionType } from "typesafe-actions";
import { createAction } from "typesafe-actions";

const errorActions = {
    raiseUnhandledError: createAction(
        "UNHANDLED_ERROR_OCCURRED",
        (resolve) =>
            (error: Error, id: string = null!) =>
                resolve({ error, id })
    ),
    clearUnhandledErrors: createAction("UNHANDLED_ERROR_CLEARED"),
};

const { raiseUnhandledError, clearUnhandledErrors } = errorActions;

export type ErrorAction = ActionType<typeof errorActions>;

export { raiseUnhandledError, clearUnhandledErrors };

export default errorActions;
