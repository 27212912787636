import React from "react";
import type { GitRefResource } from "~/client/resources/index";
import { isProtectedBranch } from "~/client/resources/index";
import { isGitCommit, isGitTag } from "~/client/resources/versionControlledResource";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";

type Props = {
    gitRef?: GitRefResource | undefined;
};

const CannotCommitCallout = (props: Props) => {
    if (!props.gitRef) {
        return <></>;
    }

    const renderWithTypeName = (canonicalName: string, type: string) => (
        <FeatureToggleVisibility toggle={"BranchProtectionsFeatureToggle"}>
            <Callout title={`Cannot commit to ${canonicalName}`} type={CalloutType.Warning} canClose={false}>
                {canonicalName} is a {type} and cannot be committed to.
            </Callout>
        </FeatureToggleVisibility>
    );

    if (isProtectedBranch(props.gitRef)) {
        return renderWithTypeName(props.gitRef.CanonicalName, "protected branch");
    } else if (isGitTag(props.gitRef.CanonicalName)) {
        return renderWithTypeName(props.gitRef.CanonicalName, "tag");
    } else if (isGitCommit(props.gitRef.CanonicalName)) {
        return renderWithTypeName(props.gitRef.CanonicalName, "commit");
    } else {
        return <></>;
    }
};

export default CannotCommitCallout;
