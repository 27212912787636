/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { AccountResource } from "~/client/resources";
import { AccountType, GetPrimaryPackageReference, InitialisePrimaryPackageReference, Permission, SetPrimaryPackageReference } from "~/client/resources";
import { ActionExecutionLocation } from "~/client/resources/actionExecutionLocation";
import type ActionProperties from "~/client/resources/actionProperties";
import { repository } from "~/clientInstance";
import AzureWebSiteSelector from "~/components/Actions/azure/AzureWebSiteSelector";
import AzureWebSlotSelector from "~/components/Actions/azure/AzureWebSlotSelector";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { BundledToolsProperties } from "~/components/BundledTools/BundledToolsEditBase";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import { BoundAccountSelect } from "~/components/form/AccountSelect/AccountSelect";
import { AccountSelectionSummary } from "~/components/form/AccountSelect/AccountSelectionSummary";
import isBound from "~/components/form/BoundField/isBound";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Summary, { OverflowSummary, PlaceholderSummary } from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { CalloutType, default as Callout } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundStringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "../../KeyAccessProvider/types";
import PermissionCheck, { isAllowed } from "../../PermissionCheck/PermissionCheck";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import { AzureBundledToolsForCustomScriptsEdit } from "./azureBundledTools";

interface AzureWebAppActionSummarySummaryState {
    feedName: string;
}

class AzureWebAppActionSummary extends BaseComponent<ActionSummaryProps, AzureWebAppActionSummarySummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Deploy an Azure Web App"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface AzureWebAppProperties extends BundledToolsProperties {
    "Octopus.Action.Azure.IsLegacyMode": string;
    "Octopus.Action.Azure.AccountId": string;
    "Octopus.Action.Azure.WebAppName": string;
    "Octopus.Action.Azure.ResourceGroupName": string;
    "Octopus.Action.Azure.DeploymentSlot": string;
    "Octopus.Action.Azure.PhysicalPath": string;
    "Octopus.Action.Azure.RemoveAdditionalFiles": string;
    "Octopus.Action.Azure.PreserveAppData": string;
    "Octopus.Action.Azure.AppOffline": string;
    "Octopus.Action.Azure.UseChecksum": string;
}

interface AzureWebAppEditState {
    accounts: AccountResource[];
    accountIsBound: boolean;
    webAppIsBound: boolean;
}

type AzureWebAppAccountSummaryProps = {
    account: AccountResource;
    webAppName: string | null;
    webAppSlot: string | null;
    resourceGroup: string | null;
};

const AzureWebAppAccountSummary: React.FC<AzureWebAppAccountSummaryProps> = ({ webAppSlot, webAppName, resourceGroup, account }) => {
    return (
        <OverflowSummary>
            <span>
                Account <strong>{account.Name}</strong> will be used to deploy to Web App <strong>{webAppName}</strong>
            </span>
            {webAppSlot && (
                <span>
                    , deployment slot <strong>{webAppSlot}</strong>,
                </span>
            )}
            {resourceGroup && (
                <span>
                    {" "}
                    in resource group <strong>{resourceGroup}</strong>
                </span>
            )}
        </OverflowSummary>
    );
};

type AzureWebAppEditProps = ActionEditProps<AzureWebAppProperties>;
type AzureWebAppEditInternalProps = AzureWebAppEditProps & ActionWithFeeds & KeyedItemProps;

export const AzureWebAppEdit: React.FC<AzureWebAppEditProps> = (props) => {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const itemKey = useKeyedItemAccess();

    return <AzureWebAppEditInternal {...props} itemKey={itemKey} feeds={feeds} refreshFeeds={refreshFeeds} />;
};

class AzureWebAppEditInternal extends BaseComponent<AzureWebAppEditInternalProps, AzureWebAppEditState> {
    constructor(props: AzureWebAppEditInternalProps) {
        super(props);
        this.state = {
            accounts: [],
            accountIsBound: isBound(props.properties["Octopus.Action.Azure.AccountId"], false),
            webAppIsBound: isBound(props.properties["Octopus.Action.Azure.WebAppName"], false),
        };
    }

    componentDidUpdate(prevProps: AzureWebAppEditInternalProps) {
        if (
            this.props.properties["Octopus.Action.Azure.AccountId"] !== prevProps.properties["Octopus.Action.Azure.AccountId"] ||
            this.props.properties["Octopus.Action.Azure.IsLegacyMode"] !== prevProps.properties["Octopus.Action.Azure.IsLegacyMode"]
        ) {
            this.props.refreshRunOn && this.props.refreshRunOn();
        }
    }

    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds, this.props.itemKey), true);

        await this.props.doBusyTask(async () => {
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"] && isAllowed({ permission: Permission.AccountView, wildcard: true })) {
                this.setState({
                    accounts: await repository.Accounts.all(),
                });
            }

            const properties: ActionProperties = {};
            if (this.props.properties["Octopus.Action.Azure.UseChecksum"] === undefined) {
                properties["Octopus.Action.Azure.UseChecksum"] = "False";
            }
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"]) {
                properties["Octopus.Action.Azure.IsLegacyMode"] = "True";
            }

            this.convertAzureWebAppNameAndSlot();

            this.props.setProperties(properties, true);
        });
    }

    convertAzureWebAppNameAndSlot() {
        let webAppName = this.props.properties["Octopus.Action.Azure.WebAppName"];
        let slotName = this.props.properties["Octopus.Action.Azure.DeploymentSlot"];

        if (!webAppName) {
            return;
        }

        const slashPosition = webAppName.indexOf("/");
        const parenPosition = webAppName.indexOf("(");
        if (slashPosition > 0) {
            slotName = webAppName.substring(slashPosition + 1).trim();
            webAppName = webAppName.substring(0, slashPosition).trim();
        } else if (parenPosition > 0) {
            slotName = webAppName
                .substring(parenPosition + 1)
                .replace(")", "")
                .trim();
            webAppName = webAppName.substring(0, parenPosition).trim();
        }

        this.props.properties["Octopus.Action.Azure.WebAppName"] = webAppName;
        this.props.properties["Octopus.Action.Azure.DeploymentSlot"] = slotName;
    }

    accountSummary() {
        const webAppName = this.props.properties["Octopus.Action.Azure.WebAppName"];
        const resourceGroup = this.props.properties["Octopus.Action.Azure.ResourceGroupName"];
        const webAppSlot = this.props.properties["Octopus.Action.Azure.DeploymentSlot"];

        return (
            <AccountSelectionSummary
                accountIdOrName={this.props.properties["Octopus.Action.Azure.AccountId"]}
                accounts={this.state.accounts}
                renderFound={(account) => <AzureWebAppAccountSummary account={account} resourceGroup={resourceGroup} webAppName={webAppName} webAppSlot={webAppSlot} />}
                renderMissing={() => <PlaceholderSummary>No account has been selected</PlaceholderSummary>}
            />
        );
    }

    getAccountId = () => {
        return isBound(this.props.properties["Octopus.Action.Azure.AccountId"]) ? undefined : this.props.properties["Octopus.Action.Azure.AccountId"];
    };

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const isLegacyActionType = this.isLegacyMode();

        return (
            <div>
                <AzureBundledToolsForCustomScriptsEdit {...this.props} />

                <FormSectionHeading title="Package" />

                <ExpandableFormSection
                    errorKey="package"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds, this.props.itemKey)}
                    help={<span>Select the package containing your application.</span>}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.props.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                </ExpandableFormSection>

                {isLegacyActionType && (
                    <div>
                        <FormSectionHeading title="Azure" />
                        <PermissionCheck
                            permission={Permission.AccountView}
                            wildcard={true}
                            alternate={
                                <Callout type={CalloutType.Information} title={"Permission required"}>
                                    The {Permission.AccountView} permission is required to change the Azure settings.
                                </Callout>
                            }
                        >
                            <Callout type={CalloutType.Warning} title={"Legacy mode"}>
                                <strong>
                                    This step is referencing an Azure Account directly, instead of referencing an <ExternalLink href="AzureTargets">Azure Web Application Target</ExternalLink> through Roles. Please read{" "}
                                    <ExternalLink href="AzureTargets">our documentation</ExternalLink> to learn how to get started with Azure Targets.
                                </strong>
                            </Callout>
                            <ExpandableFormSection
                                errorKey="Octopus.Action.Azure.AccountId|Octopus.Action.Azure.WebAppName|Octopus.Action.Azure.ResourceGroupName"
                                isExpandedByDefault={this.props.expandedByDefault}
                                title="Account and Web App"
                                summary={this.accountSummary()}
                                help="Select the Azure account and Web App for the deployment."
                            >
                                <BoundAccountSelect
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                    }}
                                    resetValue={properties["Octopus.Action.Azure.AccountId"]}
                                    label="Account"
                                    value={properties["Octopus.Action.Azure.AccountId"]}
                                    isBound={this.state.accountIsBound}
                                    onIsBoundChanged={(value: boolean) => this.setState({ accountIsBound: value })}
                                    type={[AccountType.AzureServicePrincipal]}
                                    allowClear={true}
                                    onChange={(x) => {
                                        this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x });
                                        if (!x) {
                                            // This is a key field in determining legacy Azure steps, so if this is cleared, also
                                            // clear related data that is now contributed by the target.
                                            this.clearLegacyModeProps();
                                        }
                                    }}
                                    error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                                    items={this.state.accounts}
                                    onRequestRefresh={this.refreshAccounts}
                                />
                                <AzureWebSiteSelector
                                    doBusyTask={this.props.doBusyTask}
                                    webAppName={properties["Octopus.Action.Azure.WebAppName"]}
                                    webAppNameError={this.props.getFieldError("Octopus.Action.Azure.WebAppName")}
                                    resourceGroupName={properties["Octopus.Action.Azure.ResourceGroupName"]}
                                    resourceGroupError={this.props.getFieldError("Octopus.Action.Azure.ResourceGroupName")}
                                    onWebAppNameChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.WebAppName"]: x })}
                                    onResourceGroupChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.ResourceGroupName"]: x })}
                                    projectId={this.props.projectId!}
                                    accountId={this.getAccountId()!}
                                    isAccountBound={this.state.accountIsBound}
                                    onIsBoundChanged={(value: boolean) => this.setState({ webAppIsBound: value })}
                                    localNames={this.props.localNames!}
                                />
                                <AzureWebSlotSelector
                                    doBusyTask={this.props.doBusyTask}
                                    projectId={this.props.projectId!}
                                    accountId={this.getAccountId()!}
                                    webAppName={properties["Octopus.Action.Azure.WebAppName"]}
                                    resourceGroupName={properties["Octopus.Action.Azure.ResourceGroupName"]}
                                    webAppSlotName={properties["Octopus.Action.Azure.DeploymentSlot"]}
                                    webAppSlotNameError={this.props.getFieldError("Octopus.Action.Azure.DeploymentSlot")}
                                    onWebAppSlotNameChanged={(x) => this.props.setProperties({ ["Octopus.Action.Azure.DeploymentSlot"]: x })}
                                    isAccountBound={this.state.accountIsBound}
                                    isWebAppBound={this.state.webAppIsBound}
                                    localNames={this.props.localNames!}
                                />
                            </ExpandableFormSection>
                        </PermissionCheck>
                    </div>
                )}

                <FormSectionHeading title="Deployment" />
                {!isLegacyActionType && (
                    <ExpandableFormSection
                        errorKey="Octopus.Action.Azure.DeploymentSlot"
                        title="Deployment Slot"
                        summary={
                            properties["Octopus.Action.Azure.DeploymentSlot"]
                                ? Summary.summary(
                                      <span>
                                          The deployment slot is <strong>{properties["Octopus.Action.Azure.DeploymentSlot"]}</strong>
                                      </span>
                                  )
                                : Summary.placeholder("No deployment slot defined")
                        }
                        help={"Enter the deployment slot (optional)."}
                    >
                        <VariableLookupText
                            localNames={this.props.localNames}
                            value={properties["Octopus.Action.Azure.DeploymentSlot"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.DeploymentSlot"]: x })}
                            error={this.props.getFieldError("Octopus.Action.Azure.DeploymentSlot")}
                            label="Deployment Slot"
                        />
                        <Note>
                            Slots let you deploy different versions of your web app to different URLs. You can test a certain version and then swap content and configuration between slots.
                            <strong>The slot will only apply if you have selected roles that scope to Azure Web App targets that represent the base App Service URL (and not the deployment slot URLs).</strong>
                        </Note>
                    </ExpandableFormSection>
                )}
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.PhysicalPath"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Physical Path"
                    summary={
                        properties["Octopus.Action.Azure.PhysicalPath"]
                            ? Summary.summary(
                                  <span>
                                      The physical path is <strong>{properties["Octopus.Action.Azure.PhysicalPath"]}</strong>
                                  </span>
                              )
                            : Summary.placeholder("No physical path has been entered")
                    }
                    help={"Enter the physical path to the site root."}
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={properties["Octopus.Action.Azure.PhysicalPath"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.PhysicalPath"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Azure.PhysicalPath")}
                        label="Physical path"
                    />
                    <Note>
                        Physical path relative to site root. e.g. 'foo' will deploy to 'site\wwwroot\foo'.
                        <br />
                        Leave blank to deploy to root.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.RemoveAdditionalFiles"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Remove Additional Files"
                    summary={
                        properties["Octopus.Action.Azure.RemoveAdditionalFiles"] && properties["Octopus.Action.Azure.RemoveAdditionalFiles"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      Files in the destination that are not part of the deployment <strong>will</strong> be removed
                                  </span>
                              )
                            : Summary.default("Files in the destination that are not part of the deployment will not be removed")
                    }
                    help={"Select to remove additional files on the destination that are not part of the deployment."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.RemoveAdditionalFiles"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.RemoveAdditionalFiles"]: x })}
                        label="Remove additional files"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.PreserveAppData"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Preserve App_Data"
                    summary={
                        properties["Octopus.Action.Azure.PreserveAppData"] && properties["Octopus.Action.Azure.PreserveAppData"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      Files in the App_Data folder <strong>will not</strong> be removed
                                  </span>
                              )
                            : Summary.default("Files in the App_Data folder will be removed")
                    }
                    help={"Select to preserve files in the App_Data folder before deployment."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.PreserveAppData"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.PreserveAppData"]: x })}
                        label="Preserve App_Data"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.AppOffline"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Enable AppOffline"
                    summary={
                        properties["Octopus.Action.Azure.AppOffline"] && properties["Octopus.Action.Azure.AppOffline"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      The app domain <strong>will</strong> be safely brought down using a blank app_offline.html
                                  </span>
                              )
                            : Summary.default("The app domain will not be safely brought down using a blank app_offline.html")
                    }
                    help={"Select to safely bring down the app domain with app_offline.html in root"}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.AppOffline"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.AppOffline"]: x })}
                        label="Enable AppOffline"
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.UseChecksum"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="File Comparison Method"
                    summary={
                        properties["Octopus.Action.Azure.UseChecksum"] && properties["Octopus.Action.Azure.UseChecksum"] !== "False"
                            ? Summary.summary(
                                  <span>
                                      File <strong>checksums</strong> will be used to compare files (may increase deployment time)
                                  </span>
                              )
                            : Summary.default("File timestamps will be used to compare files")
                    }
                    help={"Select which method will be used to determine which files will be updated during deployment."}
                >
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.UseChecksum"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Azure.UseChecksum"]: x })}
                        label="File comparison method"
                    >
                        <RadioButton label="Timestamp" value="False" isDefault />
                        <Note>Use file timestamps to compare files</Note>
                        <RadioButton label="Checksum" value="True" />
                        <Note>
                            Use file checksums to compare files
                            <br /> <em>Warning:</em> the checksum method may cause deployment times to increase significantly
                        </Note>
                    </BoundStringRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.IsLegacyMode"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Enable Legacy Mode"
                    summary={
                        properties["Octopus.Action.Azure.IsLegacyMode"] === "True"
                            ? Summary.summary(
                                  <span>
                                      <strong>Enabled</strong>: Account-related properties are configured on this step
                                  </span>
                              )
                            : Summary.default(<span>Not enabled: Account-related properties are configured on the Deployment Target</span>)
                    }
                    help={"Select legacy mode if you wish to configure account-related properties on the step and not through Azure Targets."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.IsLegacyMode"]}
                        onChange={async (x) => {
                            if (x === "True") {
                                this.props.setProperties({
                                    ["Octopus.Action.Azure.IsLegacyMode"]: "True",
                                });
                                await this.refreshAccounts();
                            } else {
                                this.clearLegacyModeProps();
                            }
                        }}
                        label="Enable Legacy Mode"
                    />
                    <Callout type={CalloutType.Warning} title={"Not recommended"}>
                        Toggling this <strong>on</strong> will allow account-related properties on the step <strong>(not recommended)</strong>.<br />
                        Toggling this <strong>off</strong> will clear the account-related properties on this step and allow these to be configured from your Deployment Targets.
                    </Callout>
                </ExpandableFormSection>
            </div>
        );
    }

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };

    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties && !!(properties["Octopus.Action.Azure.AccountId"] || properties["Octopus.Action.Azure.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }

    private clearLegacyModeProps = () => {
        this.props.setProperties(
            {
                ["Octopus.Action.Azure.IsLegacyMode"]: null!,
                ["Octopus.Action.Azure.AccountId"]: null!,
                ["Octopus.Action.Azure.WebAppName"]: null!,
                ["Octopus.Action.Azure.ResourceGroupName"]: null!,
                ["Octopus.Action.Azure.DeploymentSlot"]: null!,
            },
            false
        );
    };
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureWebApp",
    summary: (properties, targetRolesAsCSV, packages) => <AzureWebAppActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: AzureWebAppEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        // Azure steps (pre 2018.5) allowed you to select accounts directly on the step, making target selection optional.
        const isLegacyActionType = !!(action ? action.Properties["Octopus.Action.Azure.AccountId"] || action.Properties["Octopus.Action.Azure.IsLegacyMode"] === "True" : null);
        const result = isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
        return result;
    },
    hasPackages: (action) => true,
    features: {
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.SelectPowerShellEditionForWindows",
        ],
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
    targetDiscoveryCloudConnectionProviders: () => ["Azure"],
});
