import { cloneDeep } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { Permission, TenantedDeploymentMode, ProcessType } from "~/client/resources";
import type { ProjectResource, RunbookProcessResource, ConnectivityPolicy, EnvironmentResource, RunbookResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { RoleChip } from "~/components/Chips";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import Markdown from "~/components/Markdown";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import type { SpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/SpaceAwareNavigation";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, MarkdownEditor, Summary, Text, required, StringRadioButtonGroup, RadioButton, Note, FormSectionHeading } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import FailureMode from "../Releases/Deployments/FailureMode";
import AddRunbook from "./AddRunbook";
import DeleteRunbook from "./DeleteRunbook";
import { RunbooksPaperLayout } from "./Layouts";
import RunbooksFormPaperLayout from "./Layouts/RunbooksFormPaperLayout";
import RunNowButton from "./RunNowButton";
import { useRunbookContext } from "./RunbookContext";
import type { RunbookContextProps } from "./RunbookContext";
import RunbookEnvironmentScopeSelector from "./RunbookEnvironmentScopeSelector";
import { RunbookRetentionPolicyControl } from "./RunbookRetentionPolicy";
import { DeploymentModelType } from "./RunbookRunNowLayout";

interface RunbookSettingsLayoutState extends FormBaseComponentState<RunbookResource> {
    redirectTo: string | null;
    canDelete: boolean;
    deleteRunbookCommitMessage: string;
    commitMessage: CommitMessageWithDetails;
}

class TenantedDeploymentModeRadioButtonGroup extends RadioButtonGroup<TenantedDeploymentMode> {}

interface RunbookSettingsLayoutProps {
    runbookId: string;
    projectContext: ProjectContextProps;
    runbookContext: RunbookContextProps;
    isMultiTenancyEnabled: boolean;
    history: SpaceAwareNavigation;
}

interface InitialData {
    machineRoles: string[];
    environments: EnvironmentResource[];
    project: ProjectResource;
    runbook: RunbookResource;
    steps: RunbookProcessResource | "not implemented yet";
}

type RunbookSettingsLayoutInternalProps = {
    initialData: InitialData;
} & RunbookSettingsLayoutProps;

const Title = "Settings";
const RunbookSettingsFormPage = FormPage<InitialData>();

const RunbookSettingsLayout: React.FC<RunbookSettingsLayoutProps> = (props: RunbookSettingsLayoutProps) => {
    return (
        <RunbookSettingsFormPage
            title={Title}
            load={async () => {
                const project = props.projectContext.state && props.projectContext.state.model;
                if (!project) {
                    throw new Error("Project must be loaded.");
                }

                const runbook = await repository.Runbooks.get(props.runbookId);
                const steps = repository.RunbookProcess.get(runbook.RunbookProcessId);

                const machineRoles = repository.MachineRoles.all();
                const environments = repository.Environments.all();

                return {
                    machineRoles: await machineRoles,
                    environments: await environments,
                    project,
                    runbook,
                    steps: await steps,
                };
            }}
            renderWhenLoaded={(initialData) => <RunbookSettingsLayoutInternal initialData={initialData} {...props} />}
            renderAlternate={(args) => <RunbooksPaperLayout title={Title} breadcrumbTitle={"Runbooks"} {...args} />}
        />
    );
};

class RunbookSettingsLayoutInternal extends FormBaseComponent<RunbookSettingsLayoutInternalProps, RunbookSettingsLayoutState, RunbookResource> {
    constructor(props: RunbookSettingsLayoutInternalProps) {
        super(props);
        this.state = {
            model: cloneDeep(this.props.initialData.runbook),
            cleanModel: cloneDeep(this.props.initialData.runbook),
            redirectTo: null,
            canDelete: false,
            deleteRunbookCommitMessage: "Delete Runbook",
            commitMessage: { summary: "", details: "" },
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }
        const { model: project, gitRef } = this.props.projectContext.state;
        const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook);
        const overFlowActions = [];

        const CloneDialog = () => (
            <AddRunbook
                projectId={this.props.initialData.project.Id}
                onProcessCreated={async (id) => {
                    this.props.history.navigate(routeLinks.project(this.props.initialData.project.Slug).operations.runbook(id).root);
                }}
                cloneId={this.state.model.Id}
            />
        );
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
        overFlowActions.push(
            OverflowMenuItems.deleteItem(
                "Delete",
                "Are you sure you want to delete this Runbook?",
                this.handleDeleteConfirm,
                (dialogDoBusyTask) => <DeleteRunbook runbookName={this.state.model.Name} onChange={this.onDeleteChanged} onCommitMessageUpdate={(message) => this.setState({ deleteRunbookCommitMessage: message })} />,
                this.deletePermission(),
                !this.state.canDelete
            )
        );
        overFlowActions.push(
            OverflowMenuItems.navItem("View Snapshots", routeLinks.project(this.props.initialData.project.Slug).operations.runbook(this.state.model.Id).runbookSnapshots, {
                permission: Permission.RunbookView,
                project: this.props.initialData.project.Id,
                projectGroup: this.props.initialData.project.ProjectGroupId,
                wildcard: true,
            })
        );
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(this.props.initialData.project.Id, this.state.model.Id), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);

        return (
            <RunbooksFormPaperLayout
                busy={this.state.busy}
                errors={this.errors}
                title={this.state.model ? this.state.model.Name : StringHelper.ellipsis}
                breadcrumbTitle={"Runbooks"}
                breadcrumbPath={routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbooks}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.saveChanges}
                savePermission={this.editPermission()}
                saveText="Runbook updated"
                secondaryAction={<RunNowButton isDisabled={this.props.initialData.steps === "not implemented yet" || this.props.initialData.steps?.Steps.length === 0} />}
                overFlowActions={overFlowActions}
                saveButtonBusyLabel={"Saving"}
                saveButtonLabel={"Save"}
                confirmNavigateSaveLabel={`Save Changes`}
            >
                {this.state.model && (
                    <>
                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for the Runbook")}
                            help="Enter a name for the Runbook"
                        >
                            <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" validate={required("Please enter a name")} error={this.getFieldError("Name")} autoFocus={true} />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="Description"
                            title="Description"
                            summary={this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided")}
                            help="Enter a description for the Runbook"
                        >
                            <MarkdownEditor value={this.state.model.Description} label="Description" onChange={(Description) => this.setModelState({ Description })} />
                        </ExpandableFormSection>
                        <FormSectionHeading title="Run Settings" />
                        <RunbookEnvironmentScopeSelector
                            environments={this.props.initialData.environments}
                            environmentScope={this.state.model.EnvironmentScope}
                            inclusiveEnvironments={this.state.model.Environments}
                            onEnvironmentScopeChanged={(EnvironmentScope) => this.setModelState({ EnvironmentScope })}
                            onEnvironmentsChanged={(Environments) => this.setModelState({ Environments })}
                        />
                        {
                            // eslint-disable-next-line: max-line-length
                            (this.props.isMultiTenancyEnabled || this.state.cleanModel.MultiTenancyMode !== TenantedDeploymentMode.Untenanted) &&
                                isAllowed({ permission: Permission.TenantView, tenant: "*", project: this.props.initialData.project.Id }) && (
                                    <ExpandableFormSection errorKey="tenantedDeploymentMode" title="Multi-tenancy" summary={this.tenantedDeploymentModeSummary()} help="Choose whether this runbook runs against a tenant.">
                                        <TenantedDeploymentModeRadioButtonGroup value={this.state.model.MultiTenancyMode} onChange={(tenantedDeploymentMode) => this.setModelState({ MultiTenancyMode: tenantedDeploymentMode })}>
                                            <RadioButton value={TenantedDeploymentMode.Untenanted} label="Runbook cannot be run against a tenant" isDefault={true} />
                                            <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Runbook can be run with or without a tenant" />
                                            <RadioButton value={TenantedDeploymentMode.Tenanted} label="Runbook must be run against a tenant" />
                                        </TenantedDeploymentModeRadioButtonGroup>
                                        <Note>
                                            <ExternalLink href="ProjectTenantedDeploymentMode">Learn more about tenanted deployment modes</ExternalLink>
                                        </Note>
                                    </ExpandableFormSection>
                                )
                        }
                        {/* TODO Runbooks: Component */}
                        <ExpandableFormSection
                            errorKey="skipMachines"
                            title="Deployment Target Status"
                            summary={this.skipMachinesSummary(this.state.model.ConnectivityPolicy)}
                            help="Choose to skip unavailable, or exclude unhealthy targets when running the runbook."
                        >
                            <StringRadioButtonGroup label="Unavailable Deployment targets" value={this.state.model.ConnectivityPolicy.SkipMachineBehavior} onChange={(skipMachines) => this.handleSkipMachinesChanged(skipMachines)}>
                                <RadioButton value="None" label="Do not skip, and fail" isDefault={true} />
                                <RadioButton value="SkipUnavailableMachines" label="Skip" />
                                <Note>Deployment targets that are unavailable at the start of the run or become unavailable during the run will be skipped and removed from the run.</Note>
                            </StringRadioButtonGroup>
                            {this.state.model.ConnectivityPolicy.SkipMachineBehavior === "SkipUnavailableMachines" && (
                                <div>
                                    <RoleMultiSelect
                                        onChange={(skipMachinesRoles) => this.setConnectivityPolicyState({ TargetRoles: skipMachinesRoles })}
                                        value={this.state.model.ConnectivityPolicy.TargetRoles}
                                        label="Skip unavailable deployment targets only in selected roles"
                                        items={this.props.initialData.machineRoles}
                                    />
                                    <Note>By default, deployment targets will be skipped if they are unavailable in all roles, to limit to certain roles select them here.</Note>
                                </div>
                            )}
                            <StringRadioButtonGroup
                                value={this.state.model.ConnectivityPolicy.ExcludeUnhealthyTargets ? "ExcludeUnhealthy" : "None"}
                                onChange={(skipUnhealthyTargets) => this.setConnectivityPolicyState({ ExcludeUnhealthyTargets: skipUnhealthyTargets === "ExcludeUnhealthy" })}
                                label="Unhealthy Deployment Targets"
                            >
                                <RadioButton value="None" label="Do not exclude" isDefault={true} />
                                <RadioButton value="ExcludeUnhealthy" label="Exclude" />
                                <Note>Deployment targets that are unhealthy at the start of the run will be skipped and removed from the run.</Note>
                            </StringRadioButtonGroup>
                        </ExpandableFormSection>
                        <FailureMode
                            guidedFailureMode={this.state.model.DefaultGuidedFailureMode}
                            onModeChanged={(DefaultGuidedFailureMode) => this.setModelState({ DefaultGuidedFailureMode })}
                            title="Default Failure Mode"
                            modelType={DeploymentModelType.Runbook}
                        />
                        <RunbookRetentionPolicyControl retentionPolicy={this.state.model.RunRetentionPolicy} onRetentionPolicyChange={(RunRetentionPolicy) => this.setState({ ...this.state, model: { ...this.state.model, RunRetentionPolicy } })} />
                    </>
                )}
            </RunbooksFormPaperLayout>
        );
    }

    private tenantedDeploymentModeSummary(): SummaryNode {
        switch (this.state.model.MultiTenancyMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default("Runbook cannot be run against a tenant");
            case TenantedDeploymentMode.TenantedOrUntenanted:
                return Summary.summary("Runbook can be run with or without a tenant");
            case TenantedDeploymentMode.Tenanted:
                return Summary.summary("Runbook must be run against a tenant");
            default:
                return Summary.placeholder("Please select");
        }
    }

    private setConnectivityPolicyState<K extends keyof ConnectivityPolicy>(connectivityPolicyState: Pick<ConnectivityPolicy, K>) {
        this.setChildState2("model", "ConnectivityPolicy", connectivityPolicyState);
    }

    private handleSkipMachinesChanged = (skipMachines: string) => {
        this.setConnectivityPolicyState({
            SkipMachineBehavior: skipMachines,
            TargetRoles: skipMachines === "None" ? [] : this.state.model.ConnectivityPolicy.TargetRoles,
        });
    };

    //TODO Runbooks: extract and reuse w/ProjectSettings
    private skipMachinesSummary(connectivityPolicy: ConnectivityPolicy): SummaryNode {
        //TODO: convert to enum
        if (connectivityPolicy.SkipMachineBehavior !== "SkipUnavailableMachines") {
            return connectivityPolicy.ExcludeUnhealthyTargets ? Summary.summary("Runbook will exclude unhealthy targets, and fail if there is an unavailable target") : Summary.default("Runbook will fail if a target is unavailable");
        }

        const roles = connectivityPolicy.TargetRoles;
        const summary = [connectivityPolicy.ExcludeUnhealthyTargets ? <span key="skipMachines">Runbook will exclude unhealthy targets, and skip unavailable targets</span> : <span key="skipMachines">Runbook will skip unavailable targets</span>];

        if (roles.length > 0) {
            summary.push(connectivityPolicy.TargetRoles.length > 1 ? <span> in roles</span> : <span> in role</span>);

            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }
        return Summary.summary(React.Children.toArray(summary));
    }

    private onDeleteChanged = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private clonePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            projectGroup: this.props.initialData.project.ProjectGroupId,
            wildcard: true,
        };
    }

    private deletePermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.props.initialData.project.Id,
            wildcard: true,
        };
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.RunbookEdit,
            project: this.props.initialData.project.Id,
            wildcard: true,
        };
    }

    private handleDeleteConfirm = async () => {
        const { model: project, gitRef } = this.props.projectContext.state;
        const runbook = this.state.model;
        await repository.Runbooks.del(runbook);
        // Update project summary
        await this.props.projectContext.actions.onProjectUpdated(project, gitRef);
        this.setState({ redirectTo: routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbooks });
        return true;
    };

    private saveChanges = async () => {
        return this.doBusyTask(async () => {
            const savedRunbook = await this.saveRequest();
            this.setState({
                model: savedRunbook,
                cleanModel: cloneDeep(savedRunbook),
                commitMessage: { summary: "", details: "" },
            });
            this.props.runbookContext.actions.onRunbookUpdated(savedRunbook);
        });
    };

    private saveRequest = (): Promise<RunbookResource> => {
        const runbook = this.state.model;
        return repository.Runbooks.modify(runbook);
    };
}

const isMultiTenancyEnabledSelector = (state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled;

const RunbookSettings: React.FC<{ runbookId: string }> = ({ runbookId }) => {
    const isMultiTenancyEnabled = useSelector(isMultiTenancyEnabledSelector);
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const history = useSpaceAwareNavigation();

    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <RunbookSettingsLayout isMultiTenancyEnabled={isMultiTenancyEnabled} projectContext={projectContext} runbookContext={runbookContext} runbookId={runbookId} history={history} />;
};

export default RunbookSettings;
