import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { GitBranchResource } from "~/client/resources/index";

enum ActionKeys {
    // noinspection SpellCheckingInspection
    FORM_PAPER_LAYOUT_MOUNTED = "FORM_PAPER_LAYOUT_MOUNTED",
    FORM_PAPER_LAYOUT_DIRTYCHANGED = "FORM_PAPER_LAYOUT_DIRTYCHANGED",
    FORM_PAPER_LAYOUT_VERSIONCONTROLLEDPAGECHANGED = "FORM_PAPER_LAYOUT_VERSIONCONTROLLEDPAGECHANGED",
    OTHER_ACTION = "__any_other_action_type__",
}

interface IOnSaveClickAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED;
    saveLabel?: string;
    onSaveClick?: () => Promise<boolean>;
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

interface IDirtyChangedAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED;
    dirty: boolean;
}

interface IVersionControlledPageChangedAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_VERSIONCONTROLLEDPAGECHANGED;
    isPageVersionControlled: boolean;
}

type ActionTypes = IOnSaveClickAction | IOtherAction | IDirtyChangedAction | IVersionControlledPageChangedAction;

export function createFormPaperLayoutMountedAction(onSaveClick: (() => Promise<boolean>) | undefined, saveLabel: string | undefined): IOnSaveClickAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED, onSaveClick, saveLabel };
}

export function createFormPaperLayoutDirtyChangedAction(dirty: boolean): IDirtyChangedAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED, dirty };
}

export function createFormPaperLayoutVersionControlledPageChanged(isPageVersionControlled: boolean): IVersionControlledPageChangedAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_VERSIONCONTROLLEDPAGECHANGED, isPageVersionControlled };
}

export interface FormPaperLayoutReducerState {
    onSaveClick?: (isConfirmNavigate?: boolean, savedCallback?: () => void, newBranch?: GitBranchResource, commitMessage?: CommitMessageWithDetails) => Promise<boolean>;
    saveLabel?: string;
    dirty: boolean;
    isPageVersionControlled: boolean;
}

const formPaperLayoutReducer = (state: FormPaperLayoutReducerState = { onSaveClick: undefined, saveLabel: undefined, dirty: false, isPageVersionControlled: false }, action: ActionTypes): FormPaperLayoutReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_PAPER_LAYOUT_MOUNTED:
            return { ...state, onSaveClick: action.onSaveClick, saveLabel: action.saveLabel };
        case ActionKeys.FORM_PAPER_LAYOUT_DIRTYCHANGED:
            return state.dirty === action.dirty ? state : { ...state, dirty: action.dirty };
        case ActionKeys.FORM_PAPER_LAYOUT_VERSIONCONTROLLEDPAGECHANGED:
            return { ...state, isPageVersionControlled: action.isPageVersionControlled };
        default:
            return state;
    }
};
export default formPaperLayoutReducer;
