/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { MachineResource, TenantedDeploymentMode, NewMachineResource } from "~/client/resources";
import type { ResourceWithSlug } from "~/client/resources/ResourceWithSlug";

export interface DeploymentTargetResource extends MachineResource, ResourceWithSlug {
    EnvironmentIds: string[]; //ReferenceCollection;
    Roles: string[]; //ReferenceCollection;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
    TenantIds: string[]; //ReferenceCollection;
    TenantTags: string[]; //ReferenceCollection;
}

export interface NewDeploymentTargetResource extends NewMachineResource, ResourceWithSlug {
    EnvironmentIds: string[]; //ReferenceCollection;
    Roles: string[]; //ReferenceCollection;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
    TenantIds: string[]; //ReferenceCollection;
    TenantTags: string[]; //ReferenceCollection;
}

export function isDeploymentTarget(machine: MachineResource): machine is DeploymentTargetResource {
    return (machine as DeploymentTargetResource).EnvironmentIds !== undefined;
}

export default DeploymentTargetResource;
