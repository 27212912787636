/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { TaskResource, ResourceCollection, SpaceResource, TaskStatusMessages, TaskStatusMessage } from "~/client/resources";
import { TaskStatusMessageCategory } from "~/client/resources";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { List } from "../../../../components/List/List";
import TaskDetails from "../../../../components/TaskDetails/TaskDetails";
import { CalloutType, Callout } from "../../../../primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "../../../../routeLinks";

interface TaskQueueProps {
    task: TaskResource<any>;
}

interface TaskQueueState {
    behind?: ResourceCollection<TaskResource<any>>;
    skipBehind: number;
    space?: SpaceResource;
    messages?: TaskStatusMessages;
}

class BehindTasksList extends List<TaskResource<any>> {}

export default class TaskQueueOld extends DataBaseComponent<TaskQueueProps, TaskQueueState & DataBaseComponentState> {
    constructor(props: TaskQueueProps) {
        super(props);
        this.state = {
            behind: null!,
            skipBehind: 0,
            messages: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.startRefreshLoop(this.refresh, 5000));
    }

    refresh = async () => {
        if (this.props.task.HasBeenPickedUpByProcessor) {
            return {};
        }

        const behind = repository.Tasks.getQueuedBehind(this.props.task, { skip: this.state.skipBehind });
        const space = repository.Spaces.get(this.props.task.SpaceId!);
        const taskMessages = repository.Tasks.getTaskStatusMessages(this.props.task);

        return {
            behind: await behind,
            space: await space,
            messages: await taskMessages,
        };
    };

    render() {
        if (this.props.task.HasBeenPickedUpByProcessor) {
            return null;
        }

        if (this.props.task.HasPendingInterruptions) {
            return null;
        }

        if (!this.state.behind) {
            return <Callout type={CalloutType.Information} title={"Task has not yet started"} />;
        }

        const pendingTasks = this.state.behind.TotalResults;

        if (this.state.space && this.state.space.TaskQueueStopped) {
            return (
                <Callout type={CalloutType.Information} title={"Task has not yet started"}>
                    This task has not started as the task processor for Space <strong>{this.state.space.Name}</strong> has been stopped. {this.contactSpaceManagerMessage()}
                    <ExternalLink href="SpacesTaskQueue"> Learn More</ExternalLink>
                </Callout>
            );
        }

        const messages =
            this.state.messages &&
            this.state.messages?.Messages.sort(this.sortMessages).map((m, i) => (
                <Callout type={this.getCalloutType(m)} title={m.Title} key={i}>
                    <Markdown markup={m.Message} />
                </Callout>
            ));

        if (pendingTasks <= 0) {
            return (
                <div>
                    {messages}
                    <Callout type={CalloutType.Information} title={"Task has not yet started"}>
                        This task has not started yet, but is next in the queue and will be executed as soon as possible.
                    </Callout>
                </div>
            );
        }

        return (
            <div>
                {messages}
                <Callout type={CalloutType.Information} title={"Task has not yet started"}>
                    This task has not started yet, and is queued behind {pendingTasks > 1 ? `these ${pendingTasks} other tasks` : " another task"}
                </Callout>
                <BehindTasksList
                    initialData={this.state.behind}
                    onRow={(thing: TaskResource<any>) => <TaskDetails task={thing} />}
                    onRowRedirectUrl={(t: TaskResource<any>) => routeLinks.forSpace(t.SpaceId).task(t).root}
                    showPagingInNumberedStyle={true}
                />
            </div>
        );
    }

    private contactSpaceManagerMessage(): JSX.Element {
        return this.isSpaceManager() ? (
            <>
                <InternalLink to={routeLinks.configuration.space(this.state.space!.Id)}> Re-enable the task processor </InternalLink> to continue executing tasks.
            </>
        ) : (
            <>Re-enable the task processor to continue executing tasks. Please contact your Space Manager for more information.</>
        );
    }

    private isSpaceManager(): boolean {
        return session.currentPermissions!.isSpaceManager(this.state.space!);
    }

    private getCalloutType(message: TaskStatusMessage): CalloutType {
        switch (message.Category) {
            case TaskStatusMessageCategory.Success:
                return CalloutType.Success;
            case TaskStatusMessageCategory.Information:
                return CalloutType.Information;
            case TaskStatusMessageCategory.Warning:
                return CalloutType.Warning;
            case TaskStatusMessageCategory.Error:
                return CalloutType.Danger;
        }
    }

    private sortMessages(a: TaskStatusMessage, b: TaskStatusMessage) {
        const sortOrder = [TaskStatusMessageCategory.Error, TaskStatusMessageCategory.Warning, TaskStatusMessageCategory.Information, TaskStatusMessageCategory.Success];
        return sortOrder.indexOf(a.Category) - sortOrder.indexOf(b.Category);
    }
}
