/* eslint-disable @typescript-eslint/consistent-type-assertions */
import URI from "urijs";
import type { InsightsCadence } from "~/areas/insights/insightsCadence";
import { insightsCadenceOptions } from "~/areas/insights/insightsCadence";
import { InsightsMetricsSplit } from "~/client/repositories/insightsReportRepository";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";

export interface InsightsReportFilter {
    split: InsightsMetricsSplit;
    cadence: InsightsCadence;
    tenantTagSetId?: string;
}

export interface InsightsReportQueryFilter extends IQuery {
    split: string;
    cadence: string;
    tenantTagSetId?: string;
}

const defaultInsightsReportFilter: InsightsReportFilter = {
    split: InsightsMetricsSplit.None,
    cadence: "lastQuarterWeekly",
};

export const insightsReportQueryToFilter = (query: InsightsReportQueryFilter): InsightsReportFilter => {
    return {
        split: validateQuerySplit(query.split),
        cadence: validateQueryCadence(query.cadence),
        tenantTagSetId: query.tenantTagSetId,
    };
};

export const insightsReportFilterToQuery = (filter: InsightsReportFilter): InsightsReportQueryFilter => {
    return {
        ...filter,
    };
};

function validateQuerySplit(querySplit: string | null): InsightsMetricsSplit {
    if (!querySplit) return defaultInsightsReportFilter.split;

    return Object.values(InsightsMetricsSplit).includes(querySplit as InsightsMetricsSplit) ? (querySplit as InsightsMetricsSplit) : defaultInsightsReportFilter.split;
}

function validateQueryCadence(queryCadence: string | null): InsightsCadence {
    if (!queryCadence) return defaultInsightsReportFilter.cadence;

    // Check if the cadence in the url is a valid value, otherwise use the default
    return insightsCadenceOptions.map((o) => o.value).includes(queryCadence as InsightsCadence) ? (queryCadence as InsightsCadence) : defaultInsightsReportFilter.cadence;
}

export const getCurrentOrDefaultInsightsReportFilter = (queryString: string) => {
    const newQs = new URI().search(queryString).search(true);
    return insightsReportQueryToFilter(newQs);
};
