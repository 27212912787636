/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import type { GitRefResource } from "~/client/resources";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import BusyIndicator from "~/components/BusyIndicator/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import IconButtonList from "~/components/IconButtonList/IconButtonList";
import InputWithActions from "~/components/InputWithActions";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";

interface VariableSelectSharedProps extends FormFieldProps<string> {
    fetchVariables: () => Promise<string[]>;
    allowClear?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
}

interface VariableSelectInternalProps extends VariableSelectSharedProps {
    onAddNewVariableClick: () => void;
}

interface VariableSelectInternalState {
    variables: string[] | null;
}

class VariableSelectInternal extends DataBaseComponent<VariableSelectInternalProps, VariableSelectInternalState> {
    constructor(props: VariableSelectInternalProps) {
        super(props);

        this.state = {
            busy: undefined,
            variables: null,
        };
    }

    componentDidMount() {
        this.refreshVariables();
    }

    refreshVariables = () => {
        this.doBusyTask(async () => {
            const variables = await this.props.fetchVariables();
            this.setState({ variables });
        });
    };

    render() {
        return (
            <InputWithActions
                input={
                    <Select
                        label={this.props.label ?? "Select Account variable"}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        allowFilter={true}
                        error={this.props.error ?? this.errors?.message}
                        warning={this.props.warning}
                        allowClear={this.props.allowClear}
                        helperText={this.props.helperText}
                        onChange={(value) => this.props.onChange(value!)}
                        items={this.state.variables?.map((v) => ({ value: v, text: v })) ?? []}
                    />
                }
                actions={
                    <IconButtonList
                        buttons={[
                            <BusyFromPromise promise={this.state.busy}>
                                {(busy) => (
                                    <Busy isBusy={busy}>
                                        <IconButton accessibleName={"Refresh variables"} toolTipContent="Refresh" icon={Icon.Refresh} onClick={this.refreshVariables} />
                                    </Busy>
                                )}
                            </BusyFromPromise>,
                            <IconButton accessibleName={"Add new variable"} toolTipContent="Add" icon={Icon.Add} onClick={this.props.onAddNewVariableClick} />,
                        ]}
                    />
                }
            />
        );
    }
}

export interface VariableSelectProps extends VariableSelectSharedProps {
    projectId: string;
    gitRef: GitRefResource | undefined;
}

export function VariableSelect({ projectId, ...rest }: VariableSelectProps) {
    const { open } = useSpaceAwareNavigation();
    const onAddNewVariableClick = React.useCallback(() => {
        open(routeLinks.project(projectId).variables.root);
    }, [open, projectId]);

    return <VariableSelectInternal onAddNewVariableClick={onAddNewVariableClick} {...rest} />;
}

export const Busy: React.FC<{ isBusy: boolean }> = ({ isBusy, children }) => {
    return isBusy ? <BusyIndicator show={true} inline={true} /> : <>{children}</>;
};
