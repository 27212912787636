import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProjectContext } from "~/areas/projects/context";
import NavigationButton, { NavigationButtonType } from "~/components/Button/NavigationButton";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import processImageDark from "~/components/ProjectBasedActivation/assets/dark/darkimg-deploymentprocess.svg";
import processImage from "~/components/ProjectBasedActivation/assets/img-deploymentprocess.svg";
import routeLinks from "~/routeLinks";

const Onboarding = () => {
    const dispatchAction = useAnalyticActionDispatch();
    const processContext = useProcessContext();

    const project = useProjectContext();
    const projectId = project.state.model.Id;
    const projectSlug = project.state.model.Slug;
    const gitRef = project.state.gitRef;

    const href = routeLinks.projectBranch(projectSlug, gitRef).deploymentProcess.stepTemplates;

    return (
        <OnboardingPage
            title="Create your deployment process"
            intro={`The deployment process is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project.`}
            learnMore={
                <>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Docs</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentProcessVideo">Video (3 mins)</ExternalVideoLink>
                </>
            }
            image={processImage}
            imageDark={processImageDark}
            imageAlt={"Create Process"}
            actionButtons={
                <PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
                    <NavigationButton label={"Create Process"} href={href} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })} type={NavigationButtonType.Primary} />
                </PermissionCheck>
            }
        />
    );
};

export default Onboarding;
