import * as React from "react";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useProjectContext } from "~/areas/projects/context/index";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SectionNote from "~/components/SectionNote/SectionNote";

interface SettingsNoteSectionProps {
    isVersionControlled: boolean;
}

function SettingsNoteSectionInternal(props: SettingsNoteSectionProps & { vcsErrorMessages: string[] }) {
    const vcsSection = (
        <SectionNote>
            <div>
                Version control is configured. Please provide <ExternalLink href={"CaCEAPFeedbackForm"}>feedback</ExternalLink> on the Config as Code feature.
            </div>
        </SectionNote>
    );

    const nonVcsSection = (
        <SectionNote>
            <div>
                Configuring a project to use version control is an irreversible process. Please read the <ExternalLink href={"ConfigAsCodeEap"}>documentation</ExternalLink> first.
            </div>
        </SectionNote>
    );

    const vcsErrorSection = (
        <VcsErrorPanel
            content={
                <>
                    Unable to establish a connection to git.
                    <br />
                    Please check the URL, default branch name, and authentication details.
                    <ul>
                        {props.vcsErrorMessages.map((e) => (
                            <li>{e}</li>
                        ))}
                    </ul>
                </>
            }
        />
    );

    return (
        <>
            {props.isVersionControlled && props.vcsErrorMessages.length === 0 && vcsSection}
            {props.isVersionControlled && props.vcsErrorMessages.length > 0 && vcsErrorSection}
            {!props.isVersionControlled && nonVcsSection}
        </>
    );
}

export function SettingsNoteSection(props: SettingsNoteSectionProps) {
    const projectContext = useProjectContext();
    return <SettingsNoteSectionInternal {...props} vcsErrorMessages={projectContext.state.vcsErrorMessages} />;
}
