/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { PackageFromBuiltInFeedResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import BuildInformationTable from "~/components/BuildInformation/BuildInformationTable";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import Markdown from "~/components/Markdown";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import { FormSectionHeading } from "~/components/form";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn, DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter";
import DateFormatter from "~/utils/DateFormatter";
import { client } from "../../../../clientInstance";
import routeLinks from "../../../../routeLinks";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";

interface PackageDetailState extends DataBaseComponentState {
    package?: PackageFromBuiltInFeedResource;
    deleted?: boolean;
}

export default class PackageDetail extends DataBaseComponent<RouteComponentProps<{ id: string }>, PackageDetailState> {
    private id: string;

    constructor(props: RouteComponentProps<{ id: string }>) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async load() {
        const pkg = await repository.Packages.get(this.id, { includeNotes: true });
        let errors = null;
        if (pkg && pkg.PackageVersionBuildInformation && pkg.PackageVersionBuildInformation.IncompleteDataWarning) {
            errors = { message: `Unable to retrieve part of the package build information for package '${pkg.PackageId}'.`, errors: { PackageVersionBuildInformationIncomplete: pkg.PackageVersionBuildInformation.IncompleteDataWarning } };
        }
        this.setState({ package: pkg });
        if (!errors) {
            this.clearErrors();
        } else {
            this.setValidationErrors(errors.message, errors.errors);
        }
    }

    render() {
        const pkg = this.state.package;

        const title = this.state.package && pkg!.PackageId + " " + pkg!.Version;

        const downloadUploadActions = pkg && (
            <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                <ActionList
                    actions={[
                        <NavigationButton type={NavigationButtonType.Secondary} label="Upload another package" href={routeLinks.library.builtInRepository.root} />,
                        <NavigationButton type={NavigationButtonType.Primary} label="Download" icon={<ThirdPartyIcon iconType={ThirdPartyIconType.GetApp} />} href={client.resolve(pkg.Links["Raw"])} external={true} />,
                    ]}
                />
            </PermissionCheck>
        );

        const actions = [
            downloadUploadActions,
            <OverflowMenu menuItems={[OverflowMenuItems.deleteItemDefault(title!, this.handleDeleteConfirm, { permission: Permission.BuiltInFeedAdminister, project: "*" }, "Deleting packages will permanently remove files from disk")]} />,
        ];

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout
                    title={title}
                    breadcrumbTitle={pkg && pkg.PackageId}
                    breadcrumbPath={routeLinks.library.builtInRepository.versions(pkg?.PackageId!)}
                    sectionControl={<ActionList actions={actions} />}
                    busy={this.state.busy}
                    errors={this.errors}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.builtInRepository.root} />}
                    {pkg && (
                        <Section>
                            <p>
                                The information below is from the specification embedded in the <strong>{`${pkg.PackageId}.${pkg.Version}${pkg.FileExtension}`}</strong> file.
                            </p>
                        </Section>
                    )}
                    {pkg && (
                        <DataTable>
                            <DataTableBody>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>ID</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.PackageId}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Version</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.Version}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Published</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>
                                        <ToolTip content={DateFormatter.dateToLongFormat(pkg.Published)!}>{DateFormatter.dateToShortFormat(pkg.Published)}</ToolTip>
                                    </DataTableRowColumn>
                                </DataTableRow>
                                {pkg.Title && (
                                    <DataTableRow>
                                        <DataTableRowHeaderColumn>Title</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Title}</DataTableRowColumn>
                                    </DataTableRow>
                                )}
                                {pkg.Summary && (
                                    <DataTableRow>
                                        <DataTableRowHeaderColumn>Summary</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Summary}</DataTableRowColumn>
                                    </DataTableRow>
                                )}
                                {pkg.Description && (
                                    <DataTableRow>
                                        <DataTableRowHeaderColumn>Description</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Description}</DataTableRowColumn>
                                    </DataTableRow>
                                )}
                                {pkg.ReleaseNotes && (
                                    <DataTableRow>
                                        <DataTableRowHeaderColumn>Release Notes</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>
                                            <Markdown markup={pkg.ReleaseNotes} />
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                )}
                                {pkg.FileExtension && (
                                    <DataTableRow>
                                        <DataTableRowHeaderColumn>File Extension</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.FileExtension}</DataTableRowColumn>
                                    </DataTableRow>
                                )}
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Size</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{ByteSizeFormatter(pkg.PackageSizeBytes!)}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>SHA1</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.Hash}</DataTableRowColumn>
                                </DataTableRow>
                            </DataTableBody>
                        </DataTable>
                    )}
                    {pkg && pkg.PackageVersionBuildInformation && (
                        <>
                            <FormSectionHeading title="Build Information" />
                            <BuildInformationTable data={pkg.PackageVersionBuildInformation} />
                        </>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private handleDeleteConfirm = async () => {
        await repository.Packages.deleteMany([this.id]);
        this.setState({ deleted: true });
        return true;
    };
}
