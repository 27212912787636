import { CustomDialogActions, CustomSaveDialogActions } from "app/components/DialogLayout/Custom/CustomDialogActions";
// eslint-disable-next-line custom-portal-rules/no-restricted-imports
import CheckboxField from "material-ui/Checkbox";
import React, { useState } from "react";
import { CustomDialogContent } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { withTheme } from "~/components/Theme";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./styles.module.less";

interface CreateEnvironmentsDialogLayoutProps {
    onSaveClick: (environments: Array<string>) => Promise<boolean>;
    onSkipClick: () => boolean;
}

const suggestions = ["Development", "Staging", "Production", "Test", "QA"];
const initialEnvironments = ["Development", "Staging", "Production"];

export default function CreateEnvironmentsDialogLayout(props: CreateEnvironmentsDialogLayoutProps) {
    const [selectedEnvironments, setSelectedEnvironments] = useState<Array<string>>(initialEnvironments);
    return (
        <CustomSaveDialogLayout
            frame={NoFrame}
            onSaveClick={async () => await props.onSaveClick(selectedEnvironments)}
            close={props.onSkipClick}
            open={true}
            renderTitle={() => <CustomSaveDialogTitleBar title="You'll need at least one environment to deploy to" />}
            renderActions={(renderProps) => (
                <CustomDialogActions actions={<CustomSaveDialogActions saveButtonLabel={"Save"} onSaveClick={renderProps.onSaveClick} close={(save: boolean) => save || props.onSkipClick?.()} cancelButtonLabel={"Skip"} />} />
            )}
            renderContent={() => (
                <CustomDialogContent>
                    <p>Select from common environments:</p>
                    <CheckboxGrid options={suggestions} value={selectedEnvironments} onChange={setSelectedEnvironments} />
                    {selectedEnvironments.length >= 2 && <LifecycleSummary environments={selectedEnvironments} className={styles.lifecycleSummary} />}
                </CustomDialogContent>
            )}
        ></CustomSaveDialogLayout>
    );
}

const NoFrame: React.FC<{}> = ({ children }) => <div className={styles.noFrameFlex}>{children}</div>;

const LifecycleSummary: React.FC<{ environments: Array<string>; className?: string }> = ({ environments, className }) => (
    <div className={className}>
        <p>Your selected environments will appear in your Lifecycle in this order:</p>
        <ol className={styles.lifecycleList}>
            {environments.map((name, index) => (
                <li key={name} className={styles.lifecycleListItem}>
                    {name}
                </li>
            ))}
        </ol>
    </div>
);

interface CheckboxGridProps<T> {
    options: Array<T>;
    value?: Array<T>;
    onChange?: (values: Array<T>) => void;
}

function CheckboxGrid<T>(props: React.PropsWithChildren<CheckboxGridProps<T>>) {
    const isSelected = (value: T): boolean => props.value?.includes(value) ?? false;
    const toString = (v: T) => `${v}`;

    const toggleSelected = (value: T) => {
        const selectedValues = props.value ?? [];
        const newSelectedValues = isSelected(value) ? selectedValues.filter((env) => env !== value) : [...selectedValues, value];
        props.onChange?.(newSelectedValues);
    };

    return withTheme((theme) => (
        <div className={styles.checkboxGrid}>
            {props.options.map((name) => (
                <div key={toString(name)} className={styles.checkboxGridItem} style={{ borderColor: isSelected(name) ? theme.focus : theme.disabledButtonBorder }}>
                    <CheckboxField
                        className={styles.checkboxGridItem}
                        label={toString(name)}
                        aria-label={toString(name)}
                        checked={isSelected(name)}
                        onCheck={() => toggleSelected(name)}
                        checkedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBox} style={{ fill: theme.focus }} />}
                        uncheckedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.CheckBoxOutlineBlank} style={{ fill: theme.disabledButtonBorder }} />}
                        iconStyle={{ marginRight: "0.5rem" }}
                    />
                </div>
            ))}
        </div>
    ));
}
