/* eslint-disable custom-portal-rules/no-restricted-imports */

import type { PaletteType } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import LegacyMuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import * as React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { createLegacyMuiTheme, createMuiTheme } from "~/theme";
import { ThemeApplier } from "./ThemeApplier";
import { DarkTheme, LightTheme, OctopusTheme } from "./Themes";

type ThemeProps = React.PropsWithChildren<{
    palette: PaletteType;
}>;

function Theme({ palette, children }: ThemeProps) {
    const theme = mapPaletteToTheme(palette);
    const legacyMuiTheme = React.useMemo(() => createLegacyMuiTheme(theme), [theme]);
    const muiTheme = React.useMemo(() => createMuiTheme(palette, theme), [palette, theme]);

    return (
        <>
            <ThemeApplier />
            <LegacyMuiThemeProvider muiTheme={legacyMuiTheme}>
                <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
            </LegacyMuiThemeProvider>
        </>
    );
}

function mapPaletteToTheme(themeName: string): OctopusTheme {
    return themeName === "light" ? LightTheme : DarkTheme;
}

function withTheme(render: (theme: OctopusTheme) => React.ReactElement | null | undefined): React.ReactElement {
    return <UsingTheme>{(theme) => render(theme)}</UsingTheme>;
}

type UsingThemeProps = {
    children: (theme: OctopusTheme) => React.ReactNode;
};

function UsingTheme({ children }: UsingThemeProps) {
    const theme = useOctopusTheme();
    return <>{children(theme)}</>;
}

function useOctopusTheme(): OctopusTheme {
    const palette = useThemePaletteType();
    return mapPaletteToTheme(palette);
}

export default Theme;

export { Theme, OctopusTheme, withTheme, useOctopusTheme };
