/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { cloneDeep } from "lodash";
import React from "react";
import type { WorkerPoolResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import Markdown from "~/components/Markdown";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PermissionCheck } from "~/components/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, ExpandableFormSection, Summary, required, Checkbox, MarkdownEditor } from "~/components/form";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import routeLinks from "~/routeLinks";
import InfrastructureLayout from "../../InfrastructureLayout";

interface WorkerPoolEditProps<TWorkerPoolResource extends WorkerPoolResource> {
    workerPool: TWorkerPoolResource;
}

interface WorkerPoolEditState<TModel extends WorkerPoolResource> extends FormBaseComponentState<TModel> {
    deleted: boolean;
}

abstract class WorkerPoolEditBase<TWorkerPoolResource extends WorkerPoolResource, TState extends WorkerPoolEditState<TWorkerPoolResource>> extends FormBaseComponent<WorkerPoolEditProps<TWorkerPoolResource>, TState, TWorkerPoolResource> {
    constructor(props: WorkerPoolEditProps<TWorkerPoolResource>) {
        super(props);
        this.state = this.getDefaultState();
    }

    abstract getDefaultState(): TState;
    abstract customExpandableFormSections(): JSX.Element[];

    descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided");
    }

    render() {
        const overFlowActions = [];
        overFlowActions.push(
            OverflowMenuItems.deleteItem(
                "Delete",
                "Are you sure you want to delete this worker pool?",
                this.handleDeleteConfirm,
                <div>
                    <p>Deleting this worker pool is permanent. There is no going back.</p>
                    <p>Do you wish to continue?</p>
                </div>,
                { permission: Permission.WorkerEdit }
            ),
            [
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]
        );

        const saveText = "Worker pool details updated";

        return (
            <InfrastructureLayout>
                <FormPaperLayout
                    title={this.state.model.Name}
                    breadcrumbTitle={"Worker pools"}
                    breadcrumbPath={routeLinks.infrastructure.workerPools.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: Permission.WorkerEdit }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={false}
                    overFlowActions={overFlowActions}
                    secondaryAction={this.addWorkerButton()}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.workerPools.root} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.Name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.Name} slug={this.state.model.Slug} />) : Summary.placeholder("Please enter a name for your worker pool")}
                                help="A short, memorable, unique name for this worker pool. Example: Development."
                            >
                                <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" validate={required("Please enter a worker pool name")} autoFocus={true} />

                                <SlugEditor
                                    value={this.state.model.Slug ?? ""}
                                    name={this.state.model.Name}
                                    originalSlug={this.state.cleanModel?.Slug ?? ""}
                                    onChange={(Slug) => this.setModelState({ Slug })}
                                    label="Slug"
                                    validate={required("Please enter a worker pool slug")}
                                    error={this.getFieldError("slug")}
                                />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="IsDefault"
                                title="Default Worker Pool"
                                summary={this.state.model.IsDefault ? Summary.summary("Yes") : Summary.default("No")}
                                help={"Set this worker pool to be used as the default worker pool"}
                            >
                                <Checkbox value={this.state.model.IsDefault} onChange={(IsDefault) => this.setModelState({ IsDefault })} label="Default" />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="Description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your worker pool.">
                                <MarkdownEditor value={this.state.model.Description} label="Worker pool description" onChange={(Description) => this.setModelState({ Description })} />
                            </ExpandableFormSection>

                            {this.customExpandableFormSections()}
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = (await repository.WorkerPools.save(this.state.model)) as TWorkerPoolResource;
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.WorkerPools.del(this.state.model);
        this.setState(() => {
            return {
                deleted: true,
            };
        });
        return true;
    };

    private addWorkerButton() {
        return this.state.model && this.state.model.CanAddWorkers ? (
            <PermissionCheck permission={Permission.WorkerEdit}>
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new(this.state.model.Id)} label="Add worker" />
            </PermissionCheck>
        ) : null;
    }
}

export { WorkerPoolEditProps, WorkerPoolEditState };

export default WorkerPoolEditBase;
