/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { sortBy, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import type { DashboardDataSourceState, RenderDashboardProps } from "~/areas/projects/components/DashboardDataSource/DashboardDataSource";
import DashboardDataSource, { dashboardDataHasProjects, dashboardDataHasEnvironments, hasReachedMinimumThresholdForHidingOnboardingOnDashboard } from "~/areas/projects/components/DashboardDataSource/DashboardDataSource";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import type { DashboardFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import ProjectDashboard from "~/areas/projects/components/ProjectDashboard/index";
import type { ProjectResource } from "~/client/resources/index";
import { DashboardRenderMode } from "~/client/resources/performanceConfigurationResource";
import { client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { NavigationButton } from "~/components/Button";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { GettingStartedDetails } from "~/components/GettingStarted/GettingStartedDetails";
import { gettingStartedLoader } from "~/components/GettingStarted/gettingStartedLoader";
import InternalLink from "~/components/Navigation/InternalLink";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import Section from "~/components/Section";
import { Select } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ComponentRow from "../../../components/ComponentRow/index";
import FilterSearchBox from "../../../components/FilterSearchBox/index";
import PaperLayout from "../../../components/PaperLayout/index";
import routeLinks from "../../../routeLinks";
import DashboardLimitSummary from "./DashboardLimitSummary";
import styles from "./style.module.less";

interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}

interface DashboardOverviewState {
    filter: DashboardOverviewFilter;
    matchCount: number;
    canContinueOnboarding: boolean;
    onboardingProject?: ProjectResource;
}

const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};

interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}

const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();

class DashboardOverview extends BaseComponent<{}, DashboardOverviewState> {
    isProjectBasedActivationFeatureEnabled = useEnabledFeatureToggle("ProjectBasedActivationFeatureToggle");
    constructor(props: {}) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
            canContinueOnboarding: false,
            onboardingProject: undefined,
        };
    }

    render() {
        if (client.spaceId === null) {
            return (
                <main className={styles.container}>
                    <AreaTitle link={routeLinks.root} title="Dashboard" />
                </main>
            );
        }

        return (
            <main className={styles.container}>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={(filter) => this.setState({ filter })} />
                <AreaTitle link={routeLinks.root} title="Dashboard">
                    <NavigationButton label="Configure" href={routeLinks.dashboard.configure} />
                </AreaTitle>
                <ContextualHelpLayout>
                    <DashboardDataSource filters={this.createDashboardFilters()} render={this.renderDataSource} />
                </ContextualHelpLayout>
            </main>
        );
    }

    private showDashboard(dashboardData: DashboardDataSourceState) {
        // We want to display project list with empty columns if the ProjectBasedActivationFeature is enabled
        if (dashboardDataHasProjects(dashboardData) && !dashboardDataHasEnvironments(dashboardData) && !this.isProjectBasedActivationFeatureEnabled) {
            return (
                <Callout type={CalloutType.Warning} title="The dashboard cannot be rendered without environments">
                    To tell Octopus where to deploy your software, <InternalLink to={routeLinks.infrastructure.environments.root}>create your first environment</InternalLink> in the infrastructure area.
                </Callout>
            );
        }

        const cube = dashboardData.cube;
        const groups = sortBy(cube!.projectGroupIndex, (g) => g.Name.toLowerCase()).map((g) => ({ value: g.Id, text: g.Name }));
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;

        return (
            <Section>
                <div className={styles.filterHeaderContainer} key="A" role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow className={styles.filter}>
                            {groups.length > 1 && (
                                <div className={styles.filterField}>
                                    <Select placeholder="Filter by project group" items={groups} onChange={this.handleGroupChange} value={this.state.filter.projectGroupId} allowClear={true} />
                                </div>
                            )}
                            <div className={styles.filterField}>
                                <FilterSearchBox placeholder="Filter by project name" inputClassName={styles.filterInput} value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true} />
                            </div>
                            <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit ?? null} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube!.projectIndex).length} />
                        </ComponentRow>
                    </div>
                </div>
                <ProjectDashboard
                    key="B"
                    cube={dashboardData.cube!}
                    filters={this.createDashboardFilters()}
                    maximumRows={dashboardData.projectLimit}
                    showDeploymentCounts={true}
                    onProjectCountChanged={this.handleProjectCountChange}
                    dashboardRenderMode={DashboardRenderMode.VirtualizeColumns}
                />
            </Section>
        );
    }

    async componentWillMount() {
        if (!this.isProjectBasedActivationFeatureEnabled) return;
        const onboardingStatus = await gettingStartedLoader.loadStatus();
        this.setState({ canContinueOnboarding: !onboardingStatus.tasks.Deploy!.DeployedRelease.IsComplete && onboardingStatus.projects.length === 1 });
        this.setState({ onboardingProject: onboardingStatus.projects.length === 1 ? onboardingStatus.projects[0] : undefined });
    }

    private renderDataSource = (dataSource: RenderDashboardProps) => {
        if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource) || this.state.canContinueOnboarding) {
            return (
                <div className={styles.onboardingPanelOuterContainer}>
                    {!this.isProjectBasedActivationFeatureEnabled && (
                        <div className={styles.onboardingPanelInnerContainer}>
                            <AnalyticLinkProvider location="Onboarding dashboard">
                                <GettingStartedDetails showIntroHeading={true} />
                            </AnalyticLinkProvider>
                        </div>
                    )}
                    {this.isProjectBasedActivationFeatureEnabled && (
                        <AnalyticLinkProvider location="Onboarding dashboard">
                            <DashboardOnboardingLayout canContinueOnboarding={this.state.canContinueOnboarding} onboardingProject={this.state.onboardingProject} />
                        </AnalyticLinkProvider>
                    )}
                </div>
            );
        }

        return (
            <PaperLayout flatStyle={true} fullWidth={true} busy={dataSource.busy} errors={dataSource.errors} className={styles.paper}>
                {dataSource.hasInitialLoaded && this.showDashboard(dataSource)}
            </PaperLayout>
        );
    };

    private handleGroupChange = (projectGroupId: string | undefined) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectGroupId } }));
    };

    private handleNameChange = (projectName: string) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectName } }));
    };

    private createDashboardFilters: () => DashboardFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null!;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null!;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };

    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
}

function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}

function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}

export default DashboardOverview;
