import type { DeploymentSettingsResource, ModifyDeploymentSettingsCommand, ProjectResource, GitRefResource } from "~/client/resources";
import type { Client } from "../client";

export class DeploymentSettingsRepository {
    readonly resourceLink = "DeploymentSettings";

    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly gitRef: GitRefResource | undefined) {
        this.client = client;
    }

    get(): Promise<DeploymentSettingsResource> {
        if (this.project.IsVersionControlled && this.gitRef !== undefined) {
            return this.client.get(this.gitRef.Links[this.resourceLink]);
        }

        return this.client.get(this.project.Links[this.resourceLink]);
    }

    modify(deploymentSettings: ModifyDeploymentSettingsCommand): Promise<DeploymentSettingsResource> {
        return this.client.update(deploymentSettings.Links.Self, deploymentSettings);
    }
}

export default DeploymentSettingsRepository;
