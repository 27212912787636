import { logger } from "~/utils/logging/logger";
import { consoleLoggers } from "~/utils/logging/originalConsoleLoggers";

export function configureConsoleLoggersToForwardToGlobalLogger() {
    consoleLoggers.errorConsole.setConsoleLogger((message, ...optionalParams) => logger.unstructuredLogEvent("error", message, ...optionalParams));

    consoleLoggers.warnConsole.setConsoleLogger((message, ...optionalParams) => logger.unstructuredLogEvent("warning", message, ...optionalParams));

    consoleLoggers.infoConsole.setConsoleLogger((message, ...optionalParams) => logger.unstructuredLogEvent("information", message, ...optionalParams));

    consoleLoggers.debugConsole.setConsoleLogger((message, ...optionalParams) => logger.unstructuredLogEvent("debug", message, ...optionalParams));

    consoleLoggers.logConsole.setConsoleLogger((message, ...optionalParams) => logger.unstructuredLogEvent("verbose", message, ...optionalParams));
}
