import cn from "classnames";
import type { Dictionary } from "lodash";
import { keyBy, compact } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import type { Dispatch } from "redux";
import type { TenantResource, TagSetResource, ProjectSummaryResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import { ActionButtonType } from "~/components/Button";
import FormPage from "~/components/FormPage/FormPage";
import Markdown from "~/components/Markdown/index";
import InternalLink from "~/components/Navigation/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { Section, SectionHeadingType } from "~/components/Section/Section";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import * as tenantTagsets from "~/components/tenantTagsets";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import type { EnvironmentResource } from "../../../client/resources/environmentResource";
import type { TenantMissingVariableResource } from "../../../client/resources/tenantMissingVariablesResource";
import { environmentChipList } from "../../../components/Chips/index";
import type { DataBaseComponentState } from "../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../components/DataBaseComponent/DataBaseComponent";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import { SimpleList } from "../../../components/List/SimpleList";
import Logo from "../../../components/Logo/Logo";
import { OverflowMenu, OverflowMenuItems } from "../../../components/OverflowMenu/OverflowMenu";
import PaperLayout from "../../../components/PaperLayout/PaperLayout";
import Tag from "../../../components/Tag/Tag";
import ToolTip from "../../../primitiveComponents/dataDisplay/ToolTip/ToolTip";
import routeLinks from "../../../routeLinks";
import { tenantsActions } from "../tenantsArea";
import ProjectEnvironmentSelection from "./AddProjectsToTenantDialog";
import AddTagsToTenantDialog from "./AddTagsToTenantDialog";
import styles from "./style.module.less";

interface TenantOverviewState extends DataBaseComponentState {
    tenant: TenantResource;
    selectedProjectId?: string;
    projectToRemove?: ProjectSummaryResource;
    projectToEdit?: ProjectSummaryResource;
}

interface RouteParameters {
    tenantId: string;
}
interface TenantOverviewInternalProps {
    onTenantVariablesFetched: (tenantMissingVariables?: TenantMissingVariableResource) => void;
    initialData: InitialData;
}

class LinkedProjectEnvironmentList extends SimpleList<{ project: ProjectSummaryResource; environmentIds: string[] }> {}

interface InitialData {
    tenant: TenantResource;
    tagSets: TagSetResource[];
    projects: Dictionary<ProjectSummaryResource>;
    environments: EnvironmentResource[];
}

const TenantOverviewFormPage = FormPage<InitialData>();
const title = "Overview";

const TenantOverviewPage: React.FC = () => {
    const { tenantId } = useParams<RouteParameters>();

    return (
        <TenantOverviewFormPage
            title={title}
            load={async () => {
                const [tenant, tagSets, projects, environments] = await Promise.all([repository.Tenants.get(tenantId), tenantTagsets.getAll(), repository.Projects.summaries(), repository.Environments.all()]);

                return {
                    tenant,
                    tagSets,
                    projects: keyBy(projects, (p) => p.Id),
                    environments,
                };
            }}
            renderWhenLoaded={(data) => {
                return <TenantOverview initialData={data} />;
            }}
        />
    );
};

export class TenantOverviewInternal extends DataBaseComponent<TenantOverviewInternalProps, TenantOverviewState> {
    constructor(props: TenantOverviewInternalProps) {
        super(props);
        const intitialTenant = this.props.initialData.tenant;

        this.state = {
            tenant: intitialTenant,
        };
    }

    get tenantId(): string {
        return this.state.tenant.Id;
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const tenant = await repository.Tenants.save(this.state.tenant);
            this.setState({ tenant });

            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
        });
    };

    renderEnvironmentItems = ({ projectId, projectName }: { projectId: string; projectName: string }) => {
        const environmentIds = this.state.tenant.ProjectEnvironments[projectId];
        if (!environmentIds || !environmentIds.length) {
            return (
                <div className={styles.noEnvironmentsWarning}>
                    <ToolTip content={`You will not be able to deploy to ${projectName} until you connect to one of the environments.`}>
                        <em className={cn("fa-solid fa-triangle-exclamation", styles.noEnvironmentsWarningIcon)} />
                    </ToolTip>
                    Not connected to any environments
                </div>
            );
        }

        return <>{environmentChipList(this.props.initialData.environments, environmentIds)}</>;
    };

    renderLinkedProjects(): React.ReactNode {
        const projectEnvironments = this.state.tenant.ProjectEnvironments;
        const projects = this.props.initialData.projects;

        const items = Object.keys(this.state.tenant.ProjectEnvironments)
            // need to filter out projects that were not returned due to permissions
            .filter((p) => !!projects[p])
            .map((p) => ({ project: projects[p], environmentIds: projectEnvironments[p] }))
            .sort((a, b) => a.project && b.project && a.project.Name.localeCompare(b.project.Name));

        if (items.length === 0) {
            return null;
        }

        // user may not be able to see some of these projects, if they *can't see any* show a callout
        if (items.filter((i) => !!i.project).length === 0) {
            return (
                <div className={styles.noProjectAccess}>
                    <Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                        Your Project permissions do not allow you to see any of the Linked Projects.
                    </Callout>
                </div>
            );
        }

        return (
            <LinkedProjectEnvironmentList
                disableListItem={true}
                onFilter={(filter, item) => item.project.Name.toLowerCase().includes(filter.toLowerCase())}
                items={items}
                pageSize={20}
                onRow={(item) => {
                    if (!item.project) {
                        return null;
                    }
                    return (
                        <div className={styles.linkedProject}>
                            <div className={styles.header}>
                                <div className={styles.projectName}>
                                    <Logo url={item.project.Logo} />
                                    <InternalLink to={routeLinks.project(item.project.Slug).root}>{item.project.Name}</InternalLink>
                                </div>
                                <OverflowMenu
                                    menuItems={[
                                        OverflowMenuItems.dialogItem(
                                            "Edit",
                                            <ProjectEnvironmentSelection
                                                tenant={this.state.tenant}
                                                onUpdated={this.handleUpdatedProjectLink}
                                                existingProjectLink={{
                                                    projectId: item.project.Id,
                                                    environmentIds: item.environmentIds,
                                                }}
                                                excludedProjects={Object.keys(this.state.tenant.ProjectEnvironments)}
                                            />,
                                            { permission: Permission.TenantEdit, tenant: this.tenantId }
                                        ),
                                        OverflowMenuItems.item("Remove", () => this.setState({ projectToRemove: item.project }), { permission: Permission.TenantEdit, tenant: this.tenantId }),
                                        OverflowMenuItems.navItem(`View Project Template Variables`, routeLinks.project(item.project.Id).variables.projectTemplates, { permission: Permission.VariableView, wildcard: true }),
                                        OverflowMenuItems.navItem(`View Common Template Variables`, routeLinks.project(item.project.Id).variables.commonTemplates, { permission: Permission.VariableView, wildcard: true }),
                                    ]}
                                />
                            </div>
                            <div className={styles.details}>
                                <div className={styles.environments}>{this.renderEnvironmentItems({ projectId: item.project.Id, projectName: item.project.Name })}</div>
                            </div>
                        </div>
                    );
                }}
            />
        );
    }

    handleUpdatedProjectLink = async (tenant: TenantResource): Promise<boolean> => {
        return this.doBusyTask(async () => {
            this.setState({ tenant });

            const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
            this.props.onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            return true;
        });
    };

    handleRemoveProjectLink = async () => {
        const tenantId = this.state.tenant.Id;
        const projectId = this.state.projectToRemove?.Id;

        return this.doBusyTask(async () => {
            this.setState({ projectToRemove: undefined });

            const tenant = await repository.Tenants.get(tenantId);

            if (projectId) {
                delete tenant.ProjectEnvironments[projectId];
            }

            const savedTenant = await repository.Tenants.save(tenant);
            return this.handleUpdatedProjectLink(savedTenant);
        });
    };

    connectProjectButton() {
        return (
            <OpenDialogButton label="Connect Project" permission={{ permission: Permission.TenantEdit, tenant: this.tenantId }} type={ActionButtonType.Primary}>
                <ProjectEnvironmentSelection tenant={this.state.tenant} onUpdated={(tenant: TenantResource) => this.setState({ tenant })} excludedProjects={Object.keys(this.state.tenant.ProjectEnvironments)} />
            </OpenDialogButton>
        );
    }

    linkedProjectMessage() {
        const projectCount = Object.keys(this.state.tenant.ProjectEnvironments).length;
        if (projectCount === 0) {
            return (
                <div className={styles.notConnectedMessage}>
                    <div>No projects are connected to this tenant.</div>
                    <NoResults />
                </div>
            );
        }

        return (
            <div>
                {projectCount} project{projectCount > 1 ? "s" : ""} can deploy to this tenant.
            </div>
        );
    }

    renderTenantTagsSection() {
        const tagSets = this.props.initialData.tagSets;
        const groupedTenantTags = tenantTagsets.groupAndOrderByTagSet(this.state.tenant.TenantTags, tagSets);

        return (
            <div>
                <h4>Tag Sets</h4>
                {groupedTenantTags.map((groupedTenantTag) => {
                    const tagSet = tagSets.find((ts) => ts.Name === groupedTenantTag.name);
                    if (!tagSet) {
                        throw new Error(`Tenant had a tag that did not match one of the tagsets. Tried to find ${groupedTenantTag.name}.`);
                    }

                    return (
                        <div key={groupedTenantTag.name} className={styles.tagSetContainer}>
                            {tagSet.Description ? <ToolTip content={<Markdown markup={tagSet.Description} />}>{this.tagSetName(groupedTenantTag.name)}</ToolTip> : this.tagSetName(groupedTenantTag.name)}
                            <div>
                                {compact(groupedTenantTag.tags.map((canonicalTagName) => tagSet.Tags.find((t) => t.CanonicalTagName === canonicalTagName)))
                                    .sort((a, b) => a.SortOrder - b.SortOrder)
                                    .map((tag) => {
                                        return <Tag tagName={tag.Name} tagColor={tag.Color} key={tag.Name} description={tag.Description} />;
                                    })}
                            </div>
                        </div>
                    );
                })}
                <OpenDialogButton label="Manage Tags">
                    <AddTagsToTenantDialog tenant={this.state.tenant} onUpdated={(tenant: TenantResource) => this.setState({ tenant })} tagSets={this.props.initialData.tagSets} />
                </OpenDialogButton>
            </div>
        );
    }

    render() {
        return (
            <PaperLayout title="Overview" busy={this.state.busy} errors={this.errors} sectionControl={this.connectProjectButton()}>
                <SidebarLayout sideBar={this.renderTenantTagsSection()}>
                    <DeleteDialog
                        title={"Unlink Tenant from Project"}
                        open={!!this.state.projectToRemove}
                        onClose={() => this.setState({ projectToRemove: undefined })}
                        deleteButtonLabel="Remove"
                        onDeleteClick={this.handleRemoveProjectLink}
                        renderContent={() => {
                            if (this.state.projectToRemove) {
                                return (
                                    <p>
                                        Are you sure you want to unlink <b>{this.state.tenant.Name}</b> from <b>{this.state.projectToRemove.Name}</b>?
                                    </p>
                                );
                            }
                            throw new Error("Unlink Tenant from Project dialog tried to render without a project selected to unlink.");
                        }}
                    />
                    <Section sectionHeader="Projects" headingType={SectionHeadingType.Heading4}>
                        {this.linkedProjectMessage()}
                    </Section>
                    {this.renderLinkedProjects()}
                </SidebarLayout>
            </PaperLayout>
        );
    }

    private tagSetName(name: string) {
        return <strong>{name}</strong>;
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => {
    return {
        onTenantVariablesFetched: (tenantMissingVariables: TenantMissingVariableResource | undefined) => {
            dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
        },
    };
};

const TenantOverview = connect(null, mapGlobalActionDispatchersToProps)(TenantOverviewInternal);

export default TenantOverviewPage;
