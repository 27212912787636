/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import type { TriggerResource, EnvironmentResource, ChannelResource, TriggerScheduleResource, ScopedDeploymentActionResource, RunbookResource } from "~/client/resources";
import type { OverflowMenuDeleteItem, OverflowMenuNavLink, OverflowMenuDialogItem } from "~/components/OverflowMenu/OverflowMenu";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper/ScheduledTriggerDescriptionHelper";
import styles from "./style.module.less";

type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem;

interface ScheduledTriggerProps {
    trigger: TriggerResource;
    environments: EnvironmentResource[];
    channels: ChannelResource[];
    menuItems?: Array<MenuItem | MenuItem[]>;
    runbooks: RunbookResource[];
}

export default class ScheduledTrigger extends React.PureComponent<ScheduledTriggerProps> {
    render() {
        return (
            <div className={this.props.trigger.IsDisabled ? styles.disabled : ""}>
                <ListItem overflowMenuItems={this.props.menuItems}>
                    <ListTitle>{this.props.trigger.Name}</ListTitle>
                    {this.getTriggerDescription()}
                </ListItem>
            </div>
        );
    }

    private getTriggerDescription() {
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(this.props.trigger.Filter as TriggerScheduleResource);
        const actionDescription = ScheduledTriggerDescriptionHelper.getActionDescription(this.props.trigger.Action as ScopedDeploymentActionResource, this.props.environments, this.props.runbooks, this.props.channels);

        return (
            <span>
                {actionDescription}
                {scheduleDescription}.
            </span>
        );
    }
}
