import { createCompositeSink } from "~/utils/logging/CompositeSink";
import type { LoggingSink } from "~/utils/logging/LoggingSink";
import type { BufferingSinkTarget } from "~/utils/logging/bufferingSink";
import { createBufferingSink } from "~/utils/logging/bufferingSink";
import { createConsoleSink } from "~/utils/logging/consoleSink";
import { createLogger } from "~/utils/logging/createLogger";
import { createDynamicSink } from "~/utils/logging/dynamicSink";
import { consoleLoggers } from "~/utils/logging/originalConsoleLoggers";
import type IPageWrapper from "~/utils/pageId";

const serilogIngestionSink = createBufferingSink();
const dynamicSink = createDynamicSink();
export const logger = createLogger(createCompositeSink(createConsoleSink(consoleLoggers), serilogIngestionSink, dynamicSink), () => ({ ...loggerContext }));

interface GlobalLoggingContext {
    PageId?: string;
    PageName?: string;
    PageArea?: string;
    BrowserSessionId?: string;
}

let loggerContext: GlobalLoggingContext = {};

export const globalLogConfiguration = {
    pipeSerilogIngestionLogEventsTo: (target: BufferingSinkTarget) => serilogIngestionSink.setTarget(target),
    attachSink: (sink: LoggingSink) => dynamicSink.attachSink(sink),
};

export const globalLogContext = {
    resetContext() {
        loggerContext = {};
    },
    setPage(page: IPageWrapper) {
        loggerContext.PageId = page.Id;
        loggerContext.PageName = page.Name;
        loggerContext.PageArea = page.Area;
    },
    setBrowserSessionId(browserSessionId: string) {
        loggerContext.BrowserSessionId = browserSessionId;
    },
};
