import React from "react";
import type { TaskResource } from "~/client/resources";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";
import { TriggerSyncTask } from "./TriggerSyncTask";

type StepTemplateUpdatesProps = {
    isEnabled: boolean;
    isSyncEnabled: boolean;
    lastSyncedTask?: TaskResource<{}>;
    busy?: Promise<unknown> | boolean;
    onChange: (isEnabled: boolean) => void;
    onSyncClick: () => void;
};

export function StepTemplateUpdates({ isEnabled, lastSyncedTask, busy, isSyncEnabled, onChange, onSyncClick }: StepTemplateUpdatesProps) {
    return (
        <ExpandableFormSection
            key="IsAutomaticStepUpdatesEnabled"
            errorKey="IsAutomaticStepUpdatesEnabled"
            title="Step Template Updates"
            summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
            help="Automatically receive latest fixes, improvements and steps to the step library"
        >
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange} accessibleName={"Step Template Automatic Updates"}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>

            <TriggerSyncTask isSyncEnabled={isSyncEnabled} lastSyncedTask={lastSyncedTask} busy={busy} onSyncClick={onSyncClick} />
        </ExpandableFormSection>
    );
}
