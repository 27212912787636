import React from "react";
import { useParams } from "react-router";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import useLocalStorage from "~/hooks/useLocalStorage";
import routeLinks from "~/routeLinks";
import { CloseButton } from "./CloseButton";
import { ProjectStatusItem } from "./ProjectStatusItem";
import styles from "./style.module.less";

interface ProjectStatusProps {
    doBusyTask: DoBusyTask;
    numberOfSteps?: number;
    hasChanged?: {};
}

export type TaskState = "Current" | "Pending" | "Done";

const subscribeKey = "ProjectStatus";

export const ProjectStatus = ({ doBusyTask, numberOfSteps, hasChanged }: ProjectStatusProps): JSX.Element => {
    const dispatchAction = useAnalyticActionDispatch();
    const { spaceId, projectSlug: projectName } = useParams<{ spaceId: string; projectSlug: string }>();
    const isProjectBasedActivationFeatureEnabled = useEnabledFeatureToggle("ProjectBasedActivationFeatureToggle");
    const [dismissed, setDismissed] = useLocalStorage(`Octopus.Project.${projectName}.Status.Dismissed`, false);
    const [hasLoadedStatus, setHasLoadedStatus] = React.useState<boolean>(false);
    const [hasEnvironments, setHasEnvironments] = React.useState<boolean>(false);
    const [hasSteps, setHasSteps] = React.useState<boolean>(false);
    const [hasSuccessfulDeployment, setHasSuccessfulDeployment] = React.useState(false);
    const statusItems = getStatusItems(projectName);

    let currentStep = "initial";
    if (hasSuccessfulDeployment) {
        currentStep = "dismissed";
    } else if (hasSteps && hasEnvironments) {
        currentStep = "deployment";
    } else if (hasEnvironments) {
        currentStep = "process";
    } else {
        currentStep = "environment";
    }

    const activeItem = statusItems.findIndex((item) => item.status === currentStep);
    const closeProjectStatus = () => {
        setDismissed(true);
        dispatchAction("Project Status Bar Dismissed", { action: Action.Toggle, resource: "Project Status Bar" });
    };

    React.useEffect(() => {
        repository.Environments.subscribeToDataModifications(subscribeKey, (environment) => {
            if (environment.Id) {
                setHasEnvironments(true);
            }
        });

        return () => {
            repository.Environments.unsubscribeFromDataModifications(subscribeKey);
        };
    }, []);

    useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (isProjectBasedActivationFeatureEnabled) {
                setHasEnvironments((await repository.Environments.summary()).EnvironmentSummaries.length > 0);
                getProjectStatus(projectName, spaceId);
            }
        },
        [numberOfSteps, hasChanged]
    );

    // To prevent a flash of the status bar when it should be dismissed,
    // render nothing until we have fetched the status data
    if (!hasLoadedStatus || dismissed || hasSuccessfulDeployment) return <></>;

    return (
        <FeatureToggleVisibility toggle={"ProjectBasedActivationFeatureToggle"}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.title}>Project status</div>
                    {statusItems.map((item, index) => (
                        <ProjectStatusItem name={item.label} state={getItemState(index, activeItem)} href={item.href} key={item.label} eventName={item.eventName} eventAction={item.eventAction} />
                    ))}
                    <CloseButton onClick={closeProjectStatus} />
                </div>
            </div>
        </FeatureToggleVisibility>
    );

    async function getProjectStatus(projectName: string, spaceId: string) {
        const projectStatus = await repository.Projects.getProjectStatus(projectName, spaceId);
        setHasSteps(projectStatus.HasSteps);
        setHasSuccessfulDeployment(projectStatus.HasBeenSuccessfullyDeployed);
        const runbookOnly = !projectStatus.HasSteps && projectStatus.HasRunbooks;
        if ((projectStatus.HasBeenSuccessfullyDeployed || runbookOnly) && !dismissed) {
            setDismissed(true);
        }

        setHasLoadedStatus(true);
    }
};

function getItemState(index: number, activeItem: number): TaskState {
    if (index < activeItem) return "Done";
    if (index > activeItem) return "Pending";
    return "Current";
}

function getStatusItems(project: string) {
    return [
        {
            status: "project",
            label: "Add project",
            href: routeLinks.projects.root,
            eventName: "Click On Project Status Bar",
            eventAction: { action: Action.Add, resource: "Project" },
        },
        {
            status: "environment",
            label: "Add environment",
            href: routeLinks.infrastructure.environments.root,
            eventName: "Click On Project Status Bar",
            eventAction: { action: Action.Add, resource: "Environment" },
        },
        {
            status: "process",
            label: "Create deployment process",
            href: routeLinks.project(project).deployments.process.root,
            eventName: "Click On Project Status Bar",
            eventAction: { action: Action.Add, resource: "Deployment Process" },
        },
        {
            status: "deployment",
            label: "Release and deploy",
            href: routeLinks.project(project).deployments.releaseCreate,
            eventName: "Click On Project Status Bar",
            eventAction: { action: Action.Add, resource: "Release" },
        },
    ];
}
