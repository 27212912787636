import { flatten } from "lodash";
import { useEffect } from "react";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import mergeScopeValues from "~/areas/variables/MergeScopeValues";
import { FilterableVariableDisplayer } from "~/areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import type { ValueWithSource } from "~/areas/variables/VariableDisplayer/VariableDisplayer";
import { convertVariableResourcesToVariablesWithSource } from "~/areas/variables/convertVariableResourcesToVariablesWithSource";
import { ControlType } from "~/client/resources";
import type { LibraryVariableSetResource } from "~/client/resources/libraryVariableSetResource";
import type { ProjectResource } from "~/client/resources/projectResource";
import { HasVariablesInGit } from "~/client/resources/projectResource";
import type { TenantResource } from "~/client/resources/tenantResource";
import type { TenantVariableResource } from "~/client/resources/tenantVariableResource";
import { VariableType } from "~/client/resources/variableResource";
import type { ScopeValues, VariableSetResource } from "~/client/resources/variableSetResource";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { createFormPaperLayoutVersionControlledPageChanged } from "~/components/FormPaperLayout/reducers";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import store from "~/store";
import type { ValueSource } from "../../../../variables/SourceLink/SourceLink";
import type { VariableWithSource } from "../../../../variables/VariableDisplayer";
import { default as groupVariablesByName } from "../../../../variables/groupVariablesByName";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";

interface LibraryVariableSetWithVariables {
    variableSet: VariableSetResource;
    libraryVariableSet: LibraryVariableSetResource;
}

type AllVariablesProps = {
    doBusyTask: DoBusyTask;
    errors?: Errors;
    busy?: Promise<unknown> | boolean;
};

const AllVariables: React.FC<AllVariablesProps> = (props: AllVariablesProps) => {
    const allVariables = useAllVariablesDataLoading(props.doBusyTask);
    const projectContext = useProjectContext();
    const { model: project } = projectContext.state;

    const getVariables = (): ReadonlyArray<VariableWithSource> => {
        if (!allVariables.projectVariableSet) {
            return [];
        }
        return [
            ...buildProjectVariables(project, allVariables.projectVariableSet),
            ...buildLibraryVariableSetVariables(allVariables.libraryVariableSets),
            ...buildTenantLibraryVariables(project, allVariables.tenants, allVariables.tenantVariables),
            ...buildTenantProjectVariables(project, allVariables.tenants, allVariables.tenantVariables),
        ];
    };

    const getAvailableScopes = (): ScopeValues => {
        const allScopeValues: ScopeValues[] = allVariables.projectVariableSet ? [allVariables.projectVariableSet.ScopeValues, ...allVariables.libraryVariableSets.map((set) => set.variableSet.ScopeValues)] : [];
        return mergeScopeValues(allScopeValues);
    };

    // TODO @team-config-as-code
    // This is currently handled automatically by FormPaperLayout,
    // but this page doesn't use FormPaperLayout, so we do it manually here.
    // We should generalise the automatic solution, and remove this useEffect entirely.
    useEffect(() => {
        const gitVariables = HasVariablesInGit(project.PersistenceSettings);
        store.dispatch(createFormPaperLayoutVersionControlledPageChanged(gitVariables));
        return () => {
            store.dispatch(createFormPaperLayoutVersionControlledPageChanged(false));
        };
    }, [project.PersistenceSettings]);

    return (
        <PaperLayout busy={props.busy} breadcrumbTitle={project.Name} errors={props.errors} fullWidth={true} title={"All Variables"} statusSection={<ProjectStatus doBusyTask={props.doBusyTask} />}>
            <FilterableVariableDisplayer availableScopes={getAvailableScopes()} variableSections={[getVariables()]} doBusyTask={props.doBusyTask} />
        </PaperLayout>
    );
};

function getVariableTypeFromDisplaySettings(type?: ControlType): VariableType {
    switch (type) {
        case ControlType.Sensitive:
            return VariableType.Sensitive;
        case ControlType.Certificate:
            return VariableType.Certificate;
        case ControlType.AmazonWebServicesAccount:
            return VariableType.AmazonWebServicesAccount;
        case ControlType.AzureAccount:
            return VariableType.AzureAccount;
        case ControlType.GoogleCloudAccount:
            return VariableType.GoogleCloudAccount;
        case ControlType.WorkerPool:
            return VariableType.WorkerPool;
        default:
            return VariableType.String;
    }
}

interface AllVariablesForProject {
    projectVariableSet: VariableSetResource | undefined;
    libraryVariableSets: LibraryVariableSetWithVariables[];
    tenants: TenantResource[];
    tenantVariables: TenantVariableResource[];
}

const initialEmptyProjectVariableState: AllVariablesForProject = {
    tenants: [],
    libraryVariableSets: [],
    tenantVariables: [],
    projectVariableSet: undefined,
};

function useAllVariablesDataLoading(doBusyTask: DoBusyTask): AllVariablesForProject {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const loadProjectVariableSet = useProjectVariableSetLoader();
    const [state, setState] = React.useState<AllVariablesForProject>(initialEmptyProjectVariableState);

    useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const libraryVariableSetVariables = loadLibraryVariableSetVariables(project);
            const projectVariableSet = loadProjectVariableSet();
            const projectId = project.Id;
            const tenants = repository.Tenants.all({ projectId });
            const tenantVariables = repository.TenantVariables.all({ projectId });

            setState({
                projectVariableSet: await projectVariableSet,
                tenants: await tenants,
                tenantVariables: await tenantVariables,
                libraryVariableSets: await libraryVariableSetVariables,
            });
        },
        [project, projectContext.state.gitRef]
    );

    return state;
}

function useProjectVariableSetLoader() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;

    return React.useCallback(async () => {
        const variableSet = await projectContext.state.projectContextRepository.Variables.get();

        if (HasVariablesInGit(project.PersistenceSettings)) {
            const sensitiveVariableSet = await projectContext.state.projectContextRepository.Variables.getSensitive();

            // We are making two separate variable set requests for Git projects (text and secret variables use a
            // separate endpoint), but we need to return a single VariableSetResource. We're re-building a variable
            // set using all of the values from variableSet but combining the variables from both the text and secret
            // sets
            return {
                ...variableSet,
                Variables: [...variableSet.Variables, ...sensitiveVariableSet.Variables],
            };
        } else {
            // If the project doesn't have Git variables, everything will be in variableSet so we just return that
            // as is.
            return variableSet;
        }
    }, [projectContext.state.projectContextRepository.Variables, project.PersistenceSettings]);
}

export interface LoadedLibraryVariableSets {
    variableSet: VariableSetResource;
    libraryVariableSet: LibraryVariableSetResource;
}

export async function loadLibraryVariableSetVariables(project: ProjectResource): Promise<LoadedLibraryVariableSets[]> {
    const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: project.IncludedLibraryVariableSetIds });
    return Promise.all(
        libraryVariableSets.map(async (libraryVariableSet) => ({
            variableSet: await repository.Variables.get(libraryVariableSet.VariableSetId),
            libraryVariableSet,
        }))
    );
}

const buildTenantProjectVariables = (project: ProjectResource, tenants: TenantResource[], tenantVariables: TenantVariableResource[]): ReadonlyArray<VariableWithSource> => {
    const projectId = project.Id;
    const namedValues = flatten(tenantVariables.map(getAllProjectVariablesForTenant));

    const groupedByNameValues = groupVariablesByName(namedValues, (namedValue) => namedValue.name);
    return Object.keys(groupedByNameValues).map((name) => ({ name, values: groupedByNameValues[name].map((nv) => nv.value) }));

    function getAllProjectVariablesForTenant(tenantVariables: TenantVariableResource): Array<{ name: string; value: ValueWithSource }> {
        const tenant = tenants.find((t) => t.Id === tenantVariables.TenantId);
        const projectVariables = tenantVariables.ProjectVariables[projectId];
        const source: ValueSource = {
            tenantId: tenant?.Id ?? tenantVariables.TenantId,
            tenantName: tenant?.Name ?? "Unknown tenant",
            type: "project",
        };

        return flatten(
            projectVariables.Templates.map((template) =>
                Object.keys(projectVariables.Variables).map((environmentId) => {
                    const environmentValues = projectVariables.Variables[environmentId];
                    return {
                        name: template.Name,
                        value: {
                            type: getVariableTypeFromDisplaySettings(template.DisplaySettings["Octopus.ControlType"]),
                            scope: {
                                Environment: [environmentId],
                            },
                            value: convertPropertyValueResourceToString(environmentValues[template.Id] || template.DefaultValue),
                            source,
                            isPrompted: false,
                        },
                    };
                })
            )
        );
    }
};

const buildLibraryVariableSetVariables = (libraryVariableSets: LibraryVariableSetWithVariables[]): ReadonlyArray<VariableWithSource> =>
    flatten(
        libraryVariableSets.map((set) => {
            const source = {
                variableSetName: set.libraryVariableSet.Name,
                variableSetId: set.libraryVariableSet.Id,
            };
            return convertVariableResourcesToVariablesWithSource(set.variableSet.Variables, source);
        })
    );

const buildTenantLibraryVariables = (project: ProjectResource, tenants: TenantResource[], tenantVariables: TenantVariableResource[]): ReadonlyArray<VariableWithSource> => {
    const libraryVariableSetIds = project.IncludedLibraryVariableSetIds;
    const namedValues = flatten(tenantVariables.map(getAllLibrarySetVariablesForTenant));
    const groupedByNameValues = groupVariablesByName(namedValues, (namedValue) => namedValue.name);
    return Object.keys(groupedByNameValues).map((name) => ({ name, values: groupedByNameValues[name].map((nv) => nv.value) }));

    function getAllLibrarySetVariablesForTenant(tenantVariables: TenantVariableResource): Array<{ name: string; value: ValueWithSource }> {
        const tenant = tenants.find((t) => t.Id === tenantVariables.TenantId);
        const libraryVariablesLookup = tenantVariables.LibraryVariables;
        const source: ValueSource = {
            tenantId: tenant?.Id ?? tenantVariables.TenantId,
            tenantName: tenant?.Name ?? "Unknown tenant",
            type: "library",
        };

        return flatten(
            libraryVariableSetIds.map((variableSetId) => {
                const projectLibraryVariables = libraryVariablesLookup[variableSetId];
                const templates = projectLibraryVariables.Templates;
                const values = projectLibraryVariables.Variables;

                return templates.map((varTemplate) => ({
                    name: varTemplate.Name,
                    value: {
                        type: getVariableTypeFromDisplaySettings(varTemplate.DisplaySettings["Octopus.ControlType"]),
                        scope: {},
                        value: convertPropertyValueResourceToString(values[varTemplate.Id] || varTemplate.DefaultValue),
                        source,
                        isPrompted: false,
                    },
                }));
            })
        );
    }
};

function buildProjectVariables(project: ProjectResource, variableSet: VariableSetResource): ReadonlyArray<VariableWithSource> {
    const source = {
        projectName: project.Name,
        projectId: project.Id,
    };

    return convertVariableResourcesToVariablesWithSource(variableSet.Variables, source);
}

class AllVariablesLoader extends DataBaseComponent<{}> {
    render() {
        return <AllVariables doBusyTask={this.doBusyTask} errors={this.errors} busy={this.state?.busy} />;
    }
}

export default AllVariablesLoader;
