import cn from "classnames";
import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { Icon } from "~/components/IconButton/IconButton";
import IconButton from "~/components/IconButton/index";
import ContentPanel from "~/components/ProjectBasedActivation/components/ContentPanel";
import CustomTransparentDialogFrame from "~/components/ProjectBasedActivation/components/CustomTransparentDialogFrame";
import HelpPanel from "~/components/ProjectBasedActivation/components/HelpPanel";
import styles from "~/components/ProjectBasedActivation/styles.module.less";
import useLocalStorage from "~/hooks/useLocalStorage";

interface DialogWithHelpPanelProps extends DialogWithHelpPanelFrameProps {
    open: boolean;
    close: () => void;
}

const DialogWithHelpPanel = ({ children, open, close, ...frameProps }: PropsWithChildren<DialogWithHelpPanelProps>) => (
    <CustomDialog open={open} close={close} render={() => <DialogWithHelpPanelFrame {...frameProps}>{children}</DialogWithHelpPanelFrame>} />
);

interface DialogWithHelpPanelFrameProps {
    helpPanelContent: React.ReactNode;
    helpPanelImage: string;
    helpPanelAltImageText?: string;

    contentPanelClassName?: string;
    helpPanelClassName?: string;

    onToggleHelp?: (open: boolean) => void;
}

export const DialogWithHelpPanelFrame = ({ children, helpPanelContent, helpPanelImage, helpPanelAltImageText, contentPanelClassName, helpPanelClassName, onToggleHelp }: PropsWithChildren<DialogWithHelpPanelFrameProps>) => {
    const shouldShowHelpPanelLocalStorageKey = "pba.shouldShowHelpPanel";

    const [showHelpPanel, setShowHelpPanel] = useLocalStorage(shouldShowHelpPanelLocalStorageKey, true);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimate(false), 350);
    }, [animate]);

    return (
        <CustomTransparentDialogFrame>
            <ContentPanel className={cn({ [styles.contentPanelWithHelpPanel]: showHelpPanel }, contentPanelClassName)}>
                {children}
                {!showHelpPanel && (
                    <span className={styles.helpPanelBtn}>
                        <IconButton
                            toolTipContent={"Expand help"}
                            onClick={() => {
                                onToggleHelp?.(true);
                                setShowHelpPanel(true);
                                setAnimate(true);
                            }}
                            icon={Icon.OpenHelp}
                        />
                    </span>
                )}
            </ContentPanel>

            <HelpPanel
                content={helpPanelContent}
                image={helpPanelImage}
                className={cn({ [styles.active]: showHelpPanel, [styles.animate]: animate }, helpPanelClassName)}
                altImageText={helpPanelAltImageText ?? "Image"}
                onClose={() => {
                    onToggleHelp?.(false);
                    setShowHelpPanel(false);
                    setAnimate(true);
                }}
            />
        </CustomTransparentDialogFrame>
    );
};

export default DialogWithHelpPanel;
