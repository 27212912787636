import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter, Route } from "react-router-dom";
import { HasVariablesInGit } from "~/client/resources";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/Page";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import AllVariables from "./AllVariables";

export const AllVariablesPage = withPage({ page: pageIds.project().variables.all })(AllVariables);

type AllVariablesRouteProps = {
    path: string;
};

type Props = AllVariablesRouteProps & RouteComponentProps<{ projectSlug: string }>;

class AllVariablesRoute extends React.Component<Props> {
    render() {
        return (
            <BranchAwareRedirect condition={(project) => HasVariablesInGit(project.PersistenceSettings)}>
                <ErrorContextProvider>
                    <Switch>
                        <Route path={`${this.props.path}`} exact={true} render={(props) => <AllVariablesPage />} />
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>
        );
    }
}

const EnhancedAllVariablesRoute = withRouter(AllVariablesRoute);

export default EnhancedAllVariablesRoute;
