import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import type { GitRefDropDownMode } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import GitRefDropDown, { RefTypes } from "~/areas/projects/components/GitRefDropDown/GitRefDropDown";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import CommitDialog from "~/areas/projects/components/VersionControl/CommitDialog";
import type { GitBranchResource, GitRefResource, ProjectResource, ResourceCollection } from "~/client/resources/index";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { getGitRefType, GitRefType, toGitBranch } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState, DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { getBranchesList, getFilteredBranchesList } from "~/utils/BranchHelpers/BranchHelpers";

interface GitRefSelectorProps {
    project: Readonly<ProjectResource>;
    initialGitRef: GitRef;
    onChange: (selectedGitRef: GitRef) => void;
    onCreateBranch?: (newBranchName: string, parentGitRef: GitRef) => Promise<GitBranchResource>;
    onError?: (error: Error) => void;
    hasError?: boolean;
    disabled?: boolean;
    style?: "grey" | "white";
    mode?: GitRefDropDownMode;
    allowBranchCreation?: boolean;
}

interface GitRefSelectorInternalProps extends GitRefSelectorProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    items: GitRefOption[];
    totalItemCount: number;
    loadItems?: (refType: RefTypes, selectedGitRef: GitRef) => void;
}

function GitRefSelectorInternal(props: GitRefSelectorInternalProps) {
    const mapToRefTypes = (gitRefType: GitRefType | undefined): RefTypes => {
        if (!gitRefType) {
            throw new Error("Can't map undefined GifRefType to RefTypes");
        }
        switch (gitRefType) {
            case GitRefType.Branch:
                return RefTypes.Branches;
            case GitRefType.Tag:
                return RefTypes.Tags;
            case GitRefType.Commit:
                return RefTypes.Commits;
            case GitRefType.Unknown:
                throw new Error("Can't map GitRefType.Unknown to RefTypes");
        }
    };

    const { initialGitRef, project, onChange, onCreateBranch, loadItems } = props;

    const [refType, setRefType] = React.useState<RefTypes>(mapToRefTypes(getGitRefType(initialGitRef)));
    const isFormDirty = useSelector((state: GlobalState) => state.formPaperLayout.dirty);
    const saveClick = useSelector((state: GlobalState) => state.formPaperLayout.onSaveClick);
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [commitMessage, setCommitMessage] = React.useState({ summary: "", details: "" });
    const [branchName, setBranchName] = React.useState("");
    const branchProtectionsAreEnabled = useEnabledFeatureToggle("BranchProtectionsFeatureToggle");

    useEffect(() => {
        if (loadItems) loadItems(refType, initialGitRef);
    }, [initialGitRef, loadItems, refType]);

    const search = async (searchTerm: string): Promise<GitRefOption[]> => {
        try {
            if (refType === RefTypes.Tags) {
                const tagResources = await repository.Projects.searchTags(project, searchTerm);
                return tagResources.Items.map((t) => ({
                    text: t.Name,
                    value: t.CanonicalName,
                    canWrite: false,
                }));
            } else {
                // Fallback to branches
                return getFilteredBranchesList(project, searchTerm);
            }
        } catch (e) {
            if (props.onError) props.onError(e);
            return [];
        }
    };

    const onCreateBranchLocal = async (newBranchName: string): Promise<void> => {
        // If no 'onCreateBranch' handler has been given via props, and somehow
        // we've ended up in here, then something has gone wrong.
        if (!onCreateBranch) throw new Error("onCreateBranch was called, but no handler was given via props");

        if (isFormDirty && saveClick) {
            // Ask user if they want to save the pending changes
            // on the new branch, or discard
            setBranchName(newBranchName);
            setDialogOpen(true);
        } else {
            const gitRefResource = await onCreateBranch(newBranchName, initialGitRef);
            onChange(gitRefResource.CanonicalName);
        }
    };

    const onRefTypeChanged = (refType: RefTypes) => setRefType(refType);

    const closeDialog = useCallback(() => {
        setCommitMessage({ summary: "", details: "" });
        setDialogOpen(false);
    }, [setCommitMessage, setDialogOpen]);

    const onSave = useCallback(async () => {
        if (saveClick && onCreateBranch) {
            closeDialog();
            const newBranchResource = await onCreateBranch(branchName, initialGitRef);
            await saveClick(false, async () => onChange(newBranchResource.CanonicalName), newBranchResource, commitMessage);
        }
    }, [saveClick, onCreateBranch, onChange, closeDialog, branchName, initialGitRef, commitMessage]);

    // noinspection JSArrowFunctionBracesCanBeRemoved
    const onRequestRefresh = async (): Promise<void> => {
        if (loadItems) loadItems(refType, initialGitRef);
    };

    return (
        <>
            <GitRefDropDown
                isBusySearching={props.busy !== null && props.busy !== undefined}
                style={props.style}
                value={initialGitRef}
                items={props.items}
                totalItems={props.totalItemCount}
                onChange={(gitRefOption: GitRefOption) => onChange(gitRefOption.value)}
                onRequestRefresh={onRequestRefresh}
                onFilterChanged={search}
                disabled={props.disabled || props.hasError}
                onCreateBranch={onCreateBranchLocal}
                projectId={project.Id}
                refType={refType}
                onRefTypeChanged={onRefTypeChanged}
                mode={props.mode}
                allowBranchCreation={props.allowBranchCreation}
                disableBranchCreation={isFormDirty}
                errorMessage={props.hasError ? "Connection error" : undefined}
                branchProtectionsAreEnabled={branchProtectionsAreEnabled}
            />
            <CommitDialog
                open={isDialogOpen}
                gitRef={toGitBranch(branchName)}
                defaultSummary=""
                onCloseWithoutCommit={closeDialog}
                onCommit={onSave}
                onCommitMessageChanged={setCommitMessage}
                commitMessage={commitMessage}
                commitMessageAccessibleName=""
                commitDetailsAccessibleName=""
                projectId={project.Id}
                hideNewBranchOptions={true}
            />
        </>
    );
}

interface GitRefSelectorState extends DataBaseComponentState {
    project: Readonly<ProjectResource>;
    items: GitRefOption[];
    totalCount: number;
}

export class GitRefSelector extends DataBaseComponent<GitRefSelectorProps, GitRefSelectorState> {
    constructor(props: GitRefSelectorProps) {
        super(props);
        this.state = {
            project: props.project,
            items: [],
            totalCount: 0,
        };
    }

    static getDerivedStateFromProps(props: GitRefSelectorProps, state: GitRefSelectorState) {
        return {
            ...state,
            project: props.project,
        };
    }

    loadItems = (refType: RefTypes, selectedGitRef: GitRef): void => {
        this.doBusyTask(async () => {
            try {
                let resources: ResourceCollection<GitRefResource> | null = null;
                let items: GitRefOption[] = [];

                if (refType === RefTypes.Branches) {
                    const branchResources: ResourceCollection<GitBranchResource> = await repository.Projects.getBranches(this.state.project);
                    resources = branchResources;
                    items = await getBranchesList(this.state.project, branchResources.Items, selectedGitRef);
                } else if (refType === RefTypes.Tags) {
                    resources = await repository.Projects.getTags(this.state.project);
                    items = resources.Items.map((t) => ({
                        text: t.Name,
                        value: t.CanonicalName,
                        canWrite: false,
                    }));
                }

                this.setState({
                    ...this.state,
                    items,
                    totalCount: resources?.TotalResults ?? 0,
                });
            } catch (e) {
                if (this.props.onError) this.props.onError(e);
            }
        });
    };

    render() {
        return <GitRefSelectorInternal items={this.state.items} totalItemCount={this.state.totalCount} doBusyTask={this.doBusyTask} loadItems={this.loadItems} errors={this.errors} busy={this.state.busy} {...this.props}></GitRefSelectorInternal>;
    }
}
