/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import type { ProjectResource } from "~/client/resources";
import { ActionExecutionLocation } from "~/client/resources/actionExecutionLocation";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ChipIcon, ContextualMissingChip } from "~/components/Chips";
import { KeyValueEditList } from "~/components/EditList";
import { ExpandableFormSection, Summary } from "~/components/form";
import type { SummaryNode } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundStringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import Note from "../../../primitiveComponents/form/Note/Note";
import routeLinks from "../../../routeLinks";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import type { NameOrIdKey } from "../../KeyAccessProvider/types";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import InternalLink from "../../Navigation/InternalLink/InternalLink";
import type { ActionSummaryProps } from "../actionSummaryProps";
import pluginRegistry from "../pluginRegistry";
import type { ActionEditProps } from "../pluginRegistry";

const DeployReleaseSummary: React.FC<ActionSummaryProps> = (props) => {
    const itemsKeyedBy = useKeyedItemAccess();
    return <DeployReleaseSummaryInternal itemsKeyedBy={itemsKeyedBy} {...props} />;
};

type DeployReleaseSummaryInternalProps = ActionSummaryProps & { itemsKeyedBy: NameOrIdKey };

type ProjectName = string;
type ProjectOrName = ProjectResource | ProjectName;

class DeployReleaseSummaryInternal extends BaseComponent<DeployReleaseSummaryInternalProps, { projectOrName: ProjectOrName | null }> {
    constructor(props: DeployReleaseSummaryInternalProps) {
        super(props);
        this.state = {
            projectOrName: null,
        };
    }
    async componentDidMount() {
        //TODO: realistically we can't load all projects here unless we have some form of caching layer, so we can't show a ghost chip if a project doesn't align
        //in the summary.
        const nameOrId = this.props.properties["Octopus.Action.DeployRelease.ProjectId"] as string;
        const project: ProjectOrName | null = nameOrId.includes("#{") ? null : this.props.itemsKeyedBy === "Name" ? nameOrId : await repository.Projects.get(nameOrId);
        this.setState({ projectOrName: project });
    }
    render() {
        return (
            <div>
                Deploy a release from project{" "}
                {this.state.projectOrName ? (
                    <>
                        <b>{typeof this.state.projectOrName === "string" ? this.state.projectOrName : this.state.projectOrName.Name}</b>.
                    </>
                ) : (
                    <ContextualMissingChip lookupKey={this.props.properties["Octopus.Action.DeployRelease.ProjectId"]?.toString() ?? ""} type={ChipIcon.Project} />
                )}
            </div>
        );
    }
}

enum DeploymentCondition {
    Always = "Always",
    IfNotCurrentVersion = "IfNotCurrentVersion",
    IfNewer = "IfNewer",
}

interface DeployReleaseProperties {
    "Octopus.Action.DeployRelease.ProjectId": string;
    "Octopus.Action.DeployRelease.DeploymentCondition": string;
    "Octopus.Action.DeployRelease.Variables": string;
}

interface DeployReleaseEditState {
    projects: Array<{ value: string; text: string; slug: string }>;
}

type DeployReleaseEditProps = ActionEditProps<DeployReleaseProperties>;

const DeployReleaseEdit: React.FC<DeployReleaseEditProps> = (props) => {
    const keyedBy = useKeyedItemAccess();
    return <DeployReleaseEditInternal itemsKeyedBy={keyedBy} {...props} />;
};

type DeployReleaseEditInternalProps = DeployReleaseEditProps & { itemsKeyedBy: NameOrIdKey };

class DeployReleaseEditInternal extends BaseComponent<DeployReleaseEditInternalProps, DeployReleaseEditState> {
    constructor(props: DeployReleaseEditInternalProps) {
        super(props);
        this.state = {
            projects: [],
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]) {
                this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: DeploymentCondition.Always });
            }
            const projects = await repository.Projects.summaries();
            //TODO: investigate ways we can protect consuming actions from needing to know what things are keyed by
            this.setState({ projects: projects.map((p) => ({ value: p[this.props.itemsKeyedBy], text: p.Name, slug: p.Slug })) });
        });
    }

    render() {
        return (
            <div>
                <ExpanderSectionHeading title="Deploy a release" />
                <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.ProjectId" isExpandedByDefault={this.props.expandedByDefault} title="Project" summary={this.summary()} help={<div>Select a project that will be deployed.</div>}>
                    <BoundSelect
                        label="Select a Project"
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={""}
                        items={this.state.projects}
                        onChange={(projectId) => this.props.setProperties({ ["Octopus.Action.DeployRelease.ProjectId"]: projectId })}
                        error={this.props.getFieldError("Octopus.Action.DeployRelease.ProjectId")}
                        allowFilter={true}
                        autoFocus
                        value={this.props.properties["Octopus.Action.DeployRelease.ProjectId"]}
                    />
                    <Note>
                        See our <ExternalLink href="DeployReleaseStep">documentation</ExternalLink> for more information on deploying projects with the Deploy Release step.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.DeployRelease.DeploymentCondition"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Deployment condition"
                    summary={this.summaryDeployment()}
                    help={<span>Control when this deployment should run.</span>}
                >
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={DeploymentCondition.Always}
                        value={this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: x })}
                        label="Deployment condition"
                    >
                        <RadioButton value={DeploymentCondition.Always} label="Always" isDefault />
                        <RadioButton value={DeploymentCondition.IfNotCurrentVersion} label="If the selected release is not the current release in the environment" />
                        <RadioButton value={DeploymentCondition.IfNewer} label="If the selected release has a higher version than the current release in the environment" />
                    </BoundStringRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.DeployRelease.Variables"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Variables"
                    summary={this.summaryVariables()}
                    fillCardWidth={CardFill.FillRight}
                    help={<span>Pass variables through to the child deployment.</span>}
                >
                    <KeyValueEditList
                        items={this.props.properties["Octopus.Action.DeployRelease.Variables"]}
                        name="Variable"
                        separator="="
                        onChange={(val) => this.props.setProperties({ ["Octopus.Action.DeployRelease.Variables"]: val })}
                        valueLabel="Value"
                        keyLabel="Variable name"
                        hideBindOnKey={true}
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        gitRef={this.props.gitRef}
                    />
                    <Note>
                        See our <ExternalLink href="DeployReleaseStepVariables">documentation</ExternalLink> for more information on passing variables to deployments triggered by the Create Release step.
                    </Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private summary(): SummaryNode {
        const type = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        if (!type) {
            return Summary.placeholder("Select a project that will be deployed");
        }
        const projectIdOrName = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        const project = this.state.projects.find((p) => p.value === projectIdOrName);

        //We have to use the slug from the project for the route links, as the value can potentially be an id or a name and we don't support name for the route links.
        const link = project ? (
            projectIdOrName.includes("#{") ? (
                <b>{project.text}</b> // Don't link to bound fields
            ) : (
                <InternalLink to={routeLinks.project(project.slug).root}>{project.text}</InternalLink>
            )
        ) : (
            <ContextualMissingChip lookupKey={projectIdOrName} type={ChipIcon.Project} />
        );

        return Summary.summary(<span>Deploy a release from project {link}</span>);
    }

    private summaryDeployment(): SummaryNode {
        const condition = this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"];

        if (condition === DeploymentCondition.IfNotCurrentVersion) {
            return Summary.summary(
                <span>
                    Deploy the release if it is <b>not the current release</b> in the environment
                </span>
            );
        }

        if (condition === DeploymentCondition.IfNewer) {
            return Summary.summary(
                <span>
                    Deploy the release if it has a <b>higher version</b> than the current release in the environment
                </span>
            );
        }

        if (condition === DeploymentCondition.Always) {
            return Summary.default(<span>Deploy every time</span>);
        }

        return Summary.summary(<span>Deploy when the expression is true</span>);
    }

    private summaryVariables(): SummaryNode {
        const variables = JSON.parse(this.props.properties["Octopus.Action.DeployRelease.Variables"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No variables specified");
        } else {
            const text = Object.keys(variables)
                .map((m) => m + " = " + variables[m])
                .join(", ");
            return Summary.summary(text);
        }
    }
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunInContainer: false,
    actionType: "Octopus.DeployRelease",
    summary: (properties, targetRolesAsCSV) => <DeployReleaseSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => false,
    canBeChild: true,
    edit: DeployReleaseEdit,
    targetRoleOption: (action) => TargetRoles.None,
});
