import type { SerilogCompactFormatLogEvent } from "~/client/resources/SerilogCompactFormatLogEvent";
import type { Client } from "../client";

export class LogRepository {
    constructor(private readonly client: Client) {}

    ingestLogEvents(logEvents: SerilogCompactFormatLogEvent[]): Promise<void> {
        return this.client.post("/ingest", serializeLogEvents(logEvents));
    }
}

function serializeLogEvents(logEvents: SerilogCompactFormatLogEvent[]) {
    // https://github.com/OctopusDeploy/OctopusDeploy/blob/master/docs/OBSERVABILITY/Logging.md#server-log-ingestion-api
    return logEvents.map((l) => JSON.stringify(l)).join("\n");
}
