import type { ReportMetricsPageProps } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import { ReportMetricsLayout } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { InsightsMetricsSplit } from "~/client/repositories/insightsReportRepository";
import type { InsightsReportResource } from "~/client/resources/insightsReportResource";
import { getChartAxisDateFormatter } from "../../dataTransformation/dateHelpers";
import { getChartReadyInsightsData } from "../../dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "../../dataTransformation/getYAxisDataMax";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "../../insightsCadence";
import { InsightsTooltip } from "../ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "../Charts/LineChart";
import { DownloadReportCsvButton } from "../DownloadReportCsvButton";
import { InsightsChartCard } from "../InsightsChartCard";
import { DeploymentFrequencyTrendTables } from "./TrendTables/DeploymentFrequencyTrendTables";

type Props = ReportMetricsPageProps;

function ReportDeploymentFrequencyInner({ report, bffResponse, split, cadence, busy }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const maxYAxisValue = getYAxisDataMax(chartData, "NumberOfSuccessfulDeployments");

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    const showSeries = split !== InsightsMetricsSplit.None;

    useEffect(() => {
        dispatchAction("View Insights Report Deployment Frequency");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main>
            <section style={{ padding: "0rem 1rem 2rem" }}>
                <InsightsChartCard height={500}>
                    <LineChart
                        data={chartData}
                        loading={Boolean(busy)}
                        dataKey="NumberOfSuccessfulDeployments"
                        yAxisLabel="No. of deployments"
                        xAxisDataKey="StartOfInterval"
                        xAxisType="category"
                        yAxisType="number"
                        yAxisDomain={[0, maxYAxisValue]}
                        yAxisTickArray={getEvenlySpacedTickArray(maxYAxisValue)}
                        xTickFormatter={dateFormatter}
                        tooltip={<InsightsTooltip showSeries={showSeries} dataKey="NumberOfSuccessfulDeployments" valueFormatter={(v) => `${v.toLocaleString()} deployment${v === 1 ? "" : "s"}`} cadence={cadence} />}
                        xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}
                        showLegend={showSeries}
                    />
                </InsightsChartCard>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                    <DownloadReportCsvButton report={report} />
                </div>
            </section>
            <section>
                <DeploymentFrequencyTrendTables trends={bffResponse.DeploymentFrequency} />
            </section>
        </main>
    );
}

export function ReportDeploymentFrequency({ report }: { report: InsightsReportResource }) {
    return (
        <ReportMetricsLayout title="Deployment frequency" report={report} trendKey="DeploymentFrequency">
            {(props) => <ReportDeploymentFrequencyInner {...props} />}
        </ReportMetricsLayout>
    );
}
