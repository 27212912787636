/* eslint-disable custom-portal-rules/no-restricted-imports */

import { default as MaterialDivider } from "@material-ui/core/Divider";
import classnames from "classnames";
import * as React from "react";
import styles from "./style.module.less";

interface MultilineTextContainerProps {
    readonly isFocused?: boolean;
    onClick?: () => void;
    hideDivider?: boolean;
    showHand?: boolean;
}

const MultilineTextContainer: React.FC<MultilineTextContainerProps> = ({ isFocused = false, children, onClick, hideDivider = false, showHand = true }) => {
    return (
        <div
            className={styles.multilineContainer}
            onClick={() => {
                if (isFocused && onClick) {
                    onClick();
                }
            }}
        >
            <div className={classnames({ [styles.multilineText]: true, [styles.multilineTextFocused]: isFocused, [styles.handCursor]: showHand })}>{children}</div>
            {isFocused && !hideDivider ? <MaterialDivider /> : ""}
        </div>
    );
};

export default MultilineTextContainer;
