import type { EnabledFeatureToggles } from "~/client/resources/enabledFeatureToggles";
import type { Client, GlobalAndSpaceRootLinks } from "../client";
import type ResourceWithId from "../resources/resource";

class ConfigurationRepository<TResource extends ResourceWithId> {
    protected client: Client;
    private configurationLinkName: GlobalAndSpaceRootLinks;

    constructor(configurationLinkName: GlobalAndSpaceRootLinks, client: Client) {
        this.configurationLinkName = configurationLinkName;
        this.client = client;
    }
    get(): Promise<TResource> {
        return this.client.get<TResource>(this.client.getLink(this.configurationLinkName));
    }

    modify(resource: TResource): Promise<TResource> {
        return this.client.update<TResource>(resource.Links["Self"], resource);
    }

    clearCache(): Promise<void> {
        const clearCacheLink = this.client.getLink("VersionControlClearCache");
        return this.client.post(clearCacheLink, {});
    }

    enabledFeatureToggles(): Promise<EnabledFeatureToggles> {
        const enabledFeatureToggleLink = this.client.getLink("EnabledFeatureToggles");
        return this.client.get(enabledFeatureToggleLink, {});
    }
}

export default ConfigurationRepository;
