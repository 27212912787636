import type { SerilogCompactFormatLogEvent, SerilogLogLevel } from "~/client/resources/SerilogCompactFormatLogEvent";
import { repository, session } from "~/clientInstance";
import { exhaustiveCheck } from "~/utils/exhaustiveCheck";
import type { LogLevel, LogEvent, StructuredLogEvent } from "~/utils/logging/LogEvent";
import { isLogEventWithPossibleError, isStructuredLogEvent } from "~/utils/logging/LogEvent";
import { createConsoleSink } from "~/utils/logging/consoleSink";
import { createLogger } from "~/utils/logging/createLogger";
import { globalLogConfiguration } from "~/utils/logging/logger";
import { consoleLoggers } from "~/utils/logging/originalConsoleLoggers";

export function configureSerilogIngestionSink() {
    globalLogConfiguration.pipeSerilogIngestionLogEventsTo({
        isEnabled: serilogIngestionSinkIsEnabled,
        receiveLogEvents: sendLogEventsToSerilogIngestionEndpoint,
    });
}

function serilogIngestionSinkIsEnabled(): boolean | "indeterminate" {
    // We currently only allow log events to be sent if the client is connected
    // If we aren't connected or authenticated, any events logged will keep queueing until we do connect
    if (!session.isAuthenticated() || session.featureToggles === null) return "indeterminate";

    return !session.featureToggles.includes("DisablePortalLogIngestionFeatureToggle");
}

function sendLogEventsToSerilogIngestionEndpoint(logEvents: LogEvent[]) {
    // Nothing should block on this, or report progress. It is a "background" task
    // noinspection JSIgnoredPromiseFromCall
    fireAndForgetLogEvents();

    async function fireAndForgetLogEvents() {
        try {
            await repository.Logs.ingestLogEvents(logEvents.map(convertToSerilogCompactFormat));
        } catch (e) {
            // Don't keep pumping messages to the serilog sink if something is failing. Just go directly to the console instead.
            createLogger(createConsoleSink(consoleLoggers)).error("Unable to ingest messages: {ErrorMessage}", { ErrorMessage: e.toString() });
        }
    }
}

function convertToSerilogCompactFormat(logEvent: LogEvent): SerilogCompactFormatLogEvent {
    if (isStructuredLogEvent(logEvent)) {
        const possibleStack = getStackForStructuredLogEventWithPossibleError(logEvent);
        const possibleErrorProperties = possibleStack ? { "@x": possibleStack } : {};
        return {
            ...logEvent.propertyValues,
            "@mt": logEvent.messageTemplate,
            "@l": getSerilogLogLevel(logEvent.logLevel),
            SourceContext: "Portal",
            "@t": logEvent.timestamp.toISOString(),
            ...possibleErrorProperties,
        };
    }
    const propertyValues = {
        ...logEvent.context,
        ConsoleParams: logEvent.messageArguments,
    };

    return {
        ...propertyValues,
        "@mt": logEvent.message,
        "@t": logEvent.timestamp.toISOString(),
        "@l": getSerilogLogLevel(logEvent.logLevel),
        SourceContext: "Portal",
    };
}

function getStackForStructuredLogEventWithPossibleError(logEvent: StructuredLogEvent) {
    if (isLogEventWithPossibleError(logEvent)) {
        const errorStack = logEvent.error?.stack;
        const capturedStack = logEvent.stack;

        return errorStack ? combineErrorStackAndCapturedStack(errorStack, capturedStack) : capturedStack;
    }

    return null;
}

function combineErrorStackAndCapturedStack(errorStack: string, capturedStack: string | undefined) {
    return capturedStack ? `${errorStack}\nCaptured By\n ${capturedStack}` : errorStack;
}

function getSerilogLogLevel(logLevel: LogLevel): SerilogLogLevel {
    switch (logLevel) {
        case "verbose":
            return "Verbose";
        case "debug":
            return "Debug";
        case "information":
            return "Information";
        case "warning":
            return "Warning";
        case "error":
            return "Error";
        case "fatal":
            return "Fatal";
        default:
            return exhaustiveCheck(logLevel, "Unexpected log level");
    }
}
