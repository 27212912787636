/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import type { GitRefResource, ProjectResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import type { Binding } from "~/components/Features/iisWebSite/bindingHelpers";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { BooleanRadioButtonGroup } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import CertificateVariableSelect from "~/components/form/CertificateSelect/CertificateVariableSelect";
import DialogFormSectionHeading from "~/components/form/Sections/DialogFormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";

interface BindingState extends DataBaseComponentState {
    binding: Binding;
    managedByOctopus: boolean;
    project?: ProjectResource | null;
}

interface BindingProps {
    binding: Binding;
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    onAdd(Binding: Binding): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class BindingDialog extends DataBaseComponent<BindingProps, BindingState> {
    constructor(props: BindingProps) {
        super(props);
        this.state = {
            binding: null!,
            managedByOctopus: true,
            project: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;

            this.setState({
                binding: this.props.binding,
                managedByOctopus: !this.props.binding.thumbprint,
                project,
            });
        });
    }

    handleProtocolChanged = async (protocol: string | undefined) => {
        await this.doBusyTask(async () => {
            this.setBindingState({ protocol: protocol! });
            if (protocol === "https") {
                this.setBindingState({ port: "443" });
            }
        });
    };

    handleManagedByOctopusChanged = async (managed: boolean) => {
        await this.doBusyTask(async () => {
            if (!managed) {
                this.setState((state) => ({
                    binding: { ...state!.binding, certificateVariable: null },
                    managedByOctopus: managed,
                }));
            } else {
                this.setState((state) => ({
                    binding: { ...state!.binding, thumbprint: null },
                    managedByOctopus: managed,
                }));
            }
        });
    };

    save = () => {
        const errors: { [name: string]: string } = {};

        const binding = this.state.binding;
        if ((this.state.binding.protocol || "").trim().length === 0) {
            errors["binding.protocol"] = "A protocol must be supplied.";
        }

        if ((this.state.binding.port || "").trim().length === 0) {
            errors["binding.port"] = "A port must be supplied.";
        }

        if (this.state.binding.protocol === "https") {
            if (this.state.managedByOctopus) {
                if (!this.state.binding.certificateVariable) {
                    errors["binding.certificateVariable"] = "An SSL certificate variable must be provided for HTTPS bindings.";
                }
            } else {
                if (!this.state.binding.thumbprint || this.state.binding.thumbprint.trim() === "") {
                    errors["binding.thumbprint"] = "An SSL certificate thumbprint must be provided for HTTPS bindings.";
                } else if (!isBound(this.state.binding.thumbprint)) {
                    binding.thumbprint = binding.thumbprint?.replace(/[^0-9A-z]/g, "")!;
                }
            }
        }

        if (
            this.state.binding.protocol &&
            this.state.binding.requireSni &&
            this.state.binding.protocol.toLowerCase() !== "http" &&
            this.state.binding.requireSni.toLowerCase() !== "false" && // If the user used a binding they would expect this to evaluate to true in some configuration
            (this.state.binding.host || "").trim().length === 0
        ) {
            errors["binding.host"] = "A host name must be provided if server name identification is enabled.";
        }

        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            if (errorKeys.length === 1) {
                this.setValidationErrors(errors[errorKeys[0]]);
            } else {
                this.setValidationErrors("The binding is not valid", errors);
            }
            return false;
        }

        return this.props.onAdd(binding);
    };

    render() {
        return (
            <OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Add Binding"}>
                {this.state.binding && (
                    <div>
                        <BoundSelect
                            variableLookup={{
                                localNames: this.props.localNames,
                            }}
                            resetValue={"http"}
                            value={this.state.binding.protocol}
                            onChange={this.handleProtocolChanged}
                            items={[
                                { value: "http", text: "HTTP" },
                                { value: "https", text: "HTTPS" },
                            ]}
                            placeholder="Protocol"
                            label="Protocol"
                        />
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.port} onChange={(x) => this.setBindingState({ port: x })} label="Port" />
                        <Note>The TCP port number that this binding will listen on.</Note>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.ipAddress} onChange={(x) => this.setBindingState({ ipAddress: x })} label="IP address" />
                        <Note>
                            The IP address that the binding will listen on. Use <code>*</code> for any address, or specify an address such as <code>10.0.0.1</code>. If using a IPv6 address remember to enclose in square brackets such as{" "}
                            <code>[::1]</code>.
                        </Note>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.host} onChange={(x) => this.setBindingState({ host: x })} label="Host name" />
                        <Note>
                            The host header that this binding will listen on. Example:
                            <code>www.contoso.com</code>. Leave empty to use any host header.
                        </Note>

                        {this.state.binding.protocol !== "http" && (
                            <div>
                                <DialogFormSectionHeading title="Certificate" />
                                <BooleanRadioButtonGroup label="Is the HTTPS certificate managed by Octopus or externally?" value={this.state.managedByOctopus} onChange={this.handleManagedByOctopusChanged}>
                                    <RadioButton value={true} label="Certificate managed by Octopus" isDefault={true} />
                                    <Note>If the certificate is managed by Octopus, it will be automatically imported into the Windows Certificate Store.</Note>
                                    <RadioButton value={false} label="Certificate managed externally" />
                                    <Note>If managed externally, the certificate thumbprint is configured. The certificate must have been imported into the Windows Certificate Store.</Note>
                                </BooleanRadioButtonGroup>

                                {!this.state.managedByOctopus && (
                                    <div>
                                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.thumbprint!} onChange={(x) => this.setBindingState({ thumbprint: x })} label="SSL thumbprint" />
                                        <Note>The X.509 certificate thumbprint to use.</Note>
                                    </div>
                                )}
                                {this.state.managedByOctopus && (
                                    <div>
                                        {this.state.project ? (
                                            <CertificateVariableSelect
                                                projectId={this.props.projectId}
                                                gitRef={this.props.gitRef}
                                                doBusyTask={this.doBusyTask}
                                                value={this.state.binding.certificateVariable}
                                                onChange={(x) => this.setBindingState({ certificateVariable: x })}
                                            />
                                        ) : (
                                            <VariableLookupText localNames={this.props.localNames} value={this.state.binding.certificateVariable} onChange={(x) => this.setBindingState({ certificateVariable: x })} label="Certificate variable" />
                                        )}
                                        <Note>
                                            A project variable that refers to a certificate.{" "}
                                            <span>
                                                <ExternalLink href="CertificatesDocumentation">Learn more</ExternalLink>.
                                            </span>
                                        </Note>
                                    </div>
                                )}

                                <BoundStringCheckbox
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                    }}
                                    resetValue={"False"}
                                    title="Server name identification"
                                    value={this.state.binding.requireSni.toString()}
                                    onChange={(x) => this.setBindingState({ requireSni: x as any })}
                                    label="Required"
                                />
                            </div>
                        )}

                        <BoundStringCheckbox
                            variableLookup={{
                                localNames: this.props.localNames,
                            }}
                            resetValue={"False"}
                            title="Binding status"
                            value={this.state.binding.enabled.toString()}
                            onChange={(x) => this.setBindingState({ enabled: x as any })}
                            label="Enabled"
                            note={<span>Disable this binding to skip it (the binding will not be added).</span>}
                        />
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    private setBindingState<K extends keyof Binding>(state: Pick<Binding, K>, callback?: () => void) {
        this.setChildState1("binding", state);
    }
}

export default BindingDialog;
