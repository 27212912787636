/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as _ from "lodash";
import * as React from "react";
import type { GitRefResource } from "~/client/resources";
import type { IngressRule } from "~/components/Actions/kubernetes/kubernetesIngressComponent";
import type { ServicePort } from "~/components/Actions/kubernetes/kubernetesServiceComponent";
import { KubernetesWildcardIngressHostRegex, KubernetesNameRegex, KubernetesIngressHostRegex, KubernetesPathTypeRegex } from "~/components/Actions/kubernetes/kubernetesValidation";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { ExtendedKeyValueEditList } from "~/components/EditList/ExtendedKeyValueEditList";
import type { KeyValueOption } from "~/components/EditList/ExtendedKeyValueEditList";
import isBound from "~/components/form/BoundField/isBound";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import Note from "../../../primitiveComponents/form/Note/Note";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { VariableLookupText } from "../../form/VariableLookupText";

const pathTypeValues: Item[] = [
    {
        text: "Implementation Specific",
        value: "ImplementationSpecific",
    },
    {
        text: "Exact",
        value: "Exact",
    },
    {
        text: "Prefix",
        value: "Prefix",
    },
];

interface IngressRuleState extends DataBaseComponentState {
    ingressRule: IngressRule;
}

interface IngressRuleProps {
    ingressRule: IngressRule;
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    standAlone: boolean;
    servicePorts: ServicePort[];
    onAdd(Binding: IngressRule): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class IngressRuleDialog extends DataBaseComponent<IngressRuleProps, IngressRuleState> {
    constructor(props: IngressRuleProps) {
        super(props);
        this.state = {
            ingressRule: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setDefaults(this.props.ingressRule.http.paths);
            this.setState({
                ingressRule: this.props.ingressRule,
            });
        });
    }

    save = () => {
        let valid = true;
        const binding = this.state.ingressRule;

        if (!!binding.host && !!binding.host.trim() && !isBound(binding.host.trim()) && binding.host.indexOf("*") !== -1 && !KubernetesWildcardIngressHostRegex.exec(binding.host.trim())) {
            this.setValidationErrors(
                "The ingress host must be empty, or a wildcard DNS-1123 subdomain that starts with '*.', followed by a valid DNS subdomain, " +
                    "which must consist of lower case alphanumeric characters, '-' or '.' and end with an alphanumeric character(e.g. '*.example.com').",
                {
                    ServicePortName:
                        "The ingress host must be empty, or a wildcard DNS-1123 subdomain that starts with '*.', followed by a valid DNS subdomain, " +
                        "which must consist of lower case alphanumeric characters, '-' or '.' and end with an alphanumeric character(e.g. '*.example.com').",
                }
            );
            valid = false;
        }

        if (!!binding.host && !!binding.host.trim() && !isBound(binding.host.trim()) && binding.host.indexOf("*") === -1 && !KubernetesIngressHostRegex.exec(binding.host.trim())) {
            this.setValidationErrors("The ingress host must be empty, or a a DNS-1123 subdomain must consist of lower case alphanumeric characters, " + "'-' or '.', and must start and end with an alphanumeric character. (e.g. example.com)", {
                ServicePortName: "The ingress host must be empty, or a a DNS-1123 subdomain must consist of lower case alphanumeric characters, " + "'-' or '.', and must start and end with an alphanumeric character. (e.g. example.com)",
            });
            valid = false;
        }

        if (binding.http.paths.length === 0) {
            this.setValidationErrors("At least one ingress path must be defined.");
            valid = false;
        }

        binding.http.paths.forEach((p) => {
            p.keyError = !!p.key && (isBound(p.key) || p.key.trim().startsWith("/")) ? undefined : "Ingress paths must all start with a forward slash.";
        });

        binding.http.paths.forEach((p) => {
            p.valueError =
                !!p.value && !!p.value.trim() && (isBound(p.value.trim()) || isNaN(parseInt(p.value.trim(), 10)) || (parseInt(p.value.trim(), 10) >= 1 && parseInt(p.value.trim(), 10) <= 65535))
                    ? undefined
                    : "Ingress rules must have a service port between 1 and 65535, or a port name.";
        });

        binding.http.paths.forEach((p) => {
            p.optionError = !this.props.standAlone || (!!p.option && (isBound(p.option) || KubernetesNameRegex.exec(p.option.trim()))) ? undefined : "Ingress rules must have a valid service name.";
        });

        binding.http.paths.forEach((p) => {
            p.option2Error = !!p.option2 && (isBound(p.option2) || KubernetesPathTypeRegex.exec(p.option2.trim())) ? undefined : "Ingress rules must have a valid path type.";
        });

        if (binding.http.paths.find((p) => !!p.keyError || !!p.valueError || !!p.optionError || !!p.option2Error)) {
            this.setValidationErrors("The ingress rules are incorrectly configured.");
            valid = false;
        }

        if (valid) {
            return this.props.onAdd(binding);
        }

        return valid;
    };

    render() {
        return (
            <OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Add Host Rule"}>
                {this.state.ingressRule && (
                    <div>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.ingressRule.host} error={this.getFieldError("IngressRuleHost")} onChange={(x) => this.setIngressState({ host: x })} label="Host" />
                        <Note>An optional value that defines the host that this ingress rule applies to. If left blank, this rule will apply to all hosts.</Note>
                        {this.props.standAlone ? (
                            <div>
                                <p>
                                    <strong>Paths</strong> <br />
                                    Include the URL path and the service <code>Port</code> this ingress path directs traffic to. The service port can be the service name, or the port number.
                                </p>
                                <ExtendedKeyValueEditList
                                    items={() => this.state.ingressRule.http.paths}
                                    name="Path"
                                    onChange={(val) => {
                                        this.setIngressState({ http: { paths: val } });
                                        this.repositionDialog();
                                    }}
                                    valueLabel="Service port"
                                    optionLabel="Service name"
                                    keyLabel="Path"
                                    option2Label="Path Type"
                                    option2Values={pathTypeValues}
                                    option2Reset={"ImplementationSpecific"}
                                    hideBindOnKey={false}
                                    projectId={this.props.projectId}
                                    gitRef={this.props.gitRef}
                                    addToTop={true}
                                    onAdd={(val) => {
                                        this.setDefaults(val);
                                        this.setIngressState({ http: { paths: val } });
                                        this.repositionDialog();
                                    }}
                                    localNames={this.props.localNames}
                                />{" "}
                            </div>
                        ) : (
                            <div>
                                <p>
                                    <strong>Paths</strong> <br />
                                    Include the URL path and the service <code>Port</code> this ingress path directs traffic to. The service port can be the service name, or the port number.
                                </p>
                                <ExtendedKeyValueEditList
                                    items={() => this.state.ingressRule.http.paths}
                                    name="Path"
                                    onChange={(val) => {
                                        this.setIngressState({ http: { paths: val } });
                                        this.repositionDialog();
                                    }}
                                    valueLabel="Service port"
                                    keyLabel="Path"
                                    option2Label="Path Type"
                                    option2Values={pathTypeValues}
                                    option2Reset={"ImplementationSpecific"}
                                    hideBindOnKey={false}
                                    projectId={this.props.projectId}
                                    gitRef={this.props.gitRef}
                                    getValueOptions={(searchText) => this.getPortOptions(searchText)}
                                    addToTop={true}
                                    onAdd={(val) => {
                                        this.setDefaults(val);
                                        this.setIngressState({ http: { paths: val } });
                                        this.repositionDialog();
                                    }}
                                    localNames={this.props.localNames}
                                />
                            </div>
                        )}
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    private getPortOptions = async (searchText: string) => {
        const results = _.chain(this.props.servicePorts)
            .flatMap((p) => [p.name, p.port])
            .filter((p) => !!p)
            .sort()
            .filter((v) => !searchText || v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map((f) => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake,
        };
    };

    private setIngressState<K extends keyof IngressRule>(state: Pick<IngressRule, K>, callback?: () => void) {
        this.setChildState1("ingressRule", state);
    }

    private setDefaults(items: KeyValueOption[]) {
        items?.forEach((i) => {
            if (i.option2 === undefined || i.option2 === "") i.option2 = "ImplementationSpecific";
        });
    }

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }
}

export default IngressRuleDialog;
