import * as React from "react";
import type { AuthenticationProviderElement } from "~/client/authentication";
import type { LoginState } from "~/client/resources";
import { OctopusError } from "~/client/resources";
import { AuthenticationError } from "~/client/resources/authenticationError";
import { client } from "~/clientInstance";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import extensionManager from "~/extensionsManager";
import type { Logger } from "~/utils/logging/createLogger";
import { logger } from "~/utils/logging/logger";
const $script = require("scriptjs");

interface AuthProviderInternalProps {
    provider: AuthenticationProviderElement;
    loginState: LoginState;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    onError(error: AuthenticationError): void;
    isDarkMode: boolean;
}

class AuthProviderInternal extends React.Component<AuthProviderInternalProps, {}> {
    rootNode: React.RefObject<HTMLDivElement> = React.createRef();

    async componentDidMount() {
        const { provider, loginState, shouldAutoSignIn, autoSignInProviderName, isDarkMode } = this.props;

        const contextualLogger = logger.forContext({ provider, loginState, shouldAutoSignIn, autoSignInProviderName });

        await Promise.all(loadCSS(provider.CSSLinks, contextualLogger));

        $script(
            provider.JavascriptLinks.map((link) => loadJS(link, contextualLogger)),
            provider.Name
        );

        $script.ready(provider.Name, () => {
            const extension = extensionManager.getExtension(provider.Name, "auth_provider");
            if (!extension) {
                logger.error("Could not find authentication {providerName} extension", { providerName: provider.Name });
            }

            //Changes of dark mode won't get reflected by the extension, however this is
            //acceptable for now since we can't change the theme on the login page. Once this
            //changes, we will need to call this multiple times.
            const authProvider = extension(client, provider, loginState, this.onExtensionError, isDarkMode);

            if (!shouldAutoSignIn) {
                const node = this.rootNode.current;
                if (node) {
                    node.innerHTML = authProvider.LinkHtml;
                    node.addEventListener("click", authProvider.SignIn);
                }
            }

            if (shouldAutoSignIn && provider.Name === autoSignInProviderName) {
                authProvider.SignIn();
            }
        });
    }

    render() {
        return <div ref={this.rootNode} />;
    }

    private onExtensionError = (error: OctopusError | Error) => {
        if (error instanceof OctopusError) {
            this.props.onError(new AuthenticationError(error.ErrorMessage, error.Errors, error.ParsedHelpLinks));
        } else {
            this.props.onError(new AuthenticationError(error.message));
        }
    };
}

type AuthProviderProps = Omit<AuthProviderInternalProps, "isDarkMode">;

function AuthProvider(props: AuthProviderProps) {
    const themePalette = useThemePaletteType();
    return <AuthProviderInternal {...props} isDarkMode={themePalette === "dark"} />;
}

function loadCSS(urls: string[], contextualLogger: Logger): Promise<void>[] {
    return urls.map((url) => {
        contextualLogger.info("Loading CSS {url}", { url });

        return new Promise<void>((resolve, reject) => {
            const linkElement = document.createElement("link");
            linkElement.setAttribute("rel", "stylesheet");
            linkElement.setAttribute("type", "text/css");
            linkElement.setAttribute("href", client.resolve(url));
            document.getElementsByTagName("head")[0].appendChild(linkElement);
            resolve();
        });
    });
}

function loadJS(link: string, contextualLogger: Logger): string {
    contextualLogger.info("Loading JS {link}", { link });
    return client.resolve(link);
}

export default AuthProvider;
