/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import type { RunOn, RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import type { DynamicWorkerPoolResource, WorkerPoolResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import type { FeedResource } from "~/client/resources/feedResource";
import { FeedType } from "~/client/resources/feedResource";
import { client } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { getDefaultFeedByKey, noFeedsWarning, possibleFeeds } from "~/components/PackageSelector/PackageSelector";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Note, RadioButton, RadioButtonGroup, Text } from "~/components/form";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import callAll from "~/utils/callAll";
import { WorkerPoolType } from "../../client/resources/workerPoolsSupportedTypesResouce";
import type { WorkerToolsLatestImages } from "../../client/resources/workerToolsLatestImages";
import routeLinks from "../../routeLinks";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";
import type { NameOrIdKey } from "../KeyAccessProvider/types";
import isBound from "../form/BoundField/isBound";

export enum StepExecutionOption { // Note that running on Octopus Server means we run in the inbuilt worker
    RunDirectlyOnWorker = "RunDirectlyOnWorker",
    RunInsideContainerOnWorker = "RunInsideContainerOnWorker",
}
class StepExecutionOptionRadioButtonGroup extends RadioButtonGroup<StepExecutionOption> {}

const BoundDebounceText = withBoundField(DebounceValue(Text));

interface ExecutionContainerImageSelectorProps {
    workerPools: WorkerPoolResource[];
    runOn: RunOnServerOrWorkerPool;
    feeds: FeedResource[];
    autoFocus?: boolean;
    feedError?: string;
    feedSelectLabel?: string;
    imageNameError?: string;
    workerPoolNameOrId: string | undefined;
    refreshFeeds(): Promise<void>;
    resetContainer(runOn: RunOnServerOrWorkerPool): void;
    onImageNameChange(value: string): void;
    onFeedChange(nameOrId: string | undefined): void;
    onStepExecutionOptionChange?(option: StepExecutionOption): void;
    doBusyTask: DoBusyTask;
}

const ExecutionContainerImageSelector: React.FC<ExecutionContainerImageSelectorProps> = (props) => {
    const keyedBy = useKeyedItemAccess();
    const {
        feeds: providedFeeds,
        autoFocus = false,
        feedError,
        feedSelectLabel = "Container Registry",
        refreshFeeds,
        onFeedChange,
        onImageNameChange,
        onStepExecutionOptionChange,
        resetContainer,
        runOn,
        imageNameError,
        workerPoolNameOrId,
        workerPools,
    } = props;
    const feedType = [FeedType.Docker, FeedType.AwsElasticContainerRegistry];
    const feeds = possibleFeeds(providedFeeds, feedType);

    //TODO: SM - this will eventually become container.Feed, but for now we are keeping this.
    const defaultFeedKey = runOn.container.FeedId ?? getDefaultFeedByKey(feeds, keyedBy);
    const defaultImageName = runOn.container.Image ?? "";

    const [feedKey, setFeedKey] = React.useState(defaultFeedKey);
    const [imageName, setImageName] = React.useState(defaultImageName);

    const [stepExecutionOption, setStepExecutionOption] = React.useState<StepExecutionOption>(runOn.runningInContainer ? StepExecutionOption.RunInsideContainerOnWorker : StepExecutionOption.RunDirectlyOnWorker);

    const [latestWindowsImage, setLatestWindowsImage] = React.useState("");
    const [latestLinuxImage, setLatestLinuxImage] = React.useState("");

    const isWindows2016CloudWorkerPool = React.useMemo(() => {
        return isWindows2016CloudWorker(workerPoolNameOrId, workerPools, keyedBy);
    }, [workerPoolNameOrId, workerPools, keyedBy]);

    const runInsideContainer = stepExecutionOption === StepExecutionOption.RunInsideContainerOnWorker;

    const noFeeds = feeds == null || feeds.length === 0;

    useDoBusyTaskEffect(
        props.doBusyTask,
        async () => {
            if (runInsideContainer) {
                await findLatestWorkerToolsImageTag(setLatestWindowsImage, setLatestLinuxImage);
            }
        },
        [runInsideContainer]
    );

    if (!runOn.container.FeedId && defaultFeedKey && runInsideContainer) {
        onFeedChange(defaultFeedKey);
    }

    if (!runOn.container.Image && imageName && runInsideContainer) {
        onImageNameChange(imageName);
    }

    const runningInContainer = Boolean(isRunningInContainer(runOn) || (feedKey && imageName));

    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const feedEditPermissionGranted = isAllowed({ permission: Permission.FeedEdit, wildcard: true });

    const runningOnBuiltInWorker = isRunOnBuiltInWorker(runOn);

    return (
        <>
            {actionContainersEarlyAccessCallout()}

            <StepExecutionOptionRadioButtonGroup
                value={stepExecutionOption}
                onChange={(option) => {
                    setStepExecutionOption(option);
                    if (onStepExecutionOptionChange) {
                        onStepExecutionOptionChange(option);
                    }
                }}
            >
                <RadioButton value={StepExecutionOption.RunDirectlyOnWorker} label={`Runs directly on ${runningOnBuiltInWorker ? "Octopus Server" : "a worker"}`} isDefault={true} />
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need the required dependencies pre-installed.</Note>

                <RadioButton value={StepExecutionOption.RunInsideContainerOnWorker} label={`Runs inside a container, on ${runningOnBuiltInWorker ? "the Octopus Server" : "a worker"}`} />
                <Note>{runningOnBuiltInWorker ? "Octopus Server" : "The worker"} will need Docker installed</Note>

                {!feedViewPermissionGranted ? feedViewPermissionMissingCallout(runningInContainer) : ""}

                {runInsideContainer && isWindows2016CloudWorkerPool ? dockerNotInstalledOnWindows2016WorkersWarning() : ""}

                {runInsideContainer && noFeeds && feedViewPermissionGranted ? (
                    noFeedsWarning(feedType, refreshFeeds)
                ) : runInsideContainer && feedViewPermissionGranted ? (
                    <div>
                        <SelectWithAddRefresh
                            value={feedKey}
                            onChange={callAll(setFeedKey, onFeedChange)}
                            items={feeds.map((f) => ({ value: f[keyedBy], text: f.Name }))}
                            error={feedError}
                            autoFocus={autoFocus}
                            label={feedSelectLabel}
                            addUrl={`#${routeLinks.library.feeds}`}
                            onRequestRefresh={refreshFeeds}
                            {...(!feedEditPermissionGranted
                                ? {
                                      disableAdd: true,
                                      addButtonTooltipText: "FeedEdit permissions required to add or edit a new feed",
                                  }
                                : {})}
                        />
                        <BoundDebounceText
                            variableLookup={{
                                localNames: null!,
                            }}
                            debounceDelay={500}
                            placeholder="Enter the image, including the tag"
                            value={imageName}
                            onChange={callAll(setImageName, onImageNameChange)}
                            error={imageNameError}
                        />
                        {actionContainersHelp(latestWindowsImage, latestLinuxImage, setImageName, onImageNameChange)}
                    </div>
                ) : (
                    ""
                )}
            </StepExecutionOptionRadioButtonGroup>
        </>
    );
};

const feedViewPermissionMissingCallout = (isRunningInContainerCheck: boolean) => {
    return (
        <Callout type={CalloutType.Warning} title={`FeedView Permission required`}>
            <div>You will need FeedView permission to {isRunningInContainerCheck ? "edit selected container and/or feed" : "enable running inside a container"}</div>
            <ExternalLink href="spaces-and-permissions">System and Space Permissions</ExternalLink>{" "}
        </Callout>
    );
};

const actionContainersEarlyAccessCallout = () => {
    return (
        <Note>
            If you have any feedback on execution containers, <ExternalLink href="ActionContainersFeedbackForm">please let us know</ExternalLink>.
        </Note>
    );
};

const actionContainersHelp = (latestWindowsImage: string, latestLinuxImage: string, setImageName: (image: string) => void, onImageNameChange: (value: string) => void) => {
    return (
        <div>
            <Note>The image should include the tag.</Note>

            <Note>
                Learn more about <ExternalLink href="ExecutionContainersWhichImage">which image you can use</ExternalLink>.
            </Note>

            <Note>
                The <ExternalLink href="WorkerToolsDockerHub">octopusdeploy/worker-tools</ExternalLink> images contain many tools commonly used in deployments.
                {!!latestWindowsImage && (
                    <ul>
                        <li>
                            <a href="#" onClick={(e) => setImageOnClick(e, latestWindowsImage, setImageName, onImageNameChange)}>
                                Use latest Windows-based image
                            </a>
                            : <code>{latestWindowsImage}</code>
                        </li>
                        <li>
                            <a href="#" onClick={(e) => setImageOnClick(e, latestLinuxImage, setImageName, onImageNameChange)}>
                                Use latest Linux-based image
                            </a>
                            : <code>{latestLinuxImage}</code>
                        </li>
                    </ul>
                )}
            </Note>
        </div>
    );
};

const dockerNotInstalledOnWindows2016WorkersWarning = () => {
    return (
        <Callout title="Docker not available" type={CalloutType.Warning}>
            Docker is not installed on Windows 2016 hosted workers. Attempting to use execution containers on this worker pool will fail. Please select a different <ExternalLink href="DynamicWorkerPools">worker pool</ExternalLink>.
        </Callout>
    );
};

const findLatestWorkerToolsImageTag = async (setLatestWindowsImage: (image: string) => void, setLatestLinuxImage: (image: string) => void) => {
    const latestImages = (await client.get(client.getLink("WorkerToolsLatestImages"))) as WorkerToolsLatestImages;
    const latestWindows2019Image = latestImages["windows.ltsc2019"];
    const latestUbuntu1804Image = latestImages["ubuntu.18.04"];
    if (!!latestWindows2019Image) {
        setLatestWindowsImage(latestWindows2019Image);
    }
    if (!!latestUbuntu1804Image) {
        setLatestLinuxImage(latestUbuntu1804Image);
    }
};

function isDynamicWorkerPool(resource: WorkerPoolResource): resource is DynamicWorkerPoolResource {
    return resource.WorkerPoolType === WorkerPoolType.Dynamic;
}

const isWindows2016CloudWorker = (workerPoolNameOrId: string | undefined, workerPools: WorkerPoolResource[], keyedBy: NameOrIdKey) => {
    if (!workerPoolNameOrId || isBound(workerPoolNameOrId)) return false;
    const workerPool = workerPools.find((workerPool) => workerPool[keyedBy] === workerPoolNameOrId);

    return !!workerPool && isDynamicWorkerPool(workerPool) && workerPool.WorkerType === "Windows2016";
};

export const isRunningInContainer = (runOn: RunOn): boolean => {
    if (!isRunOnServerOrWorkerPool(runOn)) {
        return false;
    }

    if (!runOn.runningInContainer) {
        return false;
    }

    if (runOn.container.FeedId === null || runOn.container.Image === null) {
        return false;
    }

    return true;
};

const setImageOnClick = (e: React.MouseEvent<Element, MouseEvent>, image: string, setImage: (image: string) => void, onImageNameChange: (value: string) => void) => {
    e.preventDefault();
    setImage(image);
    onImageNameChange(image);
};

export default ExecutionContainerImageSelector;
