import type { ProjectResource, GitBranchResource } from "~/client/resources";
import type { GitRef } from "~/client/resources/versionControlledResource";
import type { Client } from "../client";

export class BranchesRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }

    createBranch(project: ProjectResource, newBranchName: String, baseGitRef: GitRef): Promise<GitBranchResource> {
        return this.client.post(`/api/${project.SpaceId}/projects/${project.Id}/git/branches/v2`, { NewBranchName: newBranchName, BaseGitRef: baseGitRef });
    }
}

export default BranchesRepository;
