export default (root: string) => {
    const specificRoot = `${root}/insights`;
    return {
        root: specificRoot,
        reports: `${specificRoot}/reports`,
        report: (reportId: string) => ({
            root: `${specificRoot}/reports/${reportId}`,
            overview: `${specificRoot}/reports/${reportId}/overview`,
            frequency: `${specificRoot}/reports/${reportId}/frequency`,
            leadTime: `${specificRoot}/reports/${reportId}/lead-time`,
            failureRate: `${specificRoot}/reports/${reportId}/failure-rate`,
            timeToRecovery: `${specificRoot}/reports/${reportId}/mttr`,
            settings: `${specificRoot}/reports/${reportId}/settings`,
        }),
    };
};
