import type React from "react";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";

const TaskQueueWithToggle = ({ render }: React.PropsWithChildren<{ render(useNewDesign: boolean): React.ReactElement }>) => {
    const useNewDesign = useEnabledFeatureToggle("NewTaskSummaryPageFeatureToggle");

    return render(useNewDesign);
};

export default TaskQueueWithToggle;
