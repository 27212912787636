/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import moment from "moment";
import * as React from "react";
import type {
    TriggerScheduleResource,
    EnvironmentResource,
    ChannelResource,
    ScopedDeploymentActionResource,
    DaysPerMonthTriggerScheduleResource,
    CronTriggerScheduleResource,
    DeployLatestReleaseActionResource,
    DeployNewReleaseActionResource,
    TriggerScheduleIntervalResource,
    RunRunbookActionResource,
    RunbookResource,
    OnceDailyTriggerScheduleResource,
    ContinuousDailyTriggerScheduleResource,
} from "~/client/resources";
import { TriggerActionType, TriggerScheduleIntervalType, TriggerFilterType } from "~/client/resources";
import { environmentChipListIncludingMissing, channelChipList, ChipIcon, RunbookChip, ChannelChip } from "~/components/Chips";
import LookupResourceChipComponent from "~/components/LookupResourceChip/LookupResourceChip";
import { getCronExpressionDescription } from "~/utils/CronExpressions/getCronExpressionDescription";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import DaysDescriptionHelper from "./DaysDescriptionHelper";
import DaysPerMonthDescriptionHelper from "./DaysPerMonthDescriptionHelper";
import ScheduleIntervalDescriptionHelper from "./ScheduleIntervalDescriptionHelper";

export default class ScheduledTriggerDescriptionHelper {
    public static getScheduleDescription(schedule: TriggerScheduleResource, emptyText: string = "") {
        try {
            switch (schedule.FilterType) {
                case TriggerFilterType.OnceDailySchedule:
                    return this.getDailyScheduleDescription(schedule as OnceDailyTriggerScheduleResource);
                case TriggerFilterType.ContinuousDailySchedule:
                    return this.getDailyScheduleDescription(schedule as ContinuousDailyTriggerScheduleResource);
                case TriggerFilterType.DaysPerMonthSchedule:
                    return this.getDaysPerMonthScheduleDescription(schedule as DaysPerMonthTriggerScheduleResource, emptyText);
                case TriggerFilterType.CronExpressionSchedule:
                    return this.getCronExpressionScheduleDescription(schedule as CronTriggerScheduleResource, emptyText);
            }
        } catch (e) {
            return e;
        }
    }

    public static getActionDescription(action: ScopedDeploymentActionResource, environments: EnvironmentResource[], runbooks: RunbookResource[], channels: ChannelResource[], emptyText: string = "") {
        let preText = null;
        let channelDescription = null;
        let postText = null;
        let sourceEnvironmentIds: string[] = [];
        let destinationEnvironmentId = null;

        let to = "to";
        switch (action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                preText = <strong>Deploy the latest release</strong>;
                sourceEnvironmentIds = (action as DeployLatestReleaseActionResource).SourceEnvironmentIds || [];
                destinationEnvironmentId = [(action as DeployLatestReleaseActionResource).DestinationEnvironmentId];
                break;
            case TriggerActionType.DeployNewRelease:
                preText = <strong>Create a new release</strong>;
                postText = <strong> and deploy </strong>;
                destinationEnvironmentId = [(action as DeployNewReleaseActionResource).EnvironmentId];
                break;
            case TriggerActionType.RunRunbook:
                preText = <strong>Run Runbook {this.lookupRunbookChip((action as unknown as RunRunbookActionResource).RunbookId, runbooks)}</strong>;
                destinationEnvironmentId = (action as unknown as RunRunbookActionResource).EnvironmentIds;
                to = "in";
                break;
        }

        if (action.ChannelId) {
            channelDescription = channels.find((c) => c.Id === action.ChannelId) ? <span> in {channelChipList(channels, [action.ChannelId])}</span> : <span> in {<ChannelChip channelName={action.ChannelId} />}</span>;
        }

        return (
            <span>
                {preText}
                {channelDescription}
                {postText}
                {sourceEnvironmentIds.length > 0 && <span> from {environmentChipListIncludingMissing(environments, sourceEnvironmentIds)}</span>}
                <span>
                    {" "}
                    {to} {environmentChipListIncludingMissing(environments, destinationEnvironmentId!)}
                </span>
            </span>
        );
    }

    public static lookupRunbookChip = (id: string, runbooks: RunbookResource[]) => {
        const LookupRunbookChip = LookupResourceChipComponent<RunbookResource>();

        return <LookupRunbookChip lookupCollection={runbooks} key={id} lookupId={id} type={ChipIcon.Runbook} chipRender={(item) => <RunbookChip runbook={item} />} />;
    };

    public static getScheduleIntervalDescription(interval: TriggerScheduleIntervalResource, emptyText: string = "") {
        const scheduleIntervalDescription = new ScheduleIntervalDescriptionHelper(interval);
        return scheduleIntervalDescription.getSummary(emptyText);
    }

    private static getDailyScheduleDescription(value: OnceDailyTriggerScheduleResource | ContinuousDailyTriggerScheduleResource) {
        const onceDailySchedule = value.FilterType === TriggerFilterType.OnceDailySchedule ? (value as OnceDailyTriggerScheduleResource) : null;
        const continuousDailySchedule = value.FilterType === TriggerFilterType.ContinuousDailySchedule ? (value as ContinuousDailyTriggerScheduleResource) : null;

        const daysPerWeekDescription = new DaysDescriptionHelper(value).getSummary();

        return (
            <span>
                {onceDailySchedule && this.getStartTimeDescription(onceDailySchedule.StartTime)}
                {continuousDailySchedule && this.getIntervalDescription(continuousDailySchedule.Interval, continuousDailySchedule.HourInterval, continuousDailySchedule.MinuteInterval)}
                {continuousDailySchedule && this.getRunAfterRunUntilDescription(continuousDailySchedule.RunAfter, continuousDailySchedule.RunUntil)}
                {daysPerWeekDescription}
            </span>
        );
    }

    private static getDaysPerMonthScheduleDescription(schedule: DaysPerMonthTriggerScheduleResource, emptyText: string) {
        const daysPerMonthDescription = new DaysPerMonthDescriptionHelper(schedule).getSummary(emptyText);
        return (
            <span>
                {daysPerMonthDescription}
                {this.getStartTimeDescription(schedule.StartTime)}
            </span>
        );
    }

    private static getCronExpressionScheduleDescription(schedule: CronTriggerScheduleResource, emptyText: string) {
        let cronMeaning = getCronExpressionDescription(schedule.CronExpression);
        cronMeaning = cronMeaning.charAt(0).toLowerCase() + cronMeaning.slice(1);
        return <strong>{cronMeaning}</strong>;
    }

    private static getIntervalDescription(intervalType: TriggerScheduleIntervalType, hourInterval?: number, minuteInterval?: number) {
        let intervalDescription = null;
        switch (intervalType) {
            case TriggerScheduleIntervalType.OnceDaily:
                intervalDescription = <strong> once daily</strong>;
                break;
            case TriggerScheduleIntervalType.OnceHourly:
                intervalDescription = (
                    <span>
                        {" "}
                        <strong>
                            every {hourInterval} {hourInterval! > 1 ? "hours" : "hour"} daily
                        </strong>
                    </span>
                );
                break;
            case TriggerScheduleIntervalType.OnceEveryMinute:
                intervalDescription = (
                    <span>
                        {" "}
                        <strong>
                            every {minuteInterval} {minuteInterval! > 1 ? "minutes" : "minute"} daily
                        </strong>
                    </span>
                );
                break;
        }
        return intervalDescription;
    }

    private static getStartTimeDescription(startTime?: Date) {
        return (
            <span>
                <span> starting at </span>
                <strong>{this.getFormattedTime(startTime!)}</strong>
            </span>
        );
    }

    private static getRunAfterRunUntilDescription(runAfter?: Date, runUntil?: Date) {
        return (
            <>
                <span>
                    <span> running after </span>
                    <strong>{this.getFormattedTime(runAfter!)}</strong>
                </span>
                <span>
                    <span> running until </span>
                    <strong>{this.getFormattedTime(runUntil!)}</strong>
                </span>
            </>
        );
    }

    private static getFormattedTime(time: Date) {
        return DateFormatter.dateToCustomFormat(moment(time).utc(), "hh:mm A");
    }
}
