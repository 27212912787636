/* eslint-disable custom-portal-rules/no-restricted-imports */

import { makeStyles, Tabs, Tab } from "@material-ui/core";
import React from "react";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";

const useToolbarStyles = makeStyles({
    root: {
        display: "flex",
    },
    actions: {
        display: "flex",
        justifySelf: "left",
        justifyItems: "left",
        alignItems: "flex-start",
        "& > *": {
            display: "flex",
            marginRight: "1rem !important",
            marginTop: "1rem !important",
        },
    },
    tabs: {
        flex: 1,
    },
    icon: {
        fontSize: "0.5rem",
    },
});

interface DevToolbarProps {
    selectedTab: string;
    onSelectTab: (value: string) => void;
    tabs: Array<{ label: string; value: string }>;
    onRequestClose: () => void;
    onToggleFullscreen: () => void;
    isFullScreen: boolean;
}

export const DevToolbar: React.FC<DevToolbarProps> = (props) => {
    const styles = useToolbarStyles();
    return (
        <div className={styles.root}>
            <div className={styles.tabs}>
                <Tabs value={props.selectedTab} onChange={(e, val) => props.onSelectTab(val)}>
                    {props.tabs.map((x) => (
                        <Tab key={x.value} value={x.value} label={x.label} />
                    ))}
                </Tabs>
            </div>
            <div className={styles.actions}>
                <IconButton onClick={props.onToggleFullscreen} icon={props.isFullScreen ? Icon.Expand : Icon.Collapse} className={styles.icon} />
                <IconButton onClick={props.onRequestClose} icon={Icon.Cancel} className={styles.icon} />
            </div>
        </div>
    );
};

export default DevToolbar;
