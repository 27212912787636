import * as React from "react";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { getGitRefType, GitRefType, toGitRefShort } from "~/client/resources/versionControlledResource";
import { GitBranchIcon, GitCommitIcon, GitTagIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
import styles from "./style.module.less";

interface GitRefDisplayProps {
    currentHead?: GitRef;
    isDefaultBranch?: boolean;
}

export function GitRefDisplay(props: GitRefDisplayProps) {
    const gitRefName = props.currentHead ? toGitRefShort(props.currentHead) : "(none)";
    const gitRefType: GitRefType | undefined = getGitRefType(props.currentHead);
    return (
        <div className={styles.currentBranch}>
            <span>Current {GetGitRefTypeDisplayName(gitRefType)}:</span>
            <span className={styles.iconContainer}>{GetGitIcon(gitRefType)}</span>
            <span className={styles.currentBranchValue}>
                {gitRefName} {props.isDefaultBranch ? " (default)" : ""}
            </span>
        </div>
    );
}

function GetGitIcon(gitRefType: GitRefType | undefined): JSX.Element {
    if (gitRefType === GitRefType.Commit) {
        return <GitCommitIcon fontSize={"inherit"} />;
    } else if (gitRefType === GitRefType.Tag) {
        return <GitTagIcon fontSize={"inherit"} />;
    }

    return <GitBranchIcon fontSize={"inherit"} />;
}

function GetGitRefTypeDisplayName(gitRefType: GitRefType | undefined): string {
    if (gitRefType === GitRefType.Commit) {
        return "commit";
    } else if (gitRefType === GitRefType.Tag) {
        return "tag";
    }

    return "branch";
}
