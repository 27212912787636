import * as React from "react";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout";
import { ProjectContextFormPaperLayout } from "../Process/CustomPaperLayouts/ProjectContextFormPaperLayout";

export const DeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps & { isPageVersionControlled?: boolean }> = ({ children, ...props }) => {
    return (
        <ProjectContextFormPaperLayout title="Deployment Settings" saveText="Deployment settings updated" saveButtonLabel="Save" saveButtonBusyLabel="Saving" {...props}>
            {children}
        </ProjectContextFormPaperLayout>
    );
};

export const VersionControlledDeploymentSettingsFormPaperLayout: React.FC<FormPaperLayoutProps & { onCreateBranch?: (newBranchName: string) => Promise<void> }> = ({ children, ...props }) => {
    return (
        <DeploymentSettingsFormPaperLayout {...props} saveButtonLabel="Commit" saveButtonBusyLabel="Committing" customPrimaryAction={props.customPrimaryAction} isPageVersionControlled={true}>
            {children}
        </DeploymentSettingsFormPaperLayout>
    );
};
