import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter, Route } from "react-router-dom";
import { HasVariablesInGit } from "~/client/resources";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/Page";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import ProjectVariables from "./ProjectVariables";

export const ProjectVariablesPage = withPage({ page: pageIds.project().variables.root })(ProjectVariables);

type ProjectVariablesRouteProps = {
    path: string;
};

type Props = ProjectVariablesRouteProps & RouteComponentProps<{ projectSlug: string }>;

class ProjectVariablesRoute extends React.Component<Props> {
    render() {
        return (
            <BranchAwareRedirect condition={(project) => HasVariablesInGit(project.PersistenceSettings)}>
                <ErrorContextProvider>
                    <Switch>
                        <Route path={`${this.props.path}`} exact={true} render={(props) => <ProjectVariablesPage {...props} />} />
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>
        );
    }
}

const EnhancedProjectVariablesRoute = withRouter(ProjectVariablesRoute);

export default EnhancedProjectVariablesRoute;
