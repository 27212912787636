export const projectInsightsLinks = (root: string) => {
    return {
        root,
        overview: `${root}/overview`,
        leadTime: `${root}/lead-time`,
        frequency: `${root}/frequency`,
        failureRate: `${root}/failure-rate`,
        timeToRecovery: `${root}/mttr`,
    };
};
