/* eslint-disable custom-portal-rules/no-restricted-imports */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import cn from "classnames";
import IconButton from "material-ui/IconButton";
import ClearFix from "material-ui/internal/ClearFix";
import { transitions } from "material-ui/styles";
import * as PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import type { AnalyticSimpleDispatcher } from "~/analytics/Analytics";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import Permission from "~/client/resources/permission";
import { isGitBranch, isGitCommit, isGitTag, toGitBranch, toGitBranchShort, toGitRefShort } from "~/client/resources/versionControlledResource";
import type { GitRef } from "~/client/resources/versionControlledResource";
import BusyIndicator from "~/components/BusyIndicator";
import ActionButton, { ActionButtonType } from "~/components/Button";
import FilterSearchBox from "~/components/FilterSearchBox";
import { PermissionCheck } from "~/components/PermissionCheck";
import { Section } from "~/components/Section/Section";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import { GitBranchIcon, GitCommitIcon, GitTagIcon, ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { MenuList } from "~/primitiveComponents/navigation/MenuList/MenuList";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import { noOp } from "~/utils/noOp";
import styles from "./style.module.less";
const keycode = require("keycode");

function getStyles(props: GitRefDropDownProps, context: any, theme: OctopusTheme) {
    const spacing = context.muiTheme.baseTheme.spacing;
    const palette = context.muiTheme.baseTheme.palette;
    const { disabled } = props;

    const color = disabled ? theme.disabledButtonText : theme.iconNeutral;

    return {
        iconColor: disabled ? theme.disabledButtonText : theme.iconNeutral,
        control: {
            cursor: disabled ? "not-allowed" : "pointer",
            height: "100%",
            position: "relative" as const,
            width: "100%",
        },
        icon: {
            width: `1.5rem`,
            height: `1.5rem`,
            padding: 0,
            right: 0,
            top: 0,
            marginTop: 0,
            fill: theme.secondaryText,
        },
        gitIcon: {
            fill: color,
        },
        label: {
            display: "flex" as const,
            justifyContent: "flex-start",
            alignItems: "center" as const,
            color: color,
            overflow: "hidden" as const,
            opacity: 1,
            position: "relative" as const,
            paddingRight: spacing.desktopGutter,
            textOverflow: "ellipsis" as const,
            paddingLeft: spacing.desktopGutterMini,
            width: "100%",
        },
        labelWhenOpen: {
            opacity: 0,
            top: spacing.desktopToolbarHeight / 8,
        },
        root: {
            display: "inline-block",
            fontSize: spacing.desktopDropDownMenuFontSize,
            fontFamily: context.muiTheme.baseTheme.fontFamily,
            outline: "none",
            position: "relative",
            transition: transitions.easeOut(),
        },
        rootWhenOpen: {
            opacity: 1,
        },
        buttons: {
            position: "absolute" as const,
            right: 0,
            top: "0.2rem",
        },
        dropDownMenu: {
            display: "block",
            border: `1px solid ${theme}`,
        },
        filter: {
            margin: "0 1rem",
            display: "flex",
            flexDirection: "row" as any,
            alignItems: "center",
        },
        empty: {
            margin: "1rem",
        },
    };
}

interface GitRefDropDownAnalyticsProps {
    dispatchAction: AnalyticSimpleDispatcher;
}

interface GitRefDropDownProps {
    onChange: (gitRef: GitRefOption) => void;
    items: GitRefOption[] | undefined;
    totalItems: number;
    value?: GitRef;
    empty?: string;
    style?: "grey" | "white";
    disabled?: boolean;
    projectId: string;
    onFilterChanged(value: string): Promise<GitRefOption[]>;
    onRequestRefresh(): Promise<void>;
    onCreateBranch: (newBranchName: string) => Promise<void>;
    isBusySearching?: boolean; // UX: Localised refresh indicator pattern.
    onRefTypeChanged: (refType: RefTypes) => void;
    refType: RefTypes;
    mode?: GitRefDropDownMode;
    allowBranchCreation?: boolean;
    disableBranchCreation?: boolean;
    errorMessage?: string;
    branchProtectionsAreEnabled: boolean;
}

type GitRefDropDownPropsInternal = GitRefDropDownProps & GitRefDropDownAnalyticsProps;

interface GitRefDropDownState {
    open: boolean;
    anchorElement: any;
    filter: string | undefined;
    filteredItems: GitRefOption[] | null;
    isBusyRefreshing: boolean;
    errorMessage?: string;
    refType: RefTypes;
    disableBranchCreation?: boolean;
}

export enum RefTypes {
    Branches = "Branches",
    Tags = "Tags",
    Commits = "Commits",
}

export enum GitRefDropDownMode {
    All = "All",
    BranchesOnly = "BranchesOnly",
}

const GitIcon = (props: SvgIconProps & { forRef: GitRef }) => {
    const { forRef, ...rest } = props;
    const Icon = isGitCommit(forRef) ? GitCommitIcon : isGitTag(forRef) ? GitTagIcon : GitBranchIcon;
    return <Icon {...rest} />;
};

function LockIcon() {
    return <em className={cn("fa fa-lock", styles.lockIcon)} />;
}

class GitRefDropDown extends React.Component<GitRefDropDownPropsInternal, GitRefDropDownState> {
    static muiName = "DropDownMenu";
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };
    private searchRaceConditioner = new RequestRaceConditioner();

    constructor(props: GitRefDropDownPropsInternal) {
        super(props);
        this.state = {
            refType: props.refType,
            open: false,
            anchorElement: null,
            filter: undefined,
            filteredItems: null,
            isBusyRefreshing: false,
            errorMessage: props.errorMessage,
            disableBranchCreation: props.disableBranchCreation,
        };
    }

    private firstMenuNode = React.createRef<HTMLButtonElement>();
    private popoverContentRef: HTMLElement | null = null;
    rootNode = undefined as any;
    arrowNode = undefined as any;

    componentDidMount = () => this.setWidth();

    componentDidUpdate = () => this.setWidth();

    static getDerivedStateFromProps(props: GitRefDropDownPropsInternal, state: GitRefDropDownState) {
        return {
            ...state,
            disableBranchCreation: props.disableBranchCreation,
        };
    }

    handleTouchTapControl = (event: React.MouseEvent) => {
        event.preventDefault();
        this.setState({
            open: !this.state.open,
            anchorElement: this.rootNode,
            errorMessage: "",
        });
    };

    handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        switch (keycode(event)) {
            case "up":
            case "down":
            case "space":
            case "enter":
                event.preventDefault();
                this.setState({
                    open: true,
                    anchorElement: this.rootNode,
                    errorMessage: "",
                });
                break;
        }
    };

    handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keycode(event)) {
            case "esc":
                this.close();
                break;
            case "down":
                if (this.firstMenuNode.current) {
                    this.firstMenuNode.current.focus();
                }
                break;
        }
    };

    onGitRefSelected(item: GitRefOption) {
        this.props.dispatchAction("Switch Branch");
        this.setState(
            {
                open: false,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(item);
                }

                this.close();
            }
        );
    }

    close = () => {
        this.setState(
            {
                open: false,
                //filter: undefined, //UX: review - we should refactor this Popover component so all filtering can be unmounted when we close.
                //filteredItems: null,
            },
            () => {
                // We shouldn't need to do this because the Popover already returns focus to the previously focused element
                // However, we currently need to do this because we aren't correctly using focusable button elements as our anchor elements
                // If we fix that up, we can remove this manual focusing code
                // See this comment: https://github.com/OctopusDeploy/OctopusDeploy/blob/02aa8e35966221f67ef38c4dc5ea4401060d5474/newportal/app/areas/projects/components/GitRefDropDown/GitRefDropDown.tsx#L329-L330
                // Note that adding a tabIndex={0} is not sufficient because this means there are two focusable elements instead of 1 (the icon button is also a button)
                const dropArrow = this.arrowNode;
                // eslint-disable-next-line react/no-find-dom-node
                const dropNode = ReactDOM.findDOMNode(dropArrow) as HTMLElement;
                dropNode.focus();
                dropArrow.setKeyboardFocus(true);
            }
        );
    };

    private handleFilterChanged = async (value: string) => {
        // Reset any filteredItems we're currently viewing _before_ the search begins, otherwise you end up staring at stale items while a search is happening / feels wrong.
        this.setState({ filter: value, filteredItems: [] });
        this.props.dispatchAction("Search for Branch");
        await this.searchRaceConditioner.avoidStaleResponsesForRequest(this.props.onFilterChanged(value), (filteredItems) => {
            this.setState({ filteredItems });
        });
    };

    private onRequestRefresh = async () => {
        this.props.dispatchAction(`Fetch ${this.props.refType}`);
        await this.doBusyTaskForRefreshing(this.props.onRequestRefresh);
    };

    private renderFetch() {
        const getName = () => {
            switch (this.props.refType) {
                case RefTypes.Branches:
                    return "BRANCH";
                case RefTypes.Tags:
                    return "TAG";
                case RefTypes.Commits:
                    return "COMMIT";
            }
        };
        return (
            <div>
                <div className={styles.fetchButtonContainer}>
                    <ActionButton
                        type={ActionButtonType.Secondary}
                        disabled={this.state.isBusyRefreshing}
                        label={`REFRESH ${getName()} LIST`}
                        title="Refresh the list from your configured remote repository"
                        onClick={this.onRequestRefresh}
                        className={styles.fetchButton}
                    />
                </div>
                {this.state.isBusyRefreshing && <BusyIndicator show={true} />}
            </div>
        );
    }

    private renderDropDown = (theme: OctopusTheme) => {
        const anchorOrigin: Origin = {
            vertical: "bottom",
            horizontal: "left",
        };

        const { items, value, disabled } = this.props;
        const selectedItem = items?.find((i) => i.value === value);

        const { anchorElement, open, errorMessage } = this.state;

        const { prepareStyles } = this.context.muiTheme;
        const inlineStyles = getStyles(this.props, this.context, theme);

        const activeItems = this.state.filteredItems ?? items;

        const allowBranchCreation = this.props.allowBranchCreation ?? true;
        const mode = this.props.mode ?? GitRefDropDownMode.All;

        const style = this.props.style ?? "grey";
        const className = styles["dropDownMenu-" + style];

        const onCreateBranchClick = async (e: { preventDefault: () => void }) => {
            e.preventDefault();
            try {
                const filter = this.state.filter ?? "";
                await this.doBusyTaskForRefreshing(async () => await this.props.onCreateBranch(filter));
                this.close();
                this.setState({ filter: "", errorMessage: "" });
                await this.doBusyTaskForRefreshing(async () => await this.handleFilterChanged(""));
            } catch (error) {
                this.setState({ errorMessage: error.ErrorMessage });
            }
        };

        const renderCount = (type: string) => {
            if (!activeItems || this.props.isBusySearching || activeItems.length >= this.props.totalItems || activeItems.length == 0) {
                return null;
            }

            return (
                <div className={styles.warning}>
                    Displaying {activeItems.length} out of {this.props.totalItems} {type}.
                </div>
            );
        };

        const renderBranchCreationLink = () => {
            if (!this.state.filter) {
                return null;
            }
            const newBranchName = toGitBranch(this.state.filter);
            if (this.props.isBusySearching || !value) {
                return null;
            }
            if (!activeItems || !!activeItems.find((a) => a.value === newBranchName)) {
                return null;
            }

            const linkText = (
                <>
                    Create branch <strong>{toGitBranchShort(newBranchName)}</strong> from <strong>{toGitRefShort(value)}</strong>
                </>
            );

            const createBranchLink = (
                <PermissionCheck permission={Permission.ProjectEdit} project={this.props.projectId} alternate={<p className={styles.info}>Project Edit permission is required to create branches</p>}>
                    <a href="#" onClick={onCreateBranchClick}>
                        {linkText}
                    </a>
                </PermissionCheck>
            );

            const disabledCreateBranchLink = (
                <>
                    <div className={styles.createBranchLinkDisabled}>{linkText}</div>
                    <Note>Commit or discard unsaved changes before creating a new branch.</Note>
                </>
            );

            return <div style={inlineStyles.empty}>{this.state.disableBranchCreation ? disabledCreateBranchLink : createBranchLink}</div>;
        };

        const renderItemList = () => {
            if (!activeItems || activeItems.length == 0) {
                return null;
            }

            return (
                <div className={styles.menuListScrollContainer}>
                    <MenuList accessibleName={"Git ref"}>
                        {activeItems.map((item, index) => {
                            const isSelected = value === item.value;
                            const refProps = index === 0 ? { ref: this.firstMenuNode } : {};
                            return (
                                <MenuItemButton key={item.value} isSelected={isSelected} onClick={() => this.onGitRefSelected(item)} compact={true} {...refProps}>
                                    <span className={styles.menuItem}>
                                        <span>{toGitRefShort(item.text)}</span>
                                        {this.props.branchProtectionsAreEnabled && isGitBranch(item.value) && !item.canWrite && <LockIcon />}
                                    </span>
                                </MenuItemButton>
                            );
                        })}
                    </MenuList>
                </div>
            );
        };

        const renderGitBranchList = (value: GitRef, allowBranchCreation: boolean) => {
            const searchPlaceholderText = allowBranchCreation ? "Search or create a branch..." : "Search branches...";

            return (
                <>
                    <div onKeyDown={this.handleFilterKeyDown} style={inlineStyles.filter}>
                        <FilterSearchBox placeholder={searchPlaceholderText} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} fullWidth={true} error={this.state.errorMessage ?? ""} />
                    </div>
                    {this.props.isBusySearching && (
                        <Section>
                            <BusyIndicator show={true} inline={true} />
                        </Section>
                    )}
                    {renderItemList()}
                    {allowBranchCreation && renderBranchCreationLink()}
                    {renderCount("branches")}
                    {this.renderFetch()}
                </>
            );
        };

        const styleClasses = this.props.errorMessage ? cn(styles.dropDownMenu, className, styles.error) : cn(styles.dropDownMenu, className);
        const gitIconClasses = this.props.errorMessage ? cn(styles.gitIcon, styles.error) : styles.gitIcon;
        const valueClasses = this.props.errorMessage ? cn(styles.value, styles.error) : styles.value;

        return (
            <div
                ref={(node) => {
                    this.rootNode = node;
                }}
                className={styleClasses}
                style={prepareStyles(Object.assign({}, inlineStyles.root, open && inlineStyles.rootWhenOpen))}
            >
                {/*This should ideally be a button html element (semantic html is much better for accessibility)
                This breaks the styling enough that I didn't want to commit to this change right now in the short time that I have.*/}
                <div role="button" onClick={disabled ? noOp : this.handleTouchTapControl} aria-label="Switch branch">
                    <ClearFix style={inlineStyles.control}>
                        <div style={inlineStyles.label} className={styles.label}>
                            {value && <GitIcon forRef={value} className={gitIconClasses} style={inlineStyles.gitIcon} />}
                            {value && <span className={valueClasses}>{this.props.errorMessage ?? toGitRefShort(value)}</span>}
                            {this.props.branchProtectionsAreEnabled && selectedItem && isGitBranch(selectedItem.value) && !selectedItem.canWrite && <LockIcon />}
                        </div>
                        <div style={inlineStyles.buttons} className={styles.buttonDropDown}>
                            <IconButton
                                disabled={disabled}
                                onKeyDown={disabled ? noOp : this.handleKeyDown}
                                ref={(node) => {
                                    this.arrowNode = node;
                                }}
                                style={Object.assign({}, inlineStyles.icon)}
                                {...{ "aria-label": "ToggleDropDown" }}
                            >
                                <ThirdPartyIcon iconType={ThirdPartyIconType.ArrowDropDown} color={inlineStyles.iconColor} />
                            </IconButton>
                        </div>
                    </ClearFix>
                </div>
                <Popover anchorOrigin={anchorOrigin} anchorEl={anchorElement} open={open} onClose={this.close} className={styles.popoverContainer}>
                    <div style={{ width: "500px" }} ref={(ref) => (this.popoverContentRef = ref)}>
                        {mode === GitRefDropDownMode.BranchesOnly && value && renderGitBranchList(value, allowBranchCreation)}
                        {mode === GitRefDropDownMode.All && (
                            <ControlledTabsContainer value={this.props.refType} onChange={this.changeTab} tabContainerStyle={{ borderBottom: "2px solid #eeeeee" }} variant={"fullWidth"}>
                                <TabItem label={this.renderTabHead(GitBranchIcon, "Branches")} value={"Branches"}>
                                    {value && renderGitBranchList(value, allowBranchCreation)}
                                </TabItem>
                                <TabItem label={this.renderTabHead(GitTagIcon, "Tags")} value={"Tags"}>
                                    <div onKeyDown={this.handleFilterKeyDown} style={inlineStyles.filter}>
                                        <FilterSearchBox placeholder={"Search Tags..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} fullWidth={true} error={this.state.errorMessage ?? ""} />
                                    </div>
                                    {this.props.isBusySearching && (
                                        <Section>
                                            <BusyIndicator show={true} inline={true} />
                                        </Section>
                                    )}
                                    {renderItemList()}
                                    {activeItems && activeItems.length === 0 && !this.state.isBusyRefreshing && !this.props.isBusySearching && <div className={styles.noResultsContainer}>No Tags To Show</div>}
                                    {renderCount("tags")}
                                    {this.renderFetch()}
                                </TabItem>
                                <TabItem label={this.renderTabHead(GitCommitIcon, "Commits")} value={"Commits"}>
                                    <div onKeyDown={this.handleFilterKeyDown} style={inlineStyles.filter}>
                                        <Text
                                            placeholder={"Commit hash"}
                                            value={this.state.filter || ""}
                                            onChange={(a) => {
                                                this.setState({ filter: a });
                                            }}
                                            error={this.state.errorMessage}
                                        />
                                    </div>
                                    <div style={{ padding: "1rem" }}>Enter a commit hash or short hash of 7 or more characters</div>

                                    <div>
                                        <div className={styles.fetchButtonContainer}>
                                            <ActionButton
                                                type={ActionButtonType.Secondary}
                                                disabled={this.state.isBusyRefreshing}
                                                label={`USE HASH`}
                                                title="Refresh the branch list from your configured remote repository"
                                                onClick={this.useHash}
                                                className={styles.fetchButton}
                                            />
                                        </div>
                                        {this.state.isBusyRefreshing && <BusyIndicator show={true} />}
                                    </div>
                                </TabItem>
                            </ControlledTabsContainer>
                        )}
                    </div>
                </Popover>
            </div>
        );
    };

    private useHash = () => {
        if (!this.state.filter) {
            return;
        }
        if (!isGitCommit(this.state.filter)) {
            this.setState({ errorMessage: "Does not look like a Git Commit. Expecting 7-40 alphanumeric characters." });
            return;
        }
        this.onGitRefSelected({ text: this.state.filter, value: this.state.filter, canWrite: false });
    };

    private renderTabHead = (Icon: React.JSXElementConstructor<any>, label: string) => {
        return (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <Icon style={{ height: "1rem", paddingRight: "0.5rem" }} />
                <span>{label}</span>
            </div>
        );
    };

    private changeTab = (selectedType: string) => {
        const refType = selectedType as RefTypes;
        this.setState({ filter: "", filteredItems: null, errorMessage: undefined, refType }, () => {
            this.props.onRefTypeChanged(refType);
        });
    };

    // If the popover size expands due to a long branch name, we lock in the the minimum width to the new size of the popover
    // This way, if the branch disappears temporarily (for example, while loading new branches or filtering to a subset of branches)
    // The size of the popover doesn't shrink (and then expand again later), but instead remains constant
    // By setting minWidth, we also allow the popover to expand further if an even longer branch name shows up
    private setWidth = () =>
        // Not sure why we need this requestAnimationFrame.
        // We probably shouldn't need it, but for some reason the popover doesn't seem to be rendered yet during `componentDidUpdate` (nor during a useLayoutEffect)
        // I'm not going to spend time trying to understand and solve this problem right now, so sticking with a requestAnimationFrame for now
        requestAnimationFrame(() => {
            if (this.popoverContentRef) {
                this.popoverContentRef.style.minWidth = `${this.popoverContentRef.clientWidth}px`;
            }
        });

    private doBusyTaskForRefreshing = async (action: () => Promise<void>): Promise<void> => {
        this.setState({ isBusyRefreshing: true });
        try {
            await action();
        } finally {
            this.setState({ isBusyRefreshing: false });
        }
    };

    render() {
        return withTheme((theme) => {
            return this.renderDropDown(theme);
        });
    }
}

function GitRefDropDownWithAnalytics(props: GitRefDropDownProps) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    return <GitRefDropDown {...props} dispatchAction={dispatchAction} />;
}

export default GitRefDropDownWithAnalytics;
