import type { InsightsUnitOfTime, ParsedInsightsDurationMetrics } from "app/areas/insights/dataTransformation/parseInsightsDurationMetrics";
import { getRecommendedUnitOfTimeKey, parseInsightsDurationMetrics } from "app/areas/insights/dataTransformation/parseInsightsDurationMetrics";
import type { MultiSeriesData } from "~/areas/insights/components/Charts/LineChart";
import type { InsightsDataSeriesResource, InsightsDataSeriesResourceInterval, InsightsFailureRateMetric } from "~/client/resources/insightsMetrics";

type OmittedMetrics = "LeadTime" | "MeanTimeToRecovery" | "TimeSincePreviousDeploymentAggregate";

interface ExtendedDeploymentFailureRate extends InsightsFailureRateMetric {
    ComputedFailureRate: number;
}

export interface ParsedInsightsAllMetricsInterval extends Omit<InsightsDataSeriesResourceInterval, OmittedMetrics> {
    LeadTime: ParsedInsightsDurationMetrics;
    MeanTimeToRecovery: ParsedInsightsDurationMetrics;
    TimeSincePreviousDeploymentAggregate: ParsedInsightsDurationMetrics;
    DeploymentFailureRate: ExtendedDeploymentFailureRate;
}

interface ComputedUnitOfTimes {
    LeadTimeKey: InsightsUnitOfTime;
    MeanTimeToRecoveryKey: InsightsUnitOfTime;
    TimeSincePreviousDeploymentKey: InsightsUnitOfTime;
}

export function getYAxisUnitOfTimeKeys(data: MultiSeriesData<ParsedInsightsAllMetricsInterval>[]): ComputedUnitOfTimes {
    const leadTime: ParsedInsightsDurationMetrics[] = [];
    const timeToRecovery: ParsedInsightsDurationMetrics[] = [];
    const timeSincePreviousDeployment: ParsedInsightsDurationMetrics[] = [];

    data.forEach((s) => {
        s.data.forEach((d) => {
            leadTime.push(d.LeadTime);
            timeToRecovery.push(d.MeanTimeToRecovery);
            timeSincePreviousDeployment.push(d.TimeSincePreviousDeploymentAggregate);
        });
    });

    return {
        LeadTimeKey: getRecommendedUnitOfTimeKey(leadTime),
        MeanTimeToRecoveryKey: getRecommendedUnitOfTimeKey(timeToRecovery),
        TimeSincePreviousDeploymentKey: getRecommendedUnitOfTimeKey(timeSincePreviousDeployment),
    };
}

function parseAllMetricsInterval(interval: InsightsDataSeriesResourceInterval) {
    return {
        ...interval,
        LeadTime: parseInsightsDurationMetrics(interval.LeadTime),
        MeanTimeToRecovery: parseInsightsDurationMetrics(interval.MeanTimeToRecovery),
        TimeSincePreviousDeploymentAggregate: parseInsightsDurationMetrics(interval.TimeSincePreviousDeployment),
        DeploymentFailureRate: {
            ...interval.DeploymentFailureRate,
            ComputedFailureRate: interval.DeploymentFailureRate.Total > 0 ? Number(((interval.DeploymentFailureRate.Failed / interval.DeploymentFailureRate.Total) * 100).toFixed(1)) : 0,
        },
    };
}

export function getChartReadyInsightsData(series: InsightsDataSeriesResource[]): MultiSeriesData<ParsedInsightsAllMetricsInterval>[] {
    return series.map((d, idx) => ({
        name: d.Name ?? "[Hidden name]", // Chart labels show dataKey if name is null
        id: d.Name ?? `Series ${idx}`,
        data: d.Intervals.map((i) => parseAllMetricsInterval(i)),
    }));
}
