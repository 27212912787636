import React, { useReducer } from "react";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import { useRequiredContext } from "~/hooks";
import { useBoundDispatch } from "~/utils/Reducers";
import type { StoredWarnings, ProcessWarning, ActionWarning, StepWarning, WarningValuePair, Warnings } from "../../types";
import type { ProcessStateSelectors } from "../ProcessContextState";

export const actions = {
    setWarnings: createAction(
        "SET:WARNINGS",
        (resolve) => (warnings: Warnings, selectors: ProcessStateSelectors) =>
            resolve({
                warnings: parseAllWarnings(warnings, selectors),
                message: parseMessage(warnings),
            })
    ),
    clearWarnings: createAction(
        "CLEAR:WARNINGS",
        (resolve) => () =>
            resolve({
                warnings: [],
                message: "",
            })
    ),
};

type ActionCreators = typeof actions;
export type WarningActions = ActionType<ActionCreators>;

const getStepRegex = () => /Steps\[(\d+)\].([a-zA-Z.]*)/;
const getActionRegex = () => /Steps\[(\d+)\]\.Actions\[(\d+)\].([a-zA-Z0-9[\].]*)/;

export const INITIAL_WARNINGS_STATE: StoredWarnings = {
    steps: {},
    actions: {},
    global: {},
    globalMessage: "",
};

const parseActionWarningDetails = (warning: WarningValuePair, selectors: ProcessStateSelectors): ActionWarning | undefined => {
    const actionRegex = getActionRegex();
    const actionResult = actionRegex.exec(warning.key);
    if (!actionResult || actionResult.length !== 4) {
        return;
    }

    //The key is unique for a given action i.e. Step[1].Action[2].Name which means we will have a `Name` entry against the action, keyed by the actual action ID.
    //This results in something like { actions: { actionId: { Name: warning }}}
    const key = actionResult[3].toLocaleLowerCase();
    const stepIndex = Number(actionResult[1]);
    const actionIndex = Number(actionResult[2]);
    const step = selectors.getStepByIndex(stepIndex);
    const actionId = step.ActionIds[actionIndex];

    return {
        key,
        stepId: step.Id,
        actionId,
        value: warning.value,
    };
};

const parseStepWarningDetail = (warning: WarningValuePair, selectors: ProcessStateSelectors): StepWarning | undefined => {
    const stepRegex = getStepRegex();
    const stepResult = stepRegex.exec(warning.key);
    if (!stepResult || stepResult.length !== 3) {
        return;
    }

    //The key is unique per step i.e. Step[1].Name which means we will have a `Name` entry against the step, keyed by the actual step ID.
    //This results in something like { steps: { stepId: { Name: warning }}}
    const key = stepResult[2].toLocaleLowerCase();
    const stepIndex = Number(stepResult[1]);
    const step = selectors.getStepByIndex(stepIndex);

    return {
        key,
        stepId: step.Id,
        value: warning.value,
    };
};

function isActionWarning(warning: ProcessWarning): warning is ActionWarning {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = warning as ActionWarning;
    return Object.prototype.hasOwnProperty.call(casted, "actionId") && !!casted.actionId;
}

function isStepWarning(warning: ProcessWarning): warning is StepWarning {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = warning as StepWarning;
    return Object.prototype.hasOwnProperty.call(casted, "stepId") && !!casted.stepId && !isActionWarning(warning);
}

const parseWarningDetails = (warning: WarningValuePair, selectors: ProcessStateSelectors): ProcessWarning => {
    // This parser starts with the most specific warning details (step.actions), falls through to steps, then global warnings.

    const actionWarningDetails = parseActionWarningDetails(warning, selectors);
    if (actionWarningDetails) {
        return actionWarningDetails;
    }

    const stepWarningDetails = parseStepWarningDetail(warning, selectors);
    if (stepWarningDetails) {
        return stepWarningDetails;
    }

    return warning;
};

const parseAllWarnings = (warnings: Warnings, selectors: ProcessStateSelectors) => {
    const warningDetails = warnings.details ?? {};
    return Object.keys(warningDetails).map((e) => parseWarningDetails({ key: e, value: warningDetails[e] }, selectors));
};

const parseMessage = (warnings: Warnings) => {
    const message = warnings.message;
    return message;
};

const reduceWarnings = (warnings: ProcessWarning[], message: string) => {
    return warnings.reduce<StoredWarnings>((prev, current) => {
        if (isActionWarning(current)) {
            return {
                ...prev,
                actions: {
                    ...prev.actions,
                    [current.actionId]: {
                        ...prev.actions[current.actionId],
                        [current.key]: current.value,
                    },
                },
                globalMessage: message,
            };
        } else if (isStepWarning(current)) {
            return {
                ...prev,
                steps: {
                    ...prev.steps,
                    [current.stepId]: {
                        ...prev.steps[current.stepId],
                        [current.key]: current.value,
                    },
                },
                globalMessage: message,
            };
        } else {
            return {
                ...prev,
                global: {
                    ...prev.global,
                    [current.key]: current.value,
                },
                globalMessage: message,
            };
        }
    }, INITIAL_WARNINGS_STATE);
};

export const processWarningsReducer: React.Reducer<StoredWarnings, WarningActions> = (state, action) => {
    switch (action.type) {
        case getType(actions.setWarnings): {
            if (action.payload.warnings.length === 0) {
                return state;
            }
            return {
                ...state,
                ...reduceWarnings(action.payload.warnings, action.payload.message),
            };
        }
        case getType(actions.clearWarnings): {
            return INITIAL_WARNINGS_STATE;
        }
    }
    return state;
};

const getActionWarningLookup = (state: StoredWarnings) => {
    return (actionId: string, processSelectors: ProcessStateSelectors) => {
        const actionWarnings = state.actions[actionId];
        const action = processSelectors.getActionById(actionId);
        const step = processSelectors.getStepById(action.ParentId);

        const hasSingleChild = step.ActionIds.length === 1;

        return {
            ...(hasSingleChild ? state.steps[step.Id] ?? {} : {}),
            ...(actionWarnings ?? {}),
        };
    };
};

const getActionWarnings = (state: StoredWarnings) => {
    return (actionId: string, processSelectors: ProcessStateSelectors): string[] => {
        return Object.values(getActionWarningLookup(state)(actionId, processSelectors));
    };
};

const getStepWarnings = (state: StoredWarnings) => {
    return (stepId: string): string[] => {
        return Object.values(state.steps[stepId] ?? {});
    };
};

const getStepFieldWarnings = (state: StoredWarnings) => {
    return (stepId: string) => {
        return { ...state.steps[stepId] };
    };
};

const getGlobalWarnings = (state: StoredWarnings) => {
    return (): string[] => {
        return Object.values(state.global);
    };
};

const getGlobalWarningMessage = (state: StoredWarnings) => {
    return (): string => {
        return state.globalMessage;
    };
};

const getActionFieldWarning = (state: StoredWarnings) => {
    return (actionId: string, processSelectors: ProcessStateSelectors, field: string) => {
        const warnings = getActionWarningLookup(state)(actionId, processSelectors);
        return warnings[field.toLowerCase()];
    };
};

const getActionFieldWarnings = (state: StoredWarnings) => {
    return (actionId: string, processSelectors: ProcessStateSelectors) => {
        const warnings = getActionWarningLookup(state)(actionId, processSelectors);
        return { ...warnings };
    };
};

const getStepFieldWarning = (state: StoredWarnings) => {
    return (stepId: string, field: string) => {
        const warnings = state.steps[stepId] ?? {};
        return warnings[field.toLowerCase()];
    };
};

interface ProcessWarningSelectors {
    getActionWarnings: ReturnType<typeof getActionWarnings>;
    getStepWarnings: ReturnType<typeof getStepWarnings>;
    getGlobalWarnings: ReturnType<typeof getGlobalWarnings>;
    getGlobalWarningMessage: ReturnType<typeof getGlobalWarningMessage>;
    getActionFieldWarning: ReturnType<typeof getActionFieldWarning>;
    getStepFieldWarning: ReturnType<typeof getStepFieldWarning>;
    getActionFieldWarnings: ReturnType<typeof getActionFieldWarnings>;
    getStepFieldWarnings: ReturnType<typeof getStepFieldWarnings>;
}

export const getSelectors = (state: StoredWarnings): ProcessWarningSelectors => {
    return {
        getActionWarnings: getActionWarnings(state),
        getStepWarnings: getStepWarnings(state),
        getGlobalWarnings: getGlobalWarnings(state),
        getGlobalWarningMessage: getGlobalWarningMessage(state),
        getActionFieldWarning: getActionFieldWarning(state),
        getStepFieldWarning: getStepFieldWarning(state),
        getActionFieldWarnings: getActionFieldWarnings(state),
        getStepFieldWarnings: getStepFieldWarnings(state),
    };
};

const useBoundProcessActions = (dispatch: React.Dispatch<WarningActions>) => {
    return useBoundDispatch(dispatch, actions);
};

export type BoundWarningActionsType = ReturnType<typeof useBoundProcessActions>;

interface ProcessWarningStateContextProps {
    selectors: ProcessWarningSelectors;
}

interface ProcessWarningActionContextProps {
    actions: BoundWarningActionsType;
}

const ProcessWarningSelectorContext = React.createContext<ProcessWarningStateContextProps | undefined>(undefined);
const ProcessWarningActionContext = React.createContext<ProcessWarningActionContextProps | undefined>(undefined);

export const useProcessWarningSelectors = () => {
    const selectorsContext = useRequiredContext(ProcessWarningSelectorContext, "Process Warnings State");
    return selectorsContext.selectors;
};

export const useProcessWarningActions = () => {
    const actionsContext = useRequiredContext(ProcessWarningActionContext, "Process Warning Actions");
    return actionsContext.actions;
};

const useProcessWarningReducer = () => {
    return useReducer(processWarningsReducer, INITIAL_WARNINGS_STATE);
};

export interface ProcessWarningSelectorsProps {
    children: (selectors: ProcessWarningSelectors) => React.ReactElement | null;
}

export const ProcessWarningSelectors: React.FC<ProcessWarningSelectorsProps> = ({ children }) => {
    const selectors = useProcessWarningSelectors();
    return children(selectors);
};

export interface ProcessWarningActionsProps {
    children: (actions: BoundWarningActionsType) => React.ReactElement | null;
}

export const ProcessWarningActions: React.FC<ProcessWarningActionsProps> = ({ children }) => {
    const warningActions = useProcessWarningActions();
    return children(warningActions);
};

export const ProcessWarningsController: React.FC = React.memo((props) => {
    const [state, dispatch] = useProcessWarningReducer();
    const boundActions = useBoundDispatch(dispatch, actions);
    const selectors = React.useMemo(() => getSelectors(state), [state]);

    return (
        <ProcessWarningSelectorContext.Provider value={{ selectors }}>
            <ProcessWarningActionContext.Provider value={{ actions: boundActions }}>{props.children}</ProcessWarningActionContext.Provider>
        </ProcessWarningSelectorContext.Provider>
    );
});

export interface WithProcessWarningActionsContextInjectedProps {
    processWarningActions: BoundWarningActionsType;
}

export const withProcessWarningActionsContext = <T,>(Component: React.ComponentType<T & WithProcessWarningActionsContextInjectedProps>) => {
    const WithProcessWarningActionsContext: React.FC<T> = (props) => {
        const context = useProcessWarningActions();
        return <Component processWarningActions={context} {...props} />;
    };
    return WithProcessWarningActionsContext;
};

export interface WithProcessWarningSelectorContextInjectedProps {
    processWarningSelectors: ProcessWarningSelectors;
}

export const withProcessWarningSelectorsContext = <T,>(Component: React.ComponentType<T & WithProcessWarningSelectorContextInjectedProps>) => {
    const WithProcessWarningSelectorsContext: React.FC<T> = (props) => {
        const context = useProcessWarningSelectors();
        return <Component processWarningSelectors={context} {...props} />;
    };

    return WithProcessWarningSelectorsContext;
};
