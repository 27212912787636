import type { PropsWithChildren } from "react";
import React, { useState } from "react";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { OnboardingQuestionnaireDialogLayout } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/OnboardingQuestionnaireDialog";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CreateEnvironmentsPage } from "~/components/ProjectBasedActivation/CreateEnvironmentsDialog";
import questionnaireImage from "~/components/ProjectBasedActivation/assets/img-questionnaire.svg";
import { DialogWithHelpPanelFrame } from "~/components/ProjectBasedActivation/components/DialogWithHelpPanel";

export const NewlyCreatedProjectWizardDialog: React.FC<{ open: boolean; close: () => void; showOnboardingQuestionnaire: boolean; showCreateEnvironments: boolean }> = ({ open, close, showOnboardingQuestionnaire, showCreateEnvironments }) => {
    const [page, setPage] = useState<number>(0);
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();

    const nextPage = () => {
        const hasNextPage = page + 1 < pages.length;
        if (hasNextPage) {
            setPage(page + 1);
        } else {
            close();
        }
    };

    const pages: JSX.Element[] = [];
    if (showCreateEnvironments) pages.push(<CreateEnvironmentsPage close={nextPage} trackAction={trackAction} dispatchAction={dispatchAction} />);
    if (showOnboardingQuestionnaire) pages.push(<OnboardingQuestionnairePage close={nextPage} />);
    if (!pages.length) return null;

    return <CustomDialog open={open} close={close} render={() => pages[page]} />;
};

const OnboardingQuestionnairePage = ({ close }: PropsWithChildren<{ close: () => void }>) => {
    const helpPanelContent = <span>We're looking to learn more about the projects our customers are creating to help us guide the direction of Octopus. Thanks for your time, we really appreciate it.</span>;
    return (
        <DialogWithHelpPanelFrame helpPanelImage={questionnaireImage} helpPanelContent={helpPanelContent}>
            <OnboardingQuestionnaireDialogLayout close={close} />
        </DialogWithHelpPanelFrame>
    );
};
