import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import type { StepUI } from "@octopusdeploy/step-ui";
import type { ExecutionLocation, CloudConnectionType } from "~/client/resources/stepPackage";

export interface StepPackage<StepInputs> {
    name: string;
    description: string;
    version: string;
    releaseNotesUrl?: string;
    executionLocation: ExecutionLocation;
    requiresTargetRole: boolean;
    stepUI: StepUI<StepInputs>;
    inputSchema: InputJsonSchema;
    isLegacyStep: boolean;
    targetDiscoveryCloudConnectionTypes: Array<CloudConnectionType>;
}

export type UnknownStepPackage = StepPackage<unknown>;

export function asUnknownStepPackage<StepInputs>(stepPackage: StepPackage<StepInputs>): UnknownStepPackage {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return stepPackage as unknown as UnknownStepPackage;
}
