import React from "react";
import { FormSectionHeading } from "~/components/form";
import Environment from "~/environment";
import { logger } from "~/utils/logging/logger";
import { useKeyCombo } from "./useKeyCombo";

export interface ExperimentalFeaturesGroupProps {
    children?: React.ReactNode;
}

const experimentalFeaturesKonamiCode = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight"];

export function ExperimentalFeaturesGroup(props: ExperimentalFeaturesGroupProps) {
    const { isKonamiCodeTriggered } = useKeyCombo(experimentalFeaturesKonamiCode);

    React.useEffect(() => {
        if (isKonamiCodeTriggered) {
            logger.info("Features unlocked");
        }
    }, [isKonamiCodeTriggered]);

    if (!isKonamiCodeTriggered && !Environment.isInDevelopmentMode()) return null;

    return (
        <React.Fragment>
            <FormSectionHeading key="ExperimentalFeaturesGroup" title={"Experimental"} />
            {props.children}
        </React.Fragment>
    );
}
