import * as React from "react";
import type { RouteComponentProps } from "react-router";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { logger } from "~/utils/logging/logger";

type TenantTasksProps = RouteComponentProps<{ tenantId: string }>;

const TenantTasks: React.FC<TenantTasksProps> = (props) => {
    const tenantId = props.match.params.tenantId;
    if (!tenantId) {
        logger.warn("Failed to find tenantId from the route. This should not happen.");
        return null;
    }
    return <Tasks {...props} restrictToTenantId={tenantId} hideScriptConsoleAction={true} />;
};

export default TenantTasks;
