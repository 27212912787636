/* eslint-disable @typescript-eslint/no-non-null-assertion */

import cn from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { AnalyticLinkProvider } from "~/analytics/AnalyticLink";
import { Permission } from "~/client/resources/permission";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { drawerBreakpointWidth } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { isDrawerOpen } from "~/components/ContextualHelpLayout/reducers";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { GettingStartedDialog } from "~/components/GettingStarted/GettingStartedDialog";
import type { GettingStartedStatus } from "~/components/GettingStarted/gettingStartedLoader";
import { gettingStartedLoader } from "~/components/GettingStarted/gettingStartedLoader";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
import { OnboardingSuccess } from "~/components/Images/OnboardingSuccess/OnboardingSuccess";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SuccessfulDeploymentDialog from "~/components/ProjectBasedActivation/SuccessfulDeploymentDialog";
import Environment from "~/environment";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import routeLinks from "../../routeLinks";
import { resolveStringPathWithSpaceId } from "../Navigation/resolvePathWithSpaceId";
import { GettingStartedDetails } from "./GettingStartedDetails";
import styles from "./GettingStartedFooter.module.less";

interface ProgressSection {
    index: number;
    percent: number;
    enabled: boolean;
}

interface GettingStartedFooterState {
    showBar: boolean;
    expand: boolean;
    canDismiss: boolean;
    progressSections: ProgressSection[];
    showDetails: boolean;
    status: GettingStartedStatus | null;
    highlightSection: string | null;
    spacer: number;
    width: number;
    showDialog: boolean;
}

interface GlobalConnectedProps {
    showFooterOnDashboard?: boolean;
    isDrawerOpen?: boolean;
    isHelpSidebarEnabled?: boolean;
}

type RouteParams = { spaceId: string };
type GettingStartedFooterProps = { spaceId: string; isDashboard: boolean } & GlobalConnectedProps;

const octopusShowGettingStartedFooterLocalStorageKey = "OctopusShowGettingStartedFooterLocalStorageKey";
const gettingStartedProgressBarExpandedKey = "gettingStartedProgressBarExpanded";

class GettingStartedFooterInternal extends BaseComponent<GettingStartedFooterProps, GettingStartedFooterState> {
    unsubscribe: (() => void) | null = undefined!;
    divEl: HTMLElement = undefined!;

    private isDismissedInLocalStorage = Environment.isInDevelopmentMode() && localStorage.getItem(octopusShowGettingStartedFooterLocalStorageKey) && localStorage.getItem(octopusShowGettingStartedFooterLocalStorageKey) === "false";

    constructor(props: GettingStartedFooterProps) {
        super(props);
        this.state = {
            showBar: false,
            expand: true,
            canDismiss: false,
            progressSections: [],
            showDetails: false,
            status: null,
            highlightSection: null,
            spacer: 0,
            width: 0,
            showDialog: false,
        };
    }

    async componentDidMount() {
        const hasEnvironmentCreatePermission = isAllowed({ permission: Permission.EnvironmentCreate });
        const hasEitherMachineCreateOrAccountCreatePermission = isAllowed({ permission: Permission.MachineCreate }) || isAllowed({ permission: Permission.AccountCreate });
        const hasEitherBuiltInFeedPushOrFeedEditPermission = isAllowed({ permission: Permission.BuiltInFeedPush }) || isAllowed({ permission: Permission.FeedEdit });
        const hasProjectCreatePermission = isAllowed({ permission: Permission.ProjectCreate });
        const hasProjectEditPermission = isAllowed({ permission: Permission.ProjectEdit });
        const hasReleaseCreatePermission = isAllowed({ permission: Permission.ReleaseCreate });
        const hasDeploymentCreatePermission = isAllowed({ permission: Permission.DeploymentCreate });

        const hasAllRequiredPermissions =
            hasEnvironmentCreatePermission &&
            hasEitherMachineCreateOrAccountCreatePermission &&
            hasEitherBuiltInFeedPushOrFeedEditPermission &&
            hasProjectCreatePermission &&
            hasProjectEditPermission &&
            hasReleaseCreatePermission &&
            hasDeploymentCreatePermission;

        if (!hasAllRequiredPermissions) {
            this.setState({
                showBar: false,
            });
            return;
        }

        const tasksStatus = await gettingStartedLoader.loadStatus();

        if (!this.unmounted) {
            if (tasksStatus.tasks.Deploy!.DeployedRelease.IsComplete) {
                this.setState({
                    showBar: false,
                });
                return;
            }

            this.setState({
                showBar: true,
                expand: this.isBarExpanded(),
            });

            if (this.divEl) {
                this.setState({
                    spacer: this.divEl.getBoundingClientRect().height,
                });
            }

            this.unsubscribe = this.isDismissedInLocalStorage ? null : gettingStartedLoader.subscribe((status) => this.refresh(status));
            this.refresh(tasksStatus);
        }

        this.calculateWidth();
        window.addEventListener("resize", this.calculateWidth);
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        window.removeEventListener("resize", this.calculateWidth);
    }

    componentDidUpdate() {
        if (!this.divEl) {
            return;
        }

        const newHeight = this.divEl.getBoundingClientRect().height;
        if (this.state.spacer === newHeight) {
            return;
        }

        this.setState({
            spacer: newHeight,
        });
    }

    calculateWidth = () => {
        const width = window.innerWidth;
        this.setState({ width });
    };

    render() {
        if (this.isDismissedInLocalStorage) {
            return null;
        }

        if ((this.props.isDashboard && !this.props.showFooterOnDashboard) || !this.state.showBar || !this.state.progressSections) {
            return null;
        }

        const isDrawerMediaWidthOrAbove = this.state.width >= drawerBreakpointWidth;
        const drawOffsetStyle = isDrawerMediaWidthOrAbove && this.props.isDrawerOpen && this.props.isHelpSidebarEnabled ? styles.drawerOffset : null;
        const footerActionStyles = isDrawerMediaWidthOrAbove && this.props.isDrawerOpen && this.props.isHelpSidebarEnabled ? cn(styles.gettingStartedFooterActions, styles.drawerOffsetForFooter) : styles.gettingStartedFooterActions;

        const oldOnboardingComponent = (
            <div>
                {this.state.showDetails && (
                    <AnalyticLinkProvider location="Onboarding dialog">
                        <GettingStartedDialog onNavigating={this.handleNavigating} highlightSection={this.state.highlightSection} open={this.state.showDetails} onClose={() => this.setState({ showDetails: false })} />
                    </AnalyticLinkProvider>
                )}
                <div ref={this.setDivRef} className={cn(styles.gettingStartedFooter, drawOffsetStyle)}>
                    {Environment.isInDevelopmentMode() && <IconButton onClick={() => this.dismiss()} icon={Icon.Cancel} className="pull-right" />}
                    <AnalyticLinkProvider location="Onboarding footer">
                        {this.state.canDismiss || this.state.expand ? (
                            <div className={footerActionStyles}>
                                <ToolTip content={"Hide"}>
                                    <a className={cn(styles.gettingStartedButton, styles.lightPulse)} onClick={() => this.toggle()}>
                                        <em className="fa fa-minus" aria-hidden="true" />
                                    </a>
                                </ToolTip>
                            </div>
                        ) : (
                            <div className={styles.gettingStartedMinimize}>
                                <div className={footerActionStyles}>
                                    <ToolTip content={"Show my progress"}>
                                        <a className={cn(styles.gettingStartedButton, styles.intensePulse)} onClick={() => this.toggle()}>
                                            <em className="fa-solid fa-list-ol" aria-hidden="true" />
                                        </a>
                                    </ToolTip>
                                </div>
                            </div>
                        )}
                        {this.state.canDismiss && (
                            <div className={styles.gettingStartedMinimize}>
                                <div className={footerActionStyles}>
                                    <ToolTip content={"Close"}>
                                        <a className={cn(styles.gettingStartedButton, styles.intensePulse)} onClick={() => this.dismiss()}>
                                            <em className="fa-solid fa-xmark" aria-hidden="true" />
                                        </a>
                                    </ToolTip>
                                </div>
                            </div>
                        )}

                        {this.state.canDismiss && (
                            <div className={styles.firstSuccessfulDeploymentContainer}>
                                <div className={styles.firstSuccessfulDeployment}>
                                    <div className={styles.successContent}>
                                        <h3>
                                            <strong>Congratulations, you've successfully completed your first deployment!</strong>
                                        </h3>
                                        <p>
                                            <ExternalLink href="OnboardingFeedbackForm">Complete our short feedback form</ExternalLink> on your deployment experience <i>(under 1 minute)</i> and we'll mail you a pack of laptop stickers as a thank you.
                                        </p>
                                        <p>Happy Deployments!</p>
                                    </div>
                                </div>
                                <div className={styles.successImage}>
                                    <OnboardingSuccess />
                                </div>
                            </div>
                        )}
                        {!this.state.canDismiss && this.state.expand && (
                            <div className={styles.gettingStartedContent}>
                                <GettingStartedDetails onlyShowCurrentSection={true} showGettingStartedDialog={() => this.showGettingStartedDialog()} linkClassName={styles.gettingStartedLinkWrapper} />
                            </div>
                        )}
                    </AnalyticLinkProvider>
                </div>
                <div style={{ paddingTop: `${this.state.spacer}px` }} />
            </div>
        );

        return (
            <FeatureToggleVisibility toggle={"ProjectBasedActivationFeatureToggle"} disabledContent={oldOnboardingComponent}>
                <SuccessfulDeploymentDialog open={this.state.showDialog} close={() => this.setState({ showDialog: false })} />
            </FeatureToggleVisibility>
        );
    }

    private showGettingStartedDialog() {
        this.setState({ showDetails: true });
    }

    private setDivRef = (el: HTMLDivElement) => {
        this.divEl = el;
    };

    private dismiss() {
        if (Environment.isInDevelopmentMode()) {
            localStorage.setItem(octopusShowGettingStartedFooterLocalStorageKey, "false");
        }
        this.setState({ showBar: false });
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    }

    private toggle() {
        localStorage.setItem(gettingStartedProgressBarExpandedKey, (!this.state.expand).toString());
        this.setState({
            expand: !this.state.expand,
        });
    }

    private isBarExpanded() {
        if (localStorage.getItem(gettingStartedProgressBarExpandedKey) !== null) {
            return localStorage.getItem(gettingStartedProgressBarExpandedKey) === "true" ? true : false;
        }
        return true;
    }

    private refresh(status: GettingStartedStatus) {
        const sections: ProgressSection[] = [
            {
                index: 1,
                percent: status.tasks.Infrastructure!.Percent,
                enabled: status.tasks.Infrastructure!.IsEnabled,
            },
            {
                index: 2,
                percent: status.tasks.Package!.Percent,
                enabled: status.tasks.Package!.IsEnabled,
            },
            {
                index: 3,
                percent: status.tasks.Project!.Percent,
                enabled: status.tasks.Project!.IsEnabled,
            },
            {
                index: 4,
                percent: status.tasks.Deploy!.Percent,
                enabled: status.tasks.Deploy!.IsEnabled,
            },
        ];

        if (status.tasks.Deploy!.DeployedRelease.IsComplete) {
            this.setState({
                canDismiss: true,
                showDialog: true,
            });
        }

        this.setState({
            status,
            progressSections: sections,
        });
    }

    private handleNavigating = () => {
        this.setState({ showDetails: false });
    };
}

export function GettingStartedFooter() {
    const isDashboard = useIsDashboard();
    const { spaceId } = useParams<RouteParams>();
    const { showFooterOnDashboard, isDrawerOpen, isHelpSidebarEnabled } = useGettingStartedState();

    return <GettingStartedFooterInternal spaceId={spaceId} isDashboard={isDashboard} showFooterOnDashboard={showFooterOnDashboard} isDrawerOpen={isDrawerOpen} isHelpSidebarEnabled={isHelpSidebarEnabled} />;
}

function useIsDashboard() {
    const location = useLocation();
    const { spaceId } = useParams<RouteParams>();

    return resolveStringPathWithSpaceId(routeLinks.root, spaceId) === location.pathname || resolveStringPathWithSpaceId(routeLinks.dashboard.root, spaceId) === location.pathname;
}

function useGettingStartedState() {
    const onboardingState = useSelector((state: GlobalState) => state.onboardingArea.config);
    const drawerOpen = useSelector(isDrawerOpen);
    const isHelpSidebarEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isHelpSidebarEnabled);
    return onboardingState ? { showFooterOnDashboard: onboardingState.showFooterOnDashboard, isDrawerOpen: drawerOpen, isHelpSidebarEnabled } : {};
}
