/* eslint-disable custom-portal-rules/no-restricted-imports */

import Chip from "@material-ui/core/Chip";
import type { ChipProps } from "@material-ui/core/Chip";
import React from "react";
import { ProjectContext } from "~/areas/projects/context";
import { isGitCommit, isGitTag, toGitCommitShort, toGitRefShort } from "~/client/resources/versionControlledResource";
import type { GitReference } from "~/client/resources/versionControlledResource";
import { withTheme } from "~/components/Theme";
import { GitBranchIcon, GitCommitIcon, GitTagIcon } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";

export const GitRefChip: React.FC<{ vcsRef: GitReference; showCommit?: boolean } & ChipProps> = ({ vcsRef, showCommit = true, ...chipProps }) => {
    const projectContext = React.useContext(ProjectContext);
    const isDefaultBranch = projectContext && vcsRef.GitRef == projectContext?.state.gitRef?.CanonicalName;
    // Material Chips don't really expect an icon in the label, so we need to bend the styling to our will
    const iconInLabelStyles = {
        verticalAlign: "sub",
        marginRight: "4px",
    };

    const gitRefIcon = isGitCommit(vcsRef.GitRef) ? <GitCommitIcon /> : isGitTag(vcsRef.GitRef) ? <GitTagIcon /> : <GitBranchIcon />;

    const gitRefText = vcsRef.GitRef && (
        <ToolTip key="ref" content={vcsRef.GitRef}>
            {toGitRefShort(vcsRef.GitRef)}
        </ToolTip>
    );

    const innerCommitChip = isGitCommit(vcsRef.GitRef)
        ? null
        : vcsRef.GitCommit && (
              <ToolTip content={vcsRef.GitCommit} key="commit">
                  <GitCommitIcon key="icon" className="MuiChip-icon MuiChip-iconSmall" style={iconInLabelStyles} />
                  <span key="value">{toGitCommitShort(vcsRef.GitCommit)}</span>
              </ToolTip>
          );
    const label = showCommit ? [gitRefText, innerCommitChip] : gitRefText;

    return withTheme((theme) => {
        const chipStyles = {
            backgroundColor: theme.subtleButtonBackground,
        };
        // TODO: add background colours for default branch once we know what the dark theme colours are
        const defaultBranchChipStyles = chipStyles; // e.g. { backgroundColour: theme.whatAreWeCallingThisAndWhereElseDoWeUseIt }
        const styles = isDefaultBranch ? defaultBranchChipStyles : chipStyles;

        return (vcsRef && vcsRef.GitRef && <Chip icon={gitRefIcon} label={label} style={styles} {...chipProps} size="small"></Chip>) || null;
    });
};
