import { RemoveItemsList } from "app/components/RemoveItemsList/RemoveItemsList";
import React, { useEffect, useState } from "react";
import { Action, useAnalyticCloudConnectionsDispatch } from "~/analytics/Analytics";
import AwsCloudConnectionDrawer from "~/areas/projects/components/CloudConnection/AwsCloudConnectionDrawer";
import AwsCloudConnectionTile, { AwsTileStatus } from "~/areas/projects/components/CloudConnection/AwsCloudConnectionTile";
import AzureCloudConnectionDrawer from "~/areas/projects/components/CloudConnection/AzureCloudConnectionDrawer";
import AzureCloudConnectionTile, { AzureTileStatus } from "~/areas/projects/components/CloudConnection/AzureCloudConnectionTile";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectResource } from "~/client/resources/index";
import type { CloudConnectionType } from "~/client/resources/stepPackage";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import type { GitRef } from "~/client/resources/versionControlledResource";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { Summary } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";

class CloudConnectionsList extends RemoveItemsList<CloudConnectionItem> {}

export enum CloudConnectionUsageType {
    TargetDiscovery,
}
export type ConfigurationStatus = "Configure Now" | "Configured" | "Region Required";
export type CloudConnectionProps = {
    providers: CloudConnectionType[];
    connectionType: CloudConnectionUsageType;
    sectionErrorKey: string;
    sectionHelpText: string;
    isExpandedByDefault: boolean;
    projectVariables: VariableSetResource;
    libraryVariableSets: LoadedLibraryVariableSets[] | undefined;
    refreshVariables: () => void;
    saveVariables: (variables: VariableSetResource, commitMessage: CommitMessageWithDetails | undefined) => Promise<VariableSetResource>;
    haveVariablesChanged: (variables: VariableSetResource) => Promise<boolean>;
    project: ProjectResource;
    isDefaultBranch?: boolean;
    gitRef?: GitRef;
};

export interface CloudConnectionItem {
    provider: CloudConnectionType;
    connectionType: CloudConnectionUsageType;
    tileDetails: ConfigurationStatus;
}

const cloudConnectionItem = (connection: CloudConnectionItem) => {
    if (connection.provider === "Aws") {
        return <AwsCloudConnectionTile {...connection}></AwsCloudConnectionTile>;
    } else if (connection.provider === "Azure") {
        return <AzureCloudConnectionTile {...connection}></AzureCloudConnectionTile>;
    }
};

const GetStatus = (variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined, provider: CloudConnectionType, connectionType: CloudConnectionUsageType): ConfigurationStatus => {
    if (provider === "Azure") {
        return AzureTileStatus(connectionType, variables, libraryVariableSets);
    } else if (provider === "Aws") {
        return AwsTileStatus(connectionType, variables, libraryVariableSets);
    }
    return "Configure Now";
};

const GetCloudConnections = (providers: CloudConnectionType[], connectionType: CloudConnectionUsageType, variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined): CloudConnectionItem[] => {
    return providers.map((t) => ({
        provider: t,
        tileDetails: GetStatus(variables, libraryVariableSets, t, connectionType),
        connectionType: connectionType,
    }));
};

export const CloudConnections: React.FC<CloudConnectionProps> = (props) => {
    const [connectionToEdit, setConnectionToEdit] = useState<CloudConnectionItem | null>(null);
    const [connections, setConnections] = useState<CloudConnectionItem[]>([]);
    const [showAzureDrawer, setShowAzureDrawer] = useState<boolean>(false);
    const [showAwsDrawer, setShowAwsDrawer] = useState<boolean>(false);
    const dispatchAction = useAnalyticCloudConnectionsDispatch();
    const { navigate } = useSpaceAwareNavigation();

    function GetSummary(connections: CloudConnectionItem[]) {
        const configuredCloudConnections = connections.filter((item) => item.tileDetails === "Configured");
        return props.connectionType === CloudConnectionUsageType.TargetDiscovery
            ? Summary.summary(configuredCloudConnections.length > 0 ? <span>Cloud target discovery is enabled using cloud connections</span> : <span>Cloud target discovery is not enabled</span>)
            : Summary.summary(<span>Cloud connections have {configuredCloudConnections.length > 0 ? "been" : "not been"} configured</span>);
    }

    const summary = GetSummary(connections);

    useEffect(() => {
        const c = GetCloudConnections(props.providers, props.connectionType, props.projectVariables, props.libraryVariableSets);
        setConnections(c);
    }, [props.connectionType, props.providers, props.projectVariables, props.libraryVariableSets]);

    return (
        <>
            {connectionToEdit && (
                <AwsCloudConnectionDrawer
                    cloudConnectionItem={connectionToEdit}
                    connectionType={props.connectionType}
                    variables={props.projectVariables}
                    saveVariables={props.saveVariables}
                    showDrawer={showAwsDrawer}
                    setShowDrawer={setShowAwsDrawer}
                    refreshVariables={props.refreshVariables}
                    haveVariablesChanged={props.haveVariablesChanged}
                    project={props.project}
                    gitRef={props.gitRef}
                />
            )}
            {connectionToEdit && (
                <AzureCloudConnectionDrawer
                    cloudConnectionItem={connectionToEdit}
                    variables={props.projectVariables}
                    saveVariables={props.saveVariables}
                    showDrawer={showAzureDrawer}
                    setShowDrawer={setShowAzureDrawer}
                    refreshVariables={props.refreshVariables}
                    haveVariablesChanged={props.haveVariablesChanged}
                    project={props.project}
                    gitRef={props.gitRef}
                />
            )}
            <ExpandableFormSection
                title={props.connectionType === CloudConnectionUsageType.TargetDiscovery ? "Cloud Target Discovery" : "Cloud Connections"}
                isExpandedByDefault={props.isExpandedByDefault}
                errorKey={props.sectionErrorKey}
                summary={summary}
                help={props.sectionHelpText}
            >
                {props.connectionType === CloudConnectionUsageType.TargetDiscovery && (
                    <Note>
                        Learn more about <ExternalLink href="CloudTargetDiscovery">cloud target discovery</ExternalLink>
                    </Note>
                )}
                <CloudConnectionsList
                    data={connections}
                    onRow={cloudConnectionItem}
                    onRowTouch={(item) => {
                        if (item.tileDetails === "Configure Now") {
                            dispatchAction(`Open Cloud Connections Drawer`, {
                                action: Action.Select,
                                resource: "Cloud Connections",
                                cloudProvider: item.provider,
                            });
                            setConnectionToEdit(item);
                            if (item.provider === "Aws") {
                                setShowAwsDrawer(true);
                            }
                            if (item.provider === "Azure") {
                                setShowAzureDrawer(true);
                            }
                        } else {
                            dispatchAction(`Link To Cloud Connections Variables`, {
                                action: Action.Select,
                                resource: "Cloud Connections",
                                cloudProvider: item.provider,
                            });
                            navigate(routeLinks.project(props.project.Id).variables.root);
                        }
                    }}
                />
            </ExpandableFormSection>
        </>
    );
};
