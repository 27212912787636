import { createCompositeSink } from "~/utils/logging/CompositeSink";
import type { LogEvent } from "~/utils/logging/LogEvent";
import type { LoggingSink } from "~/utils/logging/LoggingSink";

export interface DynamicSink extends LoggingSink {
    attachSink(sinkToAttach: LoggingSink): RemoveSink;
}

export function createDynamicSink(): DynamicSink {
    let sinks: LoggingSink[] = [];
    return {
        attachSink(sinkToAttach: LoggingSink) {
            sinks = [...sinks, sinkToAttach];
            return () => {
                sinks = sinks.filter((s) => s !== sinkToAttach);
            };
        },
        receiveLogEvent(logEvent: LogEvent) {
            createCompositeSink(...sinks).receiveLogEvent(logEvent);
        },
    };
}

export type RemoveSink = () => void;
