/* eslint-disable custom-portal-rules/no-restricted-imports */
import { LinearProgress } from "@material-ui/core";
import React from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomDialogContent, CustomDialogTitleBar, SmallDialogFrame } from "~/components/DialogLayout/Custom";

interface ProgressDialogProps {
    open: boolean;
    title: string;
    content: string | React.ReactNode;
    progress?: number;
}

const ProgressDialog: React.FC<ProgressDialogProps> = ({ open, title, content, progress }) => {
    const variant = progress !== undefined ? "determinate" : "indeterminate";

    const computedContent = typeof content === "string" ? <p>{content}</p> : content;

    return (
        <CustomDialog
            open={open}
            close={() => null}
            render={() => (
                <SmallDialogFrame>
                    <CustomDialogTitleBar title={title} />
                    <CustomDialogContent>
                        {computedContent}
                        <LinearProgress variant={variant} value={progress} />
                    </CustomDialogContent>
                </SmallDialogFrame>
            )}
        />
    );
};

export default ProgressDialog;
