/* eslint-disable custom-portal-rules/no-restricted-imports */
import Avatar from "@material-ui/core/Avatar";
import * as React from "react";
import type { CSSProperties } from "react";
import styles from "./IconStyledForChips.module.less";

interface IconStyledForChipProps {
    iconComponent: React.ComponentType<{ className?: string; style?: CSSProperties }>;
    backgroundColor?: string;
    iconColor?: string;
}

export function IconStyledForChip(props: IconStyledForChipProps): React.ReactElement | null {
    const { iconComponent: Icon } = props;
    const avatarProps = props.backgroundColor
        ? {
              style: {
                  backgroundColor: props.backgroundColor,
              },
          }
        : {};

    const iconProps = props.iconColor
        ? {
              style: {
                  fill: props.iconColor,
              },
          }
        : {};

    return (
        <Avatar className={styles.avatar} {...avatarProps}>
            <Icon className={styles.icon} {...iconProps} />
        </Avatar>
    );
}
