import type { LogEvent } from "~/utils/logging/LogEvent";
import type { LoggingSink } from "~/utils/logging/LoggingSink";

export function createCompositeSink(...sinks: LoggingSink[]): LoggingSink {
    return {
        receiveLogEvent(logEvent: LogEvent) {
            sinks.forEach((s) => s.receiveLogEvent(logEvent));
        },
    };
}
