/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import DashboardOverview from "~/areas/dashboard/DashboardOverview/DashboardOverview";
import { DashboardRoutes } from "~/areas/dashboard/DashboardRoutes";
import DeploymentRoutes from "~/areas/deployments/DeploymentRoutes";
import { V3AccountRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3AccountRoutes";
import { V3EnvironmentRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3EnvironmentRoutes";
import { V3MachineRoutes } from "~/areas/infrastructure/components/InfrastructureRoutes/V3MachineRoutes";
import InsightsRoutes from "~/areas/insights/components/InsightsRoutes/InsightsRoutes";
import ProjectGroupLayout from "~/areas/projects/components/ProjectGroupLayout";
import RunbookRunRoutes from "~/areas/runbookRuns/RunbookRunRoutes";
import UserProfileRoutes from "~/areas/users/UserProfileRoutes";
import { repository, session } from "~/clientInstance";
import NoPermissionsPage from "~/components/NoPermissionsPage/NoPermissionsPage";
import { NotFound, RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { UxRoutes } from "~/components/UxGuide/UxRoutes";
import Environment from "~/environment";
import importAsync from "~/importAsync";
import pageIds from "~/pageIds";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { SpaceNotFound } from "../SpaceNotFound/SpaceNotFound";
import type { SpaceContext } from "../StandardLayout/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "../StandardLayout/SpaceLoader";
import { ChannelRedirect, ReleaseRedirect, TriggerRedirect, RunbookRedirect } from "./ProjectChildRedirect";
import { RunbookSnapshotRedirect } from "./ProjectRunbookChildRedirect";

const ProjectRoutes = importAsync(
    () => import(/* webpackChunkName: "projects" */ "~/areas/projects/components"),
    (index) => index.ProjectRoutes
);
const LibraryRoutes = importAsync(
    () => import(/* webpackChunkName: "library" */ "~/areas/library/components"),
    (index) => index.LibraryRoutes
);
const InfrastructureRoutes = importAsync(
    () => import(/* webpackChunkName: "infrastructure" */ "~/areas/infrastructure/components"),
    (index) => index.InfrastructureRoutes
);
const TenantsRoutes = importAsync(
    () => import(/* webpackChunkName: "tenants" */ "~/areas/tenants"),
    (index) => index.TenantsRoutes
);
const ConfigurationRoutes = importAsync(
    () => import(/* webpackChunkName: "configuration" */ "~/areas/configuration/components"),
    (index) => index.ConfigurationRoutes
);
const TaskRoutes = importAsync(
    () => import(/* webpackChunkName: "tasks" */ "~/areas/tasks/components"),
    (index) => index.TaskRoutes
);
const StyleGuide = importAsync(
    () => import(/* webpackChunkName: "styleGuide" */ "~/components/StyleGuide"),
    (index) => index.StyleGuide,
    !Environment.isInDevelopmentMode()
);
const UxGuide = importAsync(
    () => import(/* webpackChunkName: "uxGuide" */ "~/components/UxGuide"),
    (index) => index.UxGuide,
    !Environment.isInDevelopmentMode()
);

const StyleGuidePage = withPage({ page: pageIds.styleGuide })(StyleGuide);
const UxGuidePage = withPage({ page: pageIds.uxGuide })(UxGuide);
const ProjectGroupPage = withPage({ page: pageIds.projectGroup.root })(ProjectGroupLayout);
const DashboardOverviewPage = withPage({ page: pageIds.dashboard.root })(DashboardOverview);

interface RootRoutesProps extends RouteComponentProps<{}> {
    spaceContext: SpaceContext;
}
interface RootRoutesState {
    userCanOnlyAccessPrivateSpace: boolean;
}

export const typeSafeHasOwnProperty = <T extends {}>(target: T, key: keyof T) => {
    return target.hasOwnProperty(key);
};

const is404State = <T,>(state: T): state is T & { is404: boolean } => {
    if (!state) return false;
    const hasProperty = Object.prototype.hasOwnProperty.call(state, "is404");
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = state as unknown as { is404: boolean };
    return hasProperty && casted.is404 === true;
};

class RootRoutes extends React.Component<RootRoutesProps, RootRoutesState> {
    constructor(props: RootRoutesProps) {
        super(props);

        this.state = {
            userCanOnlyAccessPrivateSpace: false,
        };
    }

    async componentDidMount() {
        const spaces = await repository.Users.getSpaces(session.currentUser!);
        if (spaces.length === 1 && spaces[0].IsPrivate) {
            this.setState({ userCanOnlyAccessPrivateSpace: true });
        }
    }

    render() {
        return (
            <Route
                render={({ location }) => {
                    if (is404State(location.state)) {
                        return <NotFound />;
                    }

                    return (
                        <Switch>
                            {this.defaultRoute()}
                            {this.spaceRoutes()}
                            <ReloadableRoute path={routeLinks.currentUser.me} component={UserProfileRoutes} />
                            <ReloadableRoute path={routeLinks.styleGuide} component={StyleGuidePage} />
                            <ReloadableRoute path={routeLinks.uxGuide.root} component={UxRoutes} />
                            {this.systemOrMixedScopeRoutes()}
                            {this.noMatch()}
                        </Switch>
                    );
                }}
            />
        );
    }

    noMatch = () => {
        const spaceContext = this.props.spaceContext;
        if (isSpaceNotFound(spaceContext)) {
            return <ReloadableRoute render={() => <SpaceNotFound spaceNotFoundContext={spaceContext} />} />;
        } else if (!session.currentPermissions!.hasAnyPermissions()) {
            return <ReloadableRoute component={NoPermissionsPage} />;
        }
        return <RedirectAs404 />;
    };

    systemOrMixedScopeRoutes = () => {
        if (isSpaceNotFound(this.props.spaceContext)) {
            // If the space was not found, we don't want to show routes that are available in the system context.
            // We want to direct people to go to either a space-less route, or to step into a space.
            // So we should only show the Space Switcher in the navbar and disallow other routing.
            return [];
        }
        if (!session.currentPermissions!.hasAnyPermissions()) {
            return [];
        }

        return [
            <ReloadableRoute key="tasks" path={routeLinks.tasks.root} component={TaskRoutes} />,
            <ReloadableRoute key="configuration" path={routeLinks.configuration.root} component={ConfigurationRoutes} />,
            <ReloadableRoute key="legacy" path={routeLinks.legacy.manage.console} render={(p) => <InternalRedirect to={{ ...p.location, pathname: routeLinks.tasks.console }} />} />,
        ];
    };

    defaultRoute = () => {
        if (isSpaceNotFound(this.props.spaceContext)) {
            return [];
        }
        if (isSpecificSpaceContext(this.props.spaceContext)) {
            return [<ReloadableRoute key="dashboard default" exact path={routeLinks.root} component={DashboardOverviewPage} />];
        }
        if (session.currentPermissions!.hasAnyPermissions()) {
            // When a user logs in for the first time, and hasn't been added to any teams, they should see the NoPermissionsPage
            // Adding Private Spaces changed that behavior, since they could access their private space.
            // However, we don't want to automatically land people in their private space, so here we preserve the original behaviour.
            if (this.state && this.state.userCanOnlyAccessPrivateSpace) {
                return <ReloadableRoute component={NoPermissionsPage} />;
            }
            return [
                <ReloadableRoute
                    key="configuration redirect"
                    exact
                    path={routeLinks.root}
                    render={() => {
                        return <InternalRedirect push={false} to={routeLinks.configuration.root} />;
                    }}
                />,
            ];
        }
        return [];
    };

    spaceRoutes = () => {
        if (!isSpecificSpaceContext(this.props.spaceContext)) {
            // If you are not in a space, then it does not make sense to render any space specific components
            // We are better off letting this fall through to a 404,
            // rather than try and fail to render a space specific component, and probably end up with undefined behaviour
            return [];
        }
        return [
            <ReloadableRoute key="dashboard" path={routeLinks.dashboard.root} component={DashboardRoutes} />,
            <ReloadableRoute key="projects" path={routeLinks.projects.root} component={ProjectRoutes} />,
            <ReloadableRoute key="project groups" path={routeLinks.projectGroup(":ProjectGroupId").root} component={ProjectGroupPage} />,
            <ReloadableRoute key="infrastructure" path={routeLinks.infrastructure.root} component={InfrastructureRoutes} />,
            <ReloadableRoute key="tenants" path={routeLinks.tenants} component={TenantsRoutes} />,
            <ReloadableRoute key="library" path={routeLinks.library.root} component={LibraryRoutes} />,
            <ReloadableRoute key="deployments" path={routeLinks.deployments} component={DeploymentRoutes} />,

            <ReloadableRoute key="release redirect" path={routeLinks.release(":releaseId")} render={(p: RouteComponentProps<{ releaseId: string }>) => <ReleaseRedirect releaseId={p.match.params.releaseId} />} />,
            <ReloadableRoute key="trigger redirect" path={routeLinks.trigger(":triggerId")} render={(p: RouteComponentProps<{ triggerId: string }>) => <TriggerRedirect triggerId={p.match.params.triggerId} />} />,
            <ReloadableRoute key="channel redirect" path={routeLinks.channel(":channelId")} render={(p: RouteComponentProps<{ channelId: string }>) => <ChannelRedirect channelId={p.match.params.channelId} />} />,

            <ReloadableRoute key="runbookruns" path={routeLinks.runbookRuns} component={RunbookRunRoutes} />,
            <ReloadableRoute
                key="runbooksnapshot redirect"
                path={routeLinks.runbookSnapshot(":runbookSnapshotId")}
                render={(p: RouteComponentProps<{ runbookSnapshotId: string }>) => <RunbookSnapshotRedirect runbookSnapshotId={p.match.params.runbookSnapshotId} />}
            />,
            <ReloadableRoute key="runbook redirect" path={routeLinks.runbook(":runbookId")} render={(p: RouteComponentProps<{ runbookId: string }>) => <RunbookRedirect runbookId={p.match.params.runbookId} />} />,

            <ReloadableRoute key="legacy environments" path={routeLinks.legacy.environments} component={V3EnvironmentRoutes} />,
            <ReloadableRoute key="legacy accounts" path={routeLinks.legacy.accounts} component={V3AccountRoutes} />,
            <ReloadableRoute key="legacy machines" path={routeLinks.legacy.machines} component={V3MachineRoutes} />,
            <ReloadableRoute key="insights" path={routeLinks.insights.root} component={InsightsRoutes} />,
        ];
    };
}

export default withRouter(RootRoutes);
