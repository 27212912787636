/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as _ from "lodash";
import * as React from "react";
import type { GitRefResource, ProjectResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { KubernetesNameRegex } from "~/components/Actions/kubernetes/kubernetesValidation";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { ExtendedKeyValueEditList } from "~/components/EditList/ExtendedKeyValueEditList";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import { StringRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Note from "../../../primitiveComponents/form/Note/Note";
import { BoundSelect, default as Select } from "../../../primitiveComponents/form/Select/Select";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import isBound from "../../form/BoundField/isBound";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { CombinedVolumeDetails } from "./kubernetesDeployContainersAction";

export const ConfigMapType = "ConfigMap";
export const SecretType = "Secret";
export const EmptyDirType = "EmptyDir";
export const HostPathType = "HostPath";
export const PersistentVolumeClaimType = "PersistentVolumeClaim";
export const RawYamlType = "RawYaml";
export const CustomResource = "CustomResource";
export const LinkedResource = "LinkedResource";

interface ContainerState extends DataBaseComponentState {
    combinedVolumeDetails: CombinedVolumeDetails;
    project?: ProjectResource;
    configMapFeatureEnabled: boolean;
    secretFeatureEnabled: boolean;
}

interface CombinedVolumeProps {
    combinedVolumeDetails: CombinedVolumeDetails;
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    featuresEnabled: string;
    standalone?: boolean;
    onAdd(Binding: CombinedVolumeDetails): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

class CombinedVolumeDialog extends DataBaseComponent<CombinedVolumeProps, ContainerState> {
    constructor(props: CombinedVolumeProps) {
        super(props);
        this.state = {
            combinedVolumeDetails: null!,
            project: null!,
            configMapFeatureEnabled: false,
            secretFeatureEnabled: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;
            const configMapFeatureEnabled = !!this.props.featuresEnabled && this.props.featuresEnabled.split(",").includes("Octopus.Features.KubernetesConfigMap");
            const secretFeatureEnabled = !!this.props.featuresEnabled && this.props.featuresEnabled.split(",").includes("Octopus.Features.KubernetesSecret");
            const combinedVolumeDetails: CombinedVolumeDetails = { ...this.props.combinedVolumeDetails };

            // If the config map feature is not enabled, then we must supply a custom name
            if ((combinedVolumeDetails.Type === ConfigMapType && !configMapFeatureEnabled) || (combinedVolumeDetails.Type === SecretType && !secretFeatureEnabled) || !combinedVolumeDetails.ReferenceNameType) {
                combinedVolumeDetails.ReferenceNameType = CustomResource;
            }

            this.setState({
                combinedVolumeDetails,
                configMapFeatureEnabled,
                secretFeatureEnabled,
            });
        });
    }

    save = () => {
        let valid = true;
        const binding = this.state.combinedVolumeDetails;

        if (!(isBound(binding.Name) || KubernetesNameRegex.exec(binding.Name))) {
            this.setValidationErrors("The volume name must consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.", { CombinedVolumeDetailsName: "The volume name must be defined." });
            valid = false;
        }

        if (binding.Type === ConfigMapType) {
            if (binding.ReferenceNameType === CustomResource && (!binding.ReferenceName || !binding.ReferenceName.trim())) {
                this.setValidationErrors("The ConfigMap name must be defined.", { CombinedVolumeReferenceName: "The ConfigMap name must be defined." });
                valid = false;
            }
        }

        if (binding.Type === SecretType) {
            if (binding.ReferenceNameType === CustomResource && (!binding.ReferenceName || !binding.ReferenceName.trim())) {
                this.setValidationErrors("The secret name must be defined.", { CombinedVolumeReferenceName: "The secret name must be defined." });
                valid = false;
            }
        }

        if (binding.Type === HostPathType) {
            if (!binding.HostPathPath || !binding.HostPathPath.trim()) {
                this.setValidationErrors("The host path path must be defined.", { CombinedVolumeHostPathPath: "The host path path must be defined." });
                valid = false;
            }
        }

        if (binding.Type === PersistentVolumeClaimType) {
            if (!binding.ReferenceName || !binding.ReferenceName.trim()) {
                this.setValidationErrors("The persistent volume claim name must be defined.", { CombinedVolumeReferenceName: "The persistent volume claim name must be defined." });
                valid = false;
            }
        }

        if (binding.Type === RawYamlType) {
            if (!binding.RawYaml || !binding.RawYaml.trim()) {
                this.setValidationErrors("The YAML must be defined.", { CombinedVolumeRawYaml: "The YAML must be defined." });
                valid = false;
            }
        }

        if (valid) {
            return this.props.onAdd(binding);
        }

        return valid;
    };

    render() {
        return (
            <OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Add Volume"}>
                {this.state.combinedVolumeDetails && (
                    <div>
                        <Select
                            value={this.state.combinedVolumeDetails.Type}
                            items={[
                                { text: "Config Map", value: ConfigMapType },
                                { text: "Secret", value: SecretType },
                                { text: "Empty Dir", value: EmptyDirType },
                                { text: "Host Path", value: HostPathType },
                                { text: "Persistent Volume Claim", value: PersistentVolumeClaimType },
                                { text: "Raw YAML", value: RawYamlType },
                            ]}
                            label="Volume type"
                            onChange={(Type) => {
                                this.setContainerState({ Type: Type! });
                                this.clearErrors();
                            }}
                        />
                        <VariableLookupText label="Name" localNames={this.props.localNames} error={this.getFieldError("CombinedVolumeDetailsName")} value={this.state.combinedVolumeDetails.Name} onChange={(Name) => this.setContainerState({ Name })} />
                        {this.configMapUI()}
                        {this.secretUI()}
                        {this.emptyDirUI()}
                        {this.hostPathUI()}
                        {this.rawYamlUI()}
                        {this.persistentVolumeClaimUI()}
                        {this.gitRepoUI()}
                    </div>
                )}
            </OkDialogLayout>
        );
    }

    private rawYamlUI() {
        if (this.state.combinedVolumeDetails.Type === "RawYaml") {
            return (
                <div>
                    <VariableLookupText
                        label={"Raw YAML"}
                        localNames={this.props.localNames}
                        error={this.getFieldError("CombinedVolumeRawYaml")}
                        value={this.state.combinedVolumeDetails.RawYaml}
                        onChange={(RawYaml) => this.setContainerState({ RawYaml })}
                        multiline={true}
                        rows={5}
                        rowsMax={5}
                    />
                    <Note>
                        Enter the raw YAML for the volume. The YAML must start with the volume type property, and not include other properties like name e.g.
                        <pre>
                            <code>{`awsElasticBlockStore:
  volumeID: myVolumeId
  fsType: ext4`}</code>
                        </pre>
                    </Note>
                </div>
            );
        }
    }

    private hostPathUI() {
        if (this.state.combinedVolumeDetails.Type === "HostPath") {
            return (
                <div>
                    <BoundSelect
                        variableLookup={{
                            localNames: this.props.localNames,
                        }}
                        resetValue={"Directory"}
                        value={this.state.combinedVolumeDetails.HostPathType}
                        items={[
                            { text: "", value: "" },
                            { text: "DirectoryOrCreate", value: "DirectoryOrCreate" },
                            { text: "Directory", value: "Directory" },
                            { text: "FileOrCreate", value: "FileOrCreate" },
                            { text: "File", value: "File" },
                            { text: "Socket", value: "Socket" },
                            { text: "CharDevice", value: "CharDevice" },
                            { text: "BlockDevice", value: "BlockDevice" },
                        ]}
                        label="Type"
                        onChange={(HostPath) => this.setContainerState({ HostPathType: HostPath! })}
                    />
                    <VariableLookupText
                        label={"Path"}
                        localNames={this.props.localNames}
                        error={this.getFieldError("CombinedVolumeHostPathPath")}
                        value={this.state.combinedVolumeDetails.HostPathPath}
                        onChange={(HostPathPath) => this.setContainerState({ HostPathPath })}
                    />
                </div>
            );
        }
    }

    private emptyDirUI() {
        if (this.state.combinedVolumeDetails.Type === "EmptyDir") {
            return (
                <BoundSelect
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    resetValue={"Default"}
                    value={this.state.combinedVolumeDetails.EmptyDirMedium}
                    items={[
                        { text: "", value: "" },
                        { text: "Memory", value: "Memory" },
                    ]}
                    label="Medium"
                    onChange={(EmptyDirMedium) => this.setContainerState({ EmptyDirMedium: EmptyDirMedium! })}
                />
            );
        }
    }

    private persistentVolumeClaimUI() {
        if (this.state.combinedVolumeDetails.Type === "PersistentVolumeClaim") {
            return (
                <div>
                    <VariableLookupText
                        label={"Persistent volume claim name"}
                        localNames={this.props.localNames}
                        error={this.getFieldError("CombinedVolumeReferenceName")}
                        value={this.state.combinedVolumeDetails.ReferenceName}
                        onChange={(SecretName) => this.setContainerState({ ReferenceName: SecretName })}
                    />
                </div>
            );
        }
    }

    private configMapUI() {
        if (this.state.combinedVolumeDetails.Type === "ConfigMap") {
            return (
                <div>
                    <p>
                        <strong>Config map resource name</strong>
                    </p>
                    {this.state.configMapFeatureEnabled && (
                        <div>
                            <p>The volume can be linked to the config map resource created by the feature in this step, or linked to an external config map that was created outside the step.</p>
                            <StringRadioButtonGroup value={this.state.combinedVolumeDetails.ReferenceNameType} onChange={(type) => this.setContainerState({ ReferenceNameType: type })}>
                                <RadioButton value={LinkedResource} label="Reference the config map created as part of this step" />
                                <RadioButton value={CustomResource} label="Reference an external config map resource" />
                            </StringRadioButtonGroup>
                        </div>
                    )}
                    {!this.props.standalone && !this.state.configMapFeatureEnabled && (
                        <div>
                            <p>By enabling the Config Map feature, this volume can be linked to a config map resource created as part of the step.</p>
                        </div>
                    )}
                    {(!this.state.configMapFeatureEnabled || this.state.combinedVolumeDetails.ReferenceNameType === CustomResource) && (
                        <VariableLookupText
                            localNames={this.props.localNames}
                            placeholder="The name of the config map"
                            error={this.getFieldError("CombinedVolumeReferenceName")}
                            value={this.state.combinedVolumeDetails.ReferenceName}
                            onChange={(name) => this.setContainerState({ ReferenceName: name })}
                            label={"Config map name"}
                        />
                    )}
                    <p>
                        <strong>Items</strong>
                    </p>
                    <ExtendedKeyValueEditList
                        items={() => (_.isArray(this.state.combinedVolumeDetails.Items) ? this.state.combinedVolumeDetails.Items : [])}
                        name="Item"
                        onAdd={this.repositionDialog}
                        onChange={(val) => this.setContainerState({ Items: val }, this.repositionDialog)}
                        valueLabel="Path"
                        keyLabel="Key"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                        gitRef={this.props.gitRef}
                    />
                </div>
            );
        }
    }

    private secretUI() {
        if (this.state.combinedVolumeDetails.Type === "Secret") {
            return (
                <div>
                    {this.state.secretFeatureEnabled && (
                        <div>
                            <p>The volume can be linked to the secret resource created by the feature in this step, or linked to an external secret that was created outside the step.</p>
                            <StringRadioButtonGroup value={this.state.combinedVolumeDetails.ReferenceNameType} onChange={(type) => this.setContainerState({ ReferenceNameType: type })}>
                                <RadioButton value={LinkedResource} label="Reference the secret created as part of this step" />
                                <RadioButton value={CustomResource} label="Reference an external secret resource" />
                            </StringRadioButtonGroup>
                        </div>
                    )}
                    {!this.props.standalone && !this.state.secretFeatureEnabled && (
                        <div>
                            <p>By enabling the Config Map feature, this volume can be linked to a config map resource created as part of the step.</p>
                        </div>
                    )}
                    {(!this.state.secretFeatureEnabled || this.state.combinedVolumeDetails.ReferenceNameType === CustomResource) && (
                        <VariableLookupText
                            label={"Secret name"}
                            localNames={this.props.localNames}
                            error={this.getFieldError("CombinedVolumeReferenceName")}
                            value={this.state.combinedVolumeDetails.ReferenceName}
                            onChange={(SecretName) => this.setContainerState({ ReferenceName: SecretName })}
                        />
                    )}
                    <p>
                        <strong>Items</strong>
                    </p>
                    <ExtendedKeyValueEditList
                        items={() => (_.isArray(this.state.combinedVolumeDetails.Items) ? this.state.combinedVolumeDetails.Items : [])}
                        name="Item"
                        onAdd={this.repositionDialog}
                        onChange={(val) => this.setContainerState({ Items: val }, this.repositionDialog)}
                        valueLabel="Path"
                        keyLabel="Key"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                        gitRef={this.props.gitRef}
                    />
                </div>
            );
        }
    }

    private gitRepoUI() {
        if (this.state.combinedVolumeDetails.Type === "GitRepo") {
            return (
                <div>
                    <VariableLookupText
                        label={"Repository"}
                        localNames={this.props.localNames}
                        error={this.getFieldError("CombinedVolumeRepository")}
                        value={this.state.combinedVolumeDetails.Repository}
                        onChange={(Repository) => this.setContainerState({ Repository })}
                    />
                    <VariableLookupText
                        label={"Revision"}
                        localNames={this.props.localNames}
                        error={this.getFieldError("CombinedVolumeRevision")}
                        value={this.state.combinedVolumeDetails.Revision}
                        onChange={(Revision) => this.setContainerState({ Revision })}
                    />
                </div>
            );
        }
    }

    private setContainerState<K extends keyof CombinedVolumeDetails>(state: Pick<CombinedVolumeDetails, K>, callback?: () => void) {
        this.setChildState1("combinedVolumeDetails", state, callback);
    }

    /**
     * https://github.com/mui-org/material-ui/issues/1676
     * https://github.com/mui-org/material-ui/issues/5793
     * When adding or removing items from a list, the dialog needs to be repositioned, otherwise
     * the list may disappear off the screen. A resize event is the commonly suggested workaround.
     */
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }
}

export default CombinedVolumeDialog;
