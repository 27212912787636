/* eslint-disable custom-portal-rules/no-restricted-imports */

import { Card, CardContent } from "@material-ui/core";
import cn from "classnames";
import * as React from "react";
import type { FormSectionBaseProps } from "~/components/form/Sections/FormSectionBase";
import { FormSectionBase } from "~/components/form/Sections/FormSectionBase";
import styles from "./style.module.less";

interface UnstructuredFormSectionProps extends FormSectionBaseProps {
    className?: string;
    stretchContent?: boolean;
}

class UnstructuredFormSection extends FormSectionBase<UnstructuredFormSectionProps> {
    render() {
        return (
            <Card className={styles.formNonExpander}>
                <CardContent className={cn(this.props.stretchContent ? styles.stretchContent : null, this.props.className ? this.props.className : styles.unstructuredFormContent)}>{this.props.children}</CardContent>
            </Card>
        );
    }
}

export default UnstructuredFormSection;
