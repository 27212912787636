/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as _ from "lodash";
import * as React from "react";
import type { ProjectResource, ActionTemplateParameterResource, GitRefResource } from "~/client/resources";
import { HasVariablesInGit, ControlType } from "~/client/resources";
import type { VariableResource } from "~/client/resources/variableResource";
import { VariableType } from "~/client/resources/variableResource";
import { repository, client } from "~/clientInstance";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import { resolveStringPathWithSpaceId } from "~/components/Navigation/resolvePathWithSpaceId";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import BusyIndicator from "../../BusyIndicator/index";
import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";

interface CertificateVariableSelectProps extends FormFieldProps<string> {
    projectId: string;
    gitRef: GitRefResource | undefined;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface CertificateVariableSelectState {
    error?: string;
    refreshing: boolean;
    project: ProjectResource | null;
    variables: string[];
    isDataLoaded: boolean;
}

class CertificateVariableSelect extends React.Component<CertificateVariableSelectProps, CertificateVariableSelectState> {
    constructor(props: CertificateVariableSelectProps) {
        super(props);
        this.state = {
            refreshing: false,
            project: null,
            variables: [],
            isDataLoaded: false,
        };
    }

    handleChange = (certificateVariable: any) => {
        const value = certificateVariable === "" ? null : certificateVariable;
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId);
            const variables = await this.getCertificateVariables(project, this.props.gitRef);

            this.setState({ project, variables, isDataLoaded: true });
        });
    }

    render() {
        if (!this.state.isDataLoaded) {
            return <BusyIndicator show={true} inline={true} />;
        }

        const { onChange, onValidate, projectId, doBusyTask, ...otherProps } = this.props;

        return (
            <InputWithActions
                input={<Select label="Select certificate variable" {...otherProps} allowFilter={true} error={this.state.error || this.props.error} onChange={this.handleChange} items={this.state.variables.map((v) => ({ value: v, text: v }))} />}
                actions={<IconButtonList buttons={this.getButtons()} />}
            />
        );
    }

    private getButtons() {
        const buttons = [];

        if (!this.state.refreshing) {
            buttons.push(<IconButton toolTipContent="Refresh" icon={Icon.Refresh} onClick={this.onRequestRefresh} disabled={!this.state.project} />);
        } else {
            buttons.push(<BusyIndicator show={true} inline={true} />);
        }
        buttons.push(<IconButton toolTipContent="Add" icon={Icon.Add} onClick={this.goTo} />);

        return buttons;
    }
    private goTo = () => {
        window.open(`#${resolveStringPathWithSpaceId(routeLinks.project(this.props.projectId).variables.root, client.spaceId!)}`, "_blank");
    };

    private getCertificateVariables = async (project: ProjectResource, gitRef: GitRefResource | undefined) => {
        const libraryVariableSets = await Promise.all(project.IncludedLibraryVariableSetIds.map((libraryVariableSetId) => repository.LibraryVariableSets.get(libraryVariableSetId)));
        const templates = _.union(project.Templates, _.flattenDeep<ActionTemplateParameterResource>(libraryVariableSets.map((lvs) => lvs.Templates)))
            .filter((template: ActionTemplateParameterResource) => !!template.DisplaySettings && template.DisplaySettings["Octopus.ControlType"] === ControlType.Certificate)
            .map((v) => v.Name);
        const variableSetIds = _.union(
            [project.VariableSetId],
            libraryVariableSets.map((lvs) => lvs.VariableSetId)
        );
        const variableResults = await Promise.all(variableSetIds.map(async (variableSetId) => (await repository.Variables.get(variableSetId)).Variables));

        if (HasVariablesInGit(project.PersistenceSettings) && gitRef) {
            variableResults.push((await repository.Variables.getForGitRef(gitRef)).Variables);
        }

        const variables = _.flattenDeep<VariableResource>(variableResults)
            .filter((v: VariableResource) => v.Type === VariableType.Certificate)
            .map((v) => v.Name);

        return _.chain(variables).union(templates).sort().uniq().value();
    };

    private onRequestRefresh = async () => {
        if (!this.state.project) {
            return;
        }

        this.setState({ refreshing: true });

        try {
            const variables = await this.getCertificateVariables(this.state.project, this.props.gitRef);
            this.setState({ variables });
        } finally {
            this.setState({ refreshing: false });
        }
    };
}

export default CertificateVariableSelect;
