import type { ResourceWithSlug } from "~/client/resources/ResourceWithSlug";
import type { MixedSpaceResource } from "./mixedSpaceResource";
import type { NamedReferenceItem } from "./namedReferenceItem";
import type { NamedResource } from "./namedResource";

export const TeamConstants = {
    SpaceManagersTeamIdPrefix: "teams-spacemanagers-",
};

export interface TeamResource extends NamedResource, MixedSpaceResource, ResourceWithSlug {
    ExternalSecurityGroups: NamedReferenceItem[];
    MemberUserIds: string[];
    CanBeDeleted: boolean;
    CanBeRenamed: boolean;
    CanChangeMembers: boolean;
    Description: string;
    // can change exists at team level to support built in teams
    CanChangeRoles: boolean;
}

export interface TeamNameResource extends NamedResource {
    Name: string;
}

export default TeamResource;
