import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { GitRefDisplay } from "~/components/GitRefDisplay/GitRefDisplay";

export function ProjectCurrentBranchDisplay() {
    const project = useProjectContext();
    if (!project.state.model.IsVersionControlled) {
        return <></>;
    }

    return <GitRefDisplay currentHead={project.state.gitRef?.CanonicalName} isDefaultBranch={project.state.isDefaultBranch}></GitRefDisplay>;
}
