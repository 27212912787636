import React, { useState } from "react";
import { Action, AnalyticView } from "~/analytics/Analytics";
import { useAlwaysHideOnboardingQuestionnaire } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/AlwaysHideOnboardingQuestionnaire";
import type { QuestionnaireEvent } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/QuestionnaireAnalytics";
import { useOnboardingQuestionnaireSubmittedDispatch } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/QuestionnaireAnalytics";
import QuestionnaireDialogLayout from "~/areas/projects/components/Projects/OnboardingQuestionnaire/QuestionnaireDialogLayout";
import styles from "~/areas/projects/components/Projects/OnboardingQuestionnaire/style.module.less";
import Dialog from "~/components/Dialog/Dialog";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { Checkbox, Select, Text } from "~/components/form/index";
import { applicationTypeOptions, buildTypeOptions, deploymentLocationOptions, packageLocationOptions } from "./QuestionnaireAnswers";
import type { ApplicationTypeOption, BuildTypeOption, DeploymentLocationOption, PackageLocationOption } from "./QuestionnaireAnswers";

type OnboardingQuestionnaireAnswers = {
    applicationType: ApplicationTypeOption | undefined;
    otherApplicationType: string | undefined;
    buildTool: BuildTypeOption | undefined;
    otherBuildTool: string | undefined;
    packageLocation: PackageLocationOption | undefined;
    otherPackageLocation: string | undefined;
    deploymentLocation: DeploymentLocationOption | undefined;
    otherDeploymentLocation: string | undefined;
    runbookOnly: boolean | undefined;
};

export function OnboardingQuestionnaireDialog() {
    const [show, setShow] = useState<boolean>(true);
    return (
        <Dialog open={show}>
            <OnboardingQuestionnaireDialogLayout close={() => setShow(false)} />
        </Dialog>
    );
}

export function OnboardingQuestionnaireDialogLayout(props: { close: () => void }) {
    const isProjectBasedActivationFeatureEnabled = useEnabledFeatureToggle("ProjectBasedActivationFeatureToggle");
    const trackingEvent = useOnboardingQuestionnaireSubmittedDispatch();
    const [alwaysHideOnboardingQuestionnaire, setAlwaysHideOnboardingQuestionnaire] = useAlwaysHideOnboardingQuestionnaire();
    const [answers, setAnswers] = useState<OnboardingQuestionnaireAnswers>({
        applicationType: undefined,
        buildTool: undefined,
        deploymentLocation: undefined,
        packageLocation: undefined,
        runbookOnly: undefined,
        otherApplicationType: undefined,
        otherBuildTool: undefined,
        otherDeploymentLocation: undefined,
        otherPackageLocation: undefined,
    });
    const dontShowAgainProps = {
        [isProjectBasedActivationFeatureEnabled ? "additionalActions" : "additionalPrimaryActions"]: (
            <Checkbox
                key={"DontShowAgain"}
                className={styles.dontShowAgain}
                value={alwaysHideOnboardingQuestionnaire}
                onChange={(x) => {
                    setAlwaysHideOnboardingQuestionnaire(x);
                    if (x) {
                        dontShowAgainAction();
                    }
                }}
                label={isProjectBasedActivationFeatureEnabled ? "Don't show this questionnaire again" : "Don't show this again"}
            />
        ),
    };
    const dontShowAgainAction = () => {
        const ev: QuestionnaireEvent = {
            action: Action.Dismiss,
            resource: "Survey",
        };
        trackingEvent("Dismiss project survey", ev);
    };

    return (
        <>
            <AnalyticView name="View project survey" resource="Survey" />
            <QuestionnaireDialogLayout
                {...dontShowAgainProps}
                title="How do you intend to use this project?"
                saveButtonLabel="Submit"
                cancelButtonLabel="Close"
                onSaveClick={async () => {
                    const ev: QuestionnaireEvent = {
                        action: Action.Save,
                        resource: "Survey",
                        data: {
                            technologyStack: answers.otherApplicationType ?? answers.applicationType,
                            buildServer: answers.otherBuildTool ?? answers.buildTool,
                            packageHost: answers.otherPackageLocation ?? answers.packageLocation,
                            hostingPlatform: answers.otherDeploymentLocation ?? answers.deploymentLocation,
                            runbookOnly: answers.runbookOnly === undefined ? undefined : answers.runbookOnly ? "True" : "False",
                        },
                    };
                    trackingEvent("Submit project survey", ev);
                    props.close();
                    return true;
                }}
                onCancelClick={() => {
                    const ev: QuestionnaireEvent = {
                        action: Action.Cancel,
                        resource: "Survey",
                    };
                    trackingEvent("Cancel project survey", ev);

                    props.close();

                    return true;
                }}
            >
                <div className={styles.questionInfo}>We're looking to learn more about the projects our customers are creating to help us guide the direction of Octopus.</div>
                <div className={styles.question}>
                    <Select
                        label="What type of application do you intend to deploy?"
                        highContrastMode={true}
                        value={answers.applicationType || ""}
                        sortItems={false}
                        items={applicationTypeOptions.map((op) => ({ value: op, text: op }))}
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        onChange={(x) => setAnswers({ ...answers, applicationType: x as ApplicationTypeOption, otherApplicationType: undefined })}
                    />
                    {answers.applicationType === "Other (please specify)" ? <Text value={answers.otherApplicationType ?? ""} onChange={(x) => setAnswers({ ...answers, otherApplicationType: x })} label="Other application type" /> : null}
                </div>
                <div className={styles.question}>
                    <Select
                        label="Where do you build your application?"
                        highContrastMode={true}
                        value={answers.buildTool || ""}
                        sortItems={false}
                        items={buildTypeOptions.map((op) => ({ value: op, text: op }))}
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        onChange={(x) => setAnswers({ ...answers, buildTool: x as BuildTypeOption, otherBuildTool: undefined })}
                    />
                    {answers.buildTool === "Other (please specify)" ? <Text value={answers.otherBuildTool ?? ""} onChange={(x) => setAnswers({ ...answers, otherBuildTool: x })} label="Other build server" /> : null}
                </div>
                <div className={styles.question}>
                    <Select
                        label="Where is your packaged application stored?"
                        highContrastMode={true}
                        value={answers.packageLocation || ""}
                        sortItems={false}
                        items={packageLocationOptions.map((op) => ({ value: op, text: op }))}
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        onChange={(x) => setAnswers({ ...answers, packageLocation: x as PackageLocationOption, otherPackageLocation: undefined })}
                    />
                    {answers.packageLocation === "Other (please specify)" ? <Text value={answers.otherPackageLocation ?? ""} onChange={(x) => setAnswers({ ...answers, otherPackageLocation: x })} label="Other repository" /> : null}
                </div>
                <div className={styles.question}>
                    <Select
                        label="Where will your application be deployed?"
                        highContrastMode={true}
                        value={answers.deploymentLocation || ""}
                        sortItems={false}
                        items={deploymentLocationOptions.map((op) => ({ value: op, text: op }))}
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        onChange={(x) => setAnswers({ ...answers, deploymentLocation: x as DeploymentLocationOption, otherDeploymentLocation: undefined })}
                    />
                    {answers.deploymentLocation === "Other (please specify)" ? <Text value={answers.otherDeploymentLocation ?? ""} onChange={(x) => setAnswers({ ...answers, otherDeploymentLocation: x })} label="Other host" /> : null}
                </div>
                <Checkbox value={answers.runbookOnly ?? false} onChange={(x) => setAnswers({ ...answers, runbookOnly: x })} label="This is a Runbook-only project" />
            </QuestionnaireDialogLayout>
        </>
    );
}
