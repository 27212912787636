import type { ReportMetricsPageProps } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import { ReportMetricsLayout } from "app/areas/insights/components/Reports/ReportMetricsLayout/ReportMetricsLayout";
import cn from "classnames";
import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import type { InsightsReportResource } from "~/client/resources/insightsReportResource";
import InternalLink from "~/components/Navigation/InternalLink";
import routeLinks from "~/routeLinks";
import { InsightsTooltip } from "../../ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "../../Charts/LineChart";
import { InsightsChartCard } from "../../InsightsChartCard";
import { TrendIndicator } from "../TrendIndicator/TrendIndicator";
import styles from "./styles.module.less";

type Props = ReportMetricsPageProps;

function ReportOverviewInner({ bffResponse, report, cadence, busy }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    useEffect(() => {
        dispatchAction("View Insights Report Overview");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const chartData = getChartReadyInsightsData(bffResponse.Series);

    const { LeadTimeKey, MeanTimeToRecoveryKey } = getYAxisUnitOfTimeKeys(chartData);

    const currentGranularity = insightsCadenceLookup[cadence].granularity;

    const dateFormatter = getChartAxisDateFormatter(currentGranularity);

    const commonChartProps = {
        xAxisDataKey: "StartOfInterval",
        xAxisType: "category",
        yAxisType: "number",
        xTickFormatter: dateFormatter,
        showLegend: false,
        xAxisTickInterval: insightsCadenceXAxisTickInterval[cadence],
    } as const;

    const frequencyYAxisMax = getYAxisDataMax(chartData, "NumberOfSuccessfulDeployments");
    const leadTimeYAxisMax = getYAxisDataMax(chartData, `LeadTime.mean.${LeadTimeKey}`);
    const timeToRecoveryYAxisMax = getYAxisDataMax(chartData, `MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`);

    const reportLinks = routeLinks.insights.report(report.Id);

    return (
        <div className={styles.content}>
            <h2 className={styles.groupHeading}>Tempo</h2>
            <div className={cn(styles.twoColumnGrid, styles.tempoGrid)}>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={reportLinks.frequency}>
                            <h3 className={styles.title}>Deployment frequency</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.DeploymentFrequency.OverallTrend} trendKey="DeploymentFrequency" />
                    </div>
                    <InsightsChartCard>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey="NumberOfSuccessfulDeployments"
                            yAxisLabel="No. of deployments"
                            tooltip={<InsightsTooltip dataKey="NumberOfSuccessfulDeployments" valueFormatter={(v) => `${v.toLocaleString()} deployment${v === 1 ? "" : "s"}`} cadence={cadence} showSeries />}
                            yAxisDomain={[0, frequencyYAxisMax]}
                            yAxisTickArray={getEvenlySpacedTickArray(frequencyYAxisMax)}
                            {...commonChartProps}
                        />
                    </InsightsChartCard>
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={reportLinks.leadTime}>
                            <h3 className={styles.title}>Deployment lead time</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.LeadTime.OverallTrend} trendKey="LeadTime" />
                    </div>
                    <InsightsChartCard>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey={`LeadTime.mean.${LeadTimeKey}`}
                            yAxisLabel={`Avg. no. of ${LeadTimeKey.toLowerCase()}`}
                            tooltip={<InsightsTooltip dataKey="LeadTime.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} showSeries />}
                            yAxisDomain={[0, leadTimeYAxisMax]}
                            yAxisTickArray={getEvenlySpacedTickArray(leadTimeYAxisMax)}
                            {...commonChartProps}
                        />
                    </InsightsChartCard>
                </div>
            </div>
            <h2 className={styles.groupHeading}>Stability</h2>
            <div className={styles.twoColumnGrid}>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={reportLinks.failureRate}>
                            <h3 className={styles.title}>Deployment failure rate</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.ChangeFailureRate.OverallTrend} trendKey="ChangeFailureRate" />
                    </div>
                    <InsightsChartCard>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey="DeploymentFailureRate.ComputedFailureRate"
                            yAxisLabel="Failure rate (%)"
                            yAxisDomain={[0, 100]}
                            yAxisTickArray={getEvenlySpacedTickArray(100)}
                            tooltip={<InsightsTooltip dataKey="DeploymentFailureRate.ComputedFailureRate" valueFormatter={(v) => `${v}%`} cadence={cadence} showSeries />}
                            {...commonChartProps}
                        />
                    </InsightsChartCard>
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={reportLinks.timeToRecovery}>
                            <h3 className={styles.title}>Mean time to recovery</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.MeanTimeToRecovery.OverallTrend} trendKey="MeanTimeToRecovery" />
                    </div>
                    <InsightsChartCard>
                        <LineChart
                            data={chartData}
                            loading={Boolean(busy)}
                            dataKey={`MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`}
                            yAxisLabel={`Avg. no. of ${MeanTimeToRecoveryKey.toLowerCase()}`}
                            tooltip={<InsightsTooltip dataKey="MeanTimeToRecovery.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence} showSeries />}
                            yAxisDomain={[0, timeToRecoveryYAxisMax]}
                            yAxisTickArray={getEvenlySpacedTickArray(timeToRecoveryYAxisMax)}
                            {...commonChartProps}
                        />
                    </InsightsChartCard>
                </div>
            </div>
        </div>
    );
}

export function ReportOverview({ report }: { report: InsightsReportResource }) {
    return (
        <ReportMetricsLayout title="Overview" report={report}>
            {(props) => <ReportOverviewInner {...props} />}
        </ReportMetricsLayout>
    );
}
