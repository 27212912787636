import * as React from "react";
import { ProcessType } from "~/client/resources";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import { ProjectContextPaperLayout } from "./ProjectContextPaperLayout";

interface ProcessPaperLayoutProps extends PaperLayoutProps {
    processType: ProcessType;
}

export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ disableAnimations = true, children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (
            <RunbooksPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>
        );
    } else {
        return (
            <ProjectContextPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </ProjectContextPaperLayout>
        );
    }
};
