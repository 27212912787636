/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable custom-portal-rules/no-restricted-imports */

import { Card } from "@material-ui/core";
import { isEqual } from "lodash";
import * as React from "react";
import type { ReactNode } from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { MachineResource, WorkerPoolsSummaryResource, WorkerPoolResource, WorkerMachineResource, ResourceCollection } from "~/client/resources";
import { TaskRestrictedTo } from "~/client/resources";
import Permission from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import FormPage from "~/components/FormPage/FormPage";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { withTheme } from "~/components/Theme";
import { CardTitle } from "~/components/form/Sections";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import MachineRow from "../MachineRow/MachineRow";
import type { WorkerPoolsSummaryFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { defaultWorkerPoolsSummaryFilter, createWorkerPoolListWorkerArgs } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import BaseAllMachinesSummary from "./BaseAllMachinesSummary";
import type { HealthStatusRecord, BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState } from "./BaseAllMachinesSummary";
import { createMachineHealthMap } from "./MachineFilter";
import styles from "./style.module.less";

interface WorkerMachinesSummarySectionProps extends BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    workerPools: WorkerPoolResource[];
    filter: WorkerPoolsSummaryFilter;
}

interface WorkerMachinesSummarySectionInnerProps extends WorkerMachinesSummarySectionProps {
    initialData: InitialData;
}

interface InitialData {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup: HealthStatusRecord<MachineResource>;
    endpointRegistrations: EndpointRegistration[];
}

const WorkerMachinesSummaryFormPage = FormPage<InitialData>();
const WorkerMachinesSummarySection: React.FC<WorkerMachinesSummarySectionProps> = (props: WorkerMachinesSummarySectionProps) => {
    return (
        <WorkerMachinesSummaryFormPage
            title={"Workers"}
            load={async () => {
                const machineRequestArgs = createWorkerPoolListWorkerArgs(defaultWorkerPoolsSummaryFilter, null, true);
                const machinesResponse = await repository.Workers.list(machineRequestArgs);
                const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, repository.takeDefaultPageSize);
                const endpointRegistrations = endpointRegistry.getAllRegistrations();

                return { machinesResponse, machineHealthStatusFastLookup, endpointRegistrations: await endpointRegistrations };
            }}
            renderWhenLoaded={(data) => <WorkerMachinesSummarySectionInner initialData={data} {...props} />}
        />
    );
};

class WorkerMachinesSummarySectionInner extends BaseAllMachinesSummary<MachineResource, WorkerMachinesSummarySectionInnerProps, WorkerPoolsSummaryFilter, BaseAllMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: WorkerMachinesSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
            endpointRegistrations: this.props.initialData.endpointRegistrations,
        };
    }

    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }

    UNSAFE_componentWillReceiveProps(nextProps: BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter>) {
        if (!isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const workerPoolsSummary = this.props.workerPoolsSummary;
        const machineStatusesLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(workerPoolsSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(workerPoolsSummary);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];

        const componentKey = "allMachines";
        const overflowMenuItems = [];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (workerPoolsSummary.TotalMachines > 0) {
            const machineIds = this.state.machinesResponse && this.state.machinesResponse.Items.map((x) => x.Id);
            overflowMenuItems.push(
                OverflowMenuItems.item(`Check Health for ${machineIds && machineIds.length} Worker${machineIds && machineIds.length === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.Workers, machineIds), {
                    permission: Permission.WorkerEdit,
                    wildcard: true,
                })
            );
            const tentacleIds = workerPoolsSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"}`, () => this.performTentacleUpgrade(TaskRestrictedTo.Workers, machineIds), {
                        permission: Permission.WorkerEdit,
                        wildcard: true,
                    })
                );
            }
            const calamariIds = workerPoolsSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Worker${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnWorkers(calamariIds), {
                        permission: Permission.WorkerEdit,
                        wildcard: true,
                    })
                );
            }
        }
        const titleContainer = (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme((theme) => (
                        <OctopusIcon iconType={OctopusIconType.Worker} color={theme.iconDark} />
                    ))}
                </div>
                <div className={styles.environmentName}>Workers</div>
                <div className={styles.environmentMachinesCount}>({workerPoolsSummary.TotalMachines && workerPoolsSummary.TotalMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        );

        return (
            <PaperLayout key={componentKey} busy={this.state.busy} errors={this.errors} className={styles.paperLayoutOverride}>
                <Card className={styles.formExpander}>
                    <CardTitle title={titleContainer} />
                    <div className={styles.cardMedia}>{this.renderMachinesList(workerPoolsSummary)}</div>
                </Card>
            </PaperLayout>
        );
    }

    protected async loadData() {
        const machineArgs = createWorkerPoolListWorkerArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        const promises = Promise.all([repository.Workers.list(machineArgs), endpointRegistry.getAllRegistrations()]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([machinesResponse, endpointRegistrations]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }

    protected renderMachine(machine: MachineResource, needsUpgrading: boolean = false): ReactNode {
        return <MachineRow registrations={this.state.endpointRegistrations} machine={machine as WorkerMachineResource} workerPools={this.props.workerPools} needsUpgrading={needsUpgrading} />;
    }
}

export default WorkerMachinesSummarySection;
