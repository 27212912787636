import React from "react";
import { InsightsEarlyAccessCallout } from "~/areas/insights/components/InsightsEarlyAccessCallout";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";

interface Props {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
}

export function InsightsEapFeature({ isEnabled, onChange }: Props) {
    return (
        <ExpandableFormSection key="IsInsightsEapEnabled" errorKey="IsInsightsEapEnabled" title="Insights" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Enable/Disable Insights">
            <InsightsEarlyAccessCallout />
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" isDefault />
                <RadioButton value={false} label="Disabled" />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}
