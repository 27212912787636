import React from "react";
import { CircleIcon } from "./CircleIcon";
import { GreenTickIcon } from "./GreenTickIcon";
import type { TaskState } from "./ProjectStatus";

export const ProjectStatusIcon = ({ state }: { state: TaskState }): JSX.Element => {
    if (state === "Current") return <CircleIcon active={true} />;
    if (state === "Pending") return <CircleIcon active={false} />;
    if (state === "Done") return <GreenTickIcon />;
    return <></>;
};
