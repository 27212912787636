/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable custom-portal-rules/no-restricted-imports */

import { cloneDeep } from "lodash";
import * as React from "react";
import type { ProjectContextState } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import ProjectContextRepository from "~/client/repositories/projectContextRepository";
import type { ProjectResource, DeploymentStepResource, DeploymentActionResource, IProcessResource, RunbookResource } from "~/client/resources";
import { ProcessType } from "~/client/resources";
import SpecialVariables from "~/client/specialVariables";
import { repository, client } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import type { OverflowMenuNavLink, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuGenericItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems, OverflowMenuItemsRenderer } from "~/components/OverflowMenu/OverflowMenu";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import CloneStep, { isRunbookProcessCloneSource, isDeploymentsStepsCloneSource, CloneStepContextType } from "../../Process/Common/CloneStep";
import type { CloneSourceDefinition, CloneStepsSource, CloneRunbookProcessSource, CloneDeploymentProcessStepsSource } from "../../Process/Common/CloneStep";
import SelectParentStep from "../../Process/Common/SelectParentStep";
import { useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { processScopedEditPermission, deleteActionAndRedirect, getDeleteProcessMenuItem } from "../Common/CommonProcessHelpers";
import { useProcessContext } from "../Contexts/ProcessContext";
import type { ProcessContextProps } from "../Contexts/ProcessContext";
import type { BoundErrorActionsType } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessErrorActions } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import type { ProcessQueryStringContextProps } from "../Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessQueryStringContext } from "../Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { BoundWarningActionsType } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import { useProcessWarningActions } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import { generateGuid } from "../generation";
import type { StoredStep, StoredAction, Warnings } from "../types";
import StepSorter from "./ProcessListItemSorter";

export interface ProcessSearchFilter extends EditorFilter {
    channelId?: string;
}

export type RunbookProcessSearchFilter = EditorFilter;

export interface EditorFilter {
    filterKeyword: string;
    environmentId?: string;
    includeUnscoped?: boolean;
}

export interface DeploymentProcessEditorQuery {
    filterKeyword?: string;
    environmentId?: string;
    channelId?: string;
    includeUnscoped?: boolean;
}

export function superEncodeURI(value: string) {
    if (!value) {
        return null;
    }
    // encodeURIComponent doesn't encode (), which may be valid keywords.
    let encodedValue = encodeURIComponent(value);
    encodedValue = encodedValue.replace(/\(/g, "%28");
    encodedValue = encodedValue.replace(/\)/g, "%29");
    return encodedValue;
}

export function getDeploymentProcessQueryFromFilters(filter: ProcessSearchFilter): DeploymentProcessEditorQuery {
    return {
        ...filter,
        filterKeyword: filter.filterKeyword,
    };
}

export function getDeploymentProcessFilter(query: DeploymentProcessEditorQuery): ProcessSearchFilter {
    return {
        filterKeyword: query.filterKeyword || "",
        environmentId: query.environmentId || "",
        channelId: query.channelId || "",
        includeUnscoped: query.includeUnscoped,
    };
}

type OverflowMenuTypes = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem;

export interface StepsRenderProps {
    name: string;
    index: string;
    detailsUrl: string;
    menuItems: Array<OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem>;
    isParentGroup: boolean;
    isSelected: boolean;
    isChildAction: boolean;
    isPlaceholder: boolean;
    isRunInParallelWithLast: boolean;
    isDisabled: boolean;
    actionErrors: string[];
    actionId: string | undefined;
    parentStepId: string | undefined;
}

export interface StepContextMenuProps {
    step: StoredStep;
    action?: StoredAction;
    stepIndex?: number;
    actionIndex?: number;
    isChildAction?: boolean;
    isParentGroup?: boolean;
    isSelected?: boolean;
    keywordSearch?: string;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    render: (props: StepsRenderProps) => React.ReactElement;
}

interface StepsOverflowMenuOptions {
    project: Readonly<ProjectResource>;
    runbook: Readonly<RunbookResource> | undefined;
    step: Readonly<StoredStep>;
    action?: Readonly<StoredAction>;
    processContext: ProcessContextProps;
    errorActions: BoundErrorActionsType;
    warningActions: BoundWarningActionsType;
    processType: ProcessType;
    processQueryStringContext: ProcessQueryStringContextProps;
    isSelected: boolean;
    onClose: () => void;
}

function getOverflowItems(projectContext: ProjectContextState, options: StepsOverflowMenuOptions, isChildAction?: boolean): OverflowMenuTypes[] {
    const { action, step, project } = options;

    const { selectors } = options.processContext;
    const projectSlug = project && project.Slug;
    const projectId = project && project.Id;
    const menuItems = [];
    const processEditPermission = { permission: processScopedEditPermission(options.processType), project: projectId, wildcard: true };
    const { actions: contextActions, selectors: contextSelectors } = options.processContext;
    const { actions: queryStringActions } = options.processQueryStringContext;

    if (!isChildAction) {
        if (!canHaveChildren(options, step)) {
            menuItems.push(OverflowMenuItems.disabledItem("Add child step", "This step type does not support child steps"));
        } else {
            menuItems.push(OverflowMenuItems.navItem("Add child step", contextSelectors.getAddChildStepUrl(projectSlug, projectContext.gitRef, step.Id), processEditPermission));
        }
    }

    if (action) {
        const isDisabled = contextSelectors.isActionDisabled(action.Id);
        menuItems.push(OverflowMenuItems.item(isDisabled ? "Enable" : "Disable", () => (isDisabled ? enable(options, action) : disable(options, action)), processEditPermission));
    }

    if (!action && canHaveChildren(options, step)) {
        menuItems.push(OverflowMenuItems.item("Enable all", () => enableAll(options, step), processEditPermission));
        menuItems.push(OverflowMenuItems.item("Disable all", () => disableAll(options, step), processEditPermission));
    }

    if (action && selectors.isChildAction(action.Id)) {
        menuItems.push(OverflowMenuItems.item("Move out", () => options.processContext.actions.moveActionOutOfStep(action.Id), processEditPermission));
    }

    const numberOfActions = contextSelectors.getAllActions().length;
    if (action && numberOfActions > 1) {
        if (!options.processContext.selectors.canStepBeChild(step.Id)) {
            menuItems.push(OverflowMenuItems.disabledItem("Move into...", "No steps available that can have children or this step type cannot be a child step."));
        } else {
            const steps = contextSelectors.getAllSteps();
            const stepsToMoveInto = steps.filter((s) => s.Id !== step.Id && canHaveChildren(options, s));
            const selectParentStep = (
                <SelectParentStep
                    steps={stepsToMoveInto}
                    actionName={action.Name}
                    currentlyTargetedRoles={step.Properties[SpecialVariables.Action.TargetRoles] as string}
                    onStepSelected={(parentStepId) => {
                        //We need to close the menu in this particular case as the element the menu would be referring to
                        //would no longer be a valid target, but would still be defined. If we did not close the menu we
                        //would have some janky behavior and the menu would go flying to the top left corner. Ideally
                        //we wouldn't have dialogs in the menus at all which would remove much of the need to keep these
                        //menus open in the first place.
                        options.onClose();
                        options.processContext.actions.moveActionIntoStep(action.Id, parentStepId);
                    }}
                />
            );
            menuItems.push(OverflowMenuItems.dialogItem("Move into...", selectParentStep, processEditPermission));
        }
    }

    const cloneStepContext = {
        getRunbookProcess: (source: CloneRunbookProcessSource) => {
            return repository.RunbookProcess.get(source.runbook.RunbookProcessId);
        },
        getDeploymentProcess: (source: CloneDeploymentProcessStepsSource) => new ProjectContextRepository(client, source.project, undefined).DeploymentProcesses.get(),
        getProcessResource: options.processContext.selectors.getProcessResource,
        getActionResource: options.processContext.selectors.getActionResource,
        getStepResource: options.processContext.selectors.getStepResource,
        setProcess: options.processContext.actions.setProcess,
        saveOnServer: options.processContext.actions.saveOnServer,
        setErrors: (errors: Errors) => options.errorActions.setErrors(errors, options.processContext.selectors),
        clearErrors: options.errorActions.clearErrors,
        setWarnings: (warnings: Warnings) => options.warningActions.setWarnings(warnings, options.processContext.selectors),
        clearWarnings: options.warningActions.clearWarnings,
    };

    const cloneStepAction = (
        <CloneStep
            stepId={options.step.Id}
            actionId={options.action && options.action.Id}
            currentRunbook={options.runbook}
            actionName={action ? action.Name : step.Name}
            currentProject={project}
            onCloneTargetSelected={(definition) => CloneStepAction(cloneStepContext, definition, step, action)}
        />
    );

    if (!project.IsVersionControlled || options.processType == ProcessType.Runbook) menuItems.push(OverflowMenuItems.dialogItem("Clone...", cloneStepAction, processEditPermission));

    menuItems.push(
        getDeleteProcessMenuItem(
            !!action ? "step" : "parent step",
            () => {
                deleteActionAndRedirect(step, action, options.isSelected, contextActions, contextSelectors, queryStringActions);
            },
            processEditPermission,
            project,
            step,
            action
        )
    );

    if (!action && contextSelectors.hasValidProcess()) {
        menuItems.push(
            OverflowMenuItems.dialogItem(
                "Reorder child steps",
                <StepSorter
                    initialItems={contextSelectors.getChildActions(step.Id)}
                    title={"Reorder child steps"}
                    onComplete={(ordered) => {
                        contextActions.reorderChildActions(
                            step.Id,
                            ordered.map((x) => x.Id)
                        );
                    }}
                />,
                processEditPermission
            )
        );
    }
    return menuItems;
}

function canHaveChildren(options: StepsOverflowMenuOptions, step: StoredStep) {
    const { processContext } = options;
    return processContext.selectors.canStepHaveChildren(step.Id);
}

async function disableAll(options: StepsOverflowMenuOptions, step: StoredStep) {
    options.processContext.actions.disableStep(step.Id);
}

async function enableAll(options: StepsOverflowMenuOptions, step: StoredStep) {
    options.processContext.actions.enableStep(step.Id);
}

function newStepResourceFromStep(step: Readonly<StoredStep>, newActionResource: DeploymentActionResource) {
    return {
        Id: generateGuid(),
        Name: newActionResource.Name,
        PackageRequirement: step.PackageRequirement,
        Properties: step.Properties,
        Condition: step.Condition,
        StartTrigger: step.StartTrigger,
        Actions: [newActionResource],
        Links: {},
    };
}

async function enable(options: StepsOverflowMenuOptions, action: StoredAction) {
    const { actions: contextActions } = options.processContext;
    contextActions.enableAction(action.Id);
}

async function disable(options: StepsOverflowMenuOptions, action: { Id: string }) {
    const { actions: contextActions } = options.processContext;
    contextActions.disableAction(action.Id);
}

export type CloneStepContext = {
    getRunbookProcess: (source: CloneRunbookProcessSource) => Promise<IProcessResource>;
    getDeploymentProcess: (source: CloneDeploymentProcessStepsSource) => Promise<IProcessResource>;
    getProcessResource: () => Readonly<IProcessResource>;
    getActionResource: (actionId: string) => DeploymentActionResource;
    getStepResource: (stepId: string) => DeploymentStepResource;
    setProcess: (process: IProcessResource, updateCleanModel: boolean) => Promise<void>;
    saveOnServer: (projectContextRepository: ProjectContextRepository, process: IProcessResource, onError: (errors: Errors) => void, onSuccess: () => void) => Promise<IProcessResource | null>;
    setErrors: (errors: Errors) => void;
    clearErrors: () => void;
};

export const CloneStepAction = async (context: CloneStepContext, definition: CloneSourceDefinition, step: Readonly<StoredStep>, action?: Readonly<StoredAction>): Promise<IProcessResource> => {
    const getProjectContextRepository = (source: CloneStepsSource) => {
        return new ProjectContextRepository(client, source.project, undefined);
    };

    const getProcess = (source: CloneStepsSource): Promise<IProcessResource> => {
        if (definition.targetType === CloneStepContextType.CurrentContext) {
            return Promise.resolve(context.getProcessResource());
        } else {
            if (isRunbookProcessCloneSource(source)) {
                return context.getRunbookProcess(source);
            } else if (isDeploymentsStepsCloneSource(source)) {
                return context.getDeploymentProcess(source);
            } else {
                throw new Error("Failed to find process");
            }
        }
    };

    const stepNameExists = (steps: DeploymentStepResource[], stepName: string) => {
        return steps.filter((s) => s.Name === stepName).length > 0;
    };

    const nameOfActionExists = (steps: DeploymentStepResource[], actionName: string) => {
        return steps.filter((s) => s.Name === actionName || s.Actions.filter((a) => a.Name === actionName).length > 0).length > 0;
    };

    const getNewActionNameForResource = (steps: DeploymentStepResource[], clonedAction: DeploymentActionResource) => {
        let suffix = "";
        let counter = 1;
        while (nameOfActionExists(steps, clonedAction.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedAction.Name + suffix;
    };

    const getNewStepNameForResource = (steps: DeploymentStepResource[], clonedStep: DeploymentStepResource) => {
        let suffix = "";
        let counter = 1;
        while (stepNameExists(steps, clonedStep.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedStep.Name + suffix;
    };

    const targetProcess = await getProcess(definition.target);
    if (!targetProcess) {
        throw new Error("Failed to find targetProcess");
    }

    if (action && step.ActionIds.length > 1 && definition.targetType !== CloneStepContextType.CurrentContext) {
        // Child step action being cloned to a different context
        const newStep = newStepResourceFromStep(step, context.getActionResource(action.Id));
        newStep.Actions[0].Id = generateGuid();
        newStep.Actions[0].Name = getNewActionNameForResource(targetProcess.Steps, newStep.Actions[0]);
        newStep.Actions[0].Channels = [];
        targetProcess.Steps.splice(targetProcess.Steps.length, 0, newStep);
    } else if (action && step.ActionIds.length > 1 && definition.targetType === CloneStepContextType.CurrentContext) {
        // It's a child step being cloned within the same step.
        const clonedAction: StoredAction = cloneDeep(action);
        clonedAction.Id = generateGuid();
        clonedAction.Name = getNewActionNameForResource(targetProcess.Steps, clonedAction);

        const targetStep = targetProcess.Steps.find((x) => x.Id === step.Id);
        const actionIndex = step.ActionIds.indexOf(action.Id);
        if (actionIndex === -1) {
            targetStep!.Actions.splice(step.ActionIds.length, 0, clonedAction);
        } else {
            targetStep!.Actions.splice(actionIndex + 1, 0, clonedAction);
        }
    } else {
        // It's a step being cloned (either to the same project or a different project).
        const clonedStepResource = { ...context.getStepResource(step.Id) };
        clonedStepResource.Id = generateGuid();
        clonedStepResource.Name = getNewStepNameForResource(targetProcess.Steps, clonedStepResource);
        clonedStepResource.Actions.forEach((a, index) => {
            clonedStepResource.Actions[index].Id = generateGuid();
            clonedStepResource.Actions[index].Name = getNewActionNameForResource(targetProcess.Steps, clonedStepResource.Actions[index]);
            clonedStepResource.Actions[index].Channels = [];
        });

        const stepIndex = targetProcess.Steps.findIndex((x) => x.Id === step.Id);
        if (stepIndex === -1) {
            targetProcess.Steps.splice(targetProcess.Steps.length, 0, clonedStepResource);
        } else {
            targetProcess.Steps.splice(stepIndex + 1, 0, clonedStepResource);
        }
    }

    // TODO: Tease this function apart into two streams (CurrentContext vs not), then we can isolate these things within the context without fully stomping it.
    if (definition.targetType === CloneStepContextType.CurrentContext) {
        // Stomp the whole process in context with the new version.
        await context.setProcess(targetProcess, false);
    } else {
        await context.saveOnServer(
            getProjectContextRepository(definition.target),
            targetProcess,
            (errors) => context.setErrors(errors),
            () => context.clearErrors()
        );
    }

    return targetProcess;
};

export class ParentStepContextMenuTarget {
    static create(element: Element, stepId: string): Readonly<ParentStepContextMenuTarget> {
        return new ParentStepContextMenuTarget(element, stepId);
    }

    element: Element;
    stepId: string;

    private constructor(element: Element, stepId: string) {
        this.element = element;
        this.stepId = stepId;
    }
}

export class ActionContextMenuTarget {
    static create(element: Element, actionId: string): Readonly<ActionContextMenuTarget> {
        return new ActionContextMenuTarget(element, actionId);
    }

    element: Element;
    actionId: string;

    private constructor(element: Element, actionId: string) {
        this.element = element;
        this.actionId = actionId;
    }
}

export const NoContextMenuTarget = "None";

export type ContextMenuTarget = ParentStepContextMenuTarget | ActionContextMenuTarget | typeof NoContextMenuTarget;

export const ProcessListItemContextMenu: React.FC<{ target: ContextMenuTarget; open: boolean; onRequestClose: () => void; project: ProjectResource }> = (props) => {
    const processContext = useProcessContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const { selectors } = processContext;
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const processQueryStringContext = useProcessQueryStringContext();
    const {
        state: { queryFilter },
    } = processQueryStringContext;

    const targetExists = (props.target instanceof ActionContextMenuTarget && selectors.hasAction(props.target.actionId)) || (props.target instanceof ParentStepContextMenuTarget && selectors.hasStep(props.target.stepId));

    if (props.target === NoContextMenuTarget || !props.target.element || !targetExists) {
        return null;
    }

    const element = props.target.element;

    const step = props.target instanceof ParentStepContextMenuTarget ? selectors.getStepById(props.target.stepId) : selectors.getActionParentStep(props.target.actionId);
    const action = props.target instanceof ActionContextMenuTarget ? selectors.getActionById(props.target.actionId) : undefined;

    const overflowDefinitions = getOverflowItems(projectContext.state, {
        isSelected: props.target instanceof ActionContextMenuTarget ? queryFilter.actionId === props.target.actionId : queryFilter.parentStepId === props.target.stepId,
        processContext,
        errorActions,
        warningActions,
        processQueryStringContext,
        project: projectContext.state.model,
        runbook: runbookContext?.state.runbook,
        processType: processContext.selectors.getProcessType(),
        step,
        action,
        onClose: props.onRequestClose,
    });

    return (
        <OverflowMenuItemsRenderer onClose={props.onRequestClose} menuItems={overflowDefinitions}>
            {({ dialogs, convertedMenuItems }) => (
                <React.Fragment>
                    {dialogs}
                    <CustomMenu accessibleName={`Overflow for ${action?.Name ?? step.Name}`} anchorElement={element} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} onClose={props.onRequestClose} isOpen={props.open} menuId={"overflow"}>
                        {convertedMenuItems}
                    </CustomMenu>
                </React.Fragment>
            )}
        </OverflowMenuItemsRenderer>
    );
};
