import type { NamedResource, SpaceScopedResource } from ".";

interface InsightsReportLinks {
    Self: string;
}

export interface InsightsEnvironmentGroup {
    Id: string;
    Name: string;
    Environments: string[];
}

export interface NewInsightsReportResource {
    Name: string;
    Description: string;
}

export enum InsightsReportTenantMode {
    Untenanted = "Untenanted",
    Tenanted = "Tenanted",
    TenantedAndUntenanted = "TenantedAndUntenanted",
}

export interface InsightsReportResource extends NamedResource<InsightsReportLinks>, SpaceScopedResource {
    SpaceId: string;
    Description: string;
    ProjectIds: string[];
    ProjectGroupIds: string[];
    TenantIds: string[];
    EnvironmentGroups: InsightsEnvironmentGroup[];
    TenantTags: string[];
    TenantMode: InsightsReportTenantMode;
    IconId?: string;
    IconColor?: string;
    TimeZone: string;
    LastModifiedOn?: string | null;
    LastModifiedBy?: string | null;
}
