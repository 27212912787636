import amplitude from "amplitude-js";
import { anonymizeString } from "~/analytics/anonymizeString";
import { getServerHost } from "~/client/utils";
import { getTutorialPackageExperimentVariation } from "~/components/GettingStarted/gettingStartedExperiments";
import Environment from "~/environment";
import type { ThemePaletteType } from "~/theme/index";
import { logger } from "~/utils/logging/logger";
import type { AnalyticFields, AnalyticSession } from "./AnalyticSession";

export class AmplitudeSession implements AnalyticSession {
    private api: amplitude.AmplitudeClient;

    public constructor(private readonly installationId: string, userId: string, initialTheme: ThemePaletteType) {
        this.api = amplitude.getInstance();
        this.api.options.apiEndpoint = `${getServerHost()}/api/telemetry/process`;
        this.api.options.forceHttps = !Environment.isInDevelopmentMode();
        // The real API token is injected in the Telemetry Proxy before forwarding to Amplitude, but Amplitude.JS requires one anyway.
        this.api.init("dummy-token");
        this.api.setUserId(userId);

        // Setting the user properties to indicate which variations of experiments they are seeing (https://help.amplitude.com/hc/en-us/articles/115001580108-Analyze-A-B-test-results-in-Amplitude)
        this.api.setUserProperties({ "user-onboarding-experiments": [getTutorialPackageExperimentVariation(userId)], Theme: initialTheme });
    }

    track(name: string, event: AnalyticFields): void {
        this.api.logEvent(name, { ...event });
    }

    end() {
        try {
            this.api.setUserId(null);
            this.api.regenerateDeviceId();
        } catch (err) {
            logger.error(err, "(Amplitude) error ending session");
        }
    }

    setTheme(theme: ThemePaletteType) {
        this.api.setUserProperties({ Theme: theme });
    }

    anonymize(value: string): string {
        return anonymizeString(`${this.installationId}-${value}`);
    }
}
