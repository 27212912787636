import type { LogEvent } from "~/utils/logging/LogEvent";

export function createBufferingSink() {
    let logEventQueue: LogEvent[] = [];
    let target: BufferingSinkTarget = unconfiguredTarget;

    setInterval(() => {
        if (target.isEnabled() === true && logEventQueue.length > 0) {
            const logEventsToSend = logEventQueue;
            logEventQueue = [];
            // A future optimisation here would be to not send all events in one single request, but limit the batch size
            target.receiveLogEvents(logEventsToSend);
        }
    }, 1000);

    return {
        receiveLogEvent(logEvent: LogEvent) {
            if (target.isEnabled() !== false) {
                // If the enabled state is indeterminate, continue to accrue events just in case
                logEventQueue.push(logEvent);
            }
        },
        setTarget(newTarget: BufferingSinkTarget) {
            // noinspection ReuseOfLocalVariableJS
            target = newTarget;
        },
    };
}

const unconfiguredTarget: BufferingSinkTarget = {
    isEnabled: () => false,
    receiveLogEvents: () => {
        throw new Error("An unconfigured buffering sink target should never receive events");
    },
};

export interface BufferingSinkTarget {
    isEnabled: () => boolean | "indeterminate";
    receiveLogEvents: (logEvents: LogEvent[]) => void;
}
