import React, { useState } from "react";
import { Action, useAnalyticCloudConnectionsDispatch } from "~/analytics/Analytics";
import { CloudConnectionsDrawer } from "~/areas/projects/components/CloudConnection/CloudConnectionDrawer";
import { getVariable, setVariableValue } from "~/areas/projects/components/CloudConnection/CloudConnectionUtilities";
import { VariableNames } from "~/areas/projects/components/CloudConnection/CloudConnectionVariables";
import { CloudConnectionUsageType } from "~/areas/projects/components/CloudConnection/CloudConnections";
import type { CloudConnectionItem } from "~/areas/projects/components/CloudConnection/CloudConnections";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type AccountResource from "~/client/resources/accountResource";
import type { ProjectResource } from "~/client/resources/index";
import { AccountType } from "~/client/resources/index";
import { VariableType } from "~/client/resources/variableResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import type { GitRef } from "~/client/resources/versionControlledResource";
import { repository } from "~/clientInstance";
import DeletableChip from "~/components/Chips/DeletableChip";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import { FormSection } from "~/components/form/Sections/index";
import { FormSectionHeading, Note, RadioButton, RadioButtonGroup } from "~/components/form/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import Divider from "~/primitiveComponents/dataDisplay/Divider/index";
import Text from "~/primitiveComponents/form/Text/Text";

const RegionMultiSelect = MultiSelect<SelectItem>();
const knownRegions: SelectItem[] = [
    { Id: "us-east-2", Name: "us-east-2 (US East - Ohio)" },
    { Id: "us-east-1", Name: "us-east-1 (US East - N. Virginia)" },
    { Id: "us-west-1", Name: "us-west-1 (US West - N. California)" },
    { Id: "us-west-2", Name: "us-west-2 (US West - Oregon)" },
    { Id: "af-south-1", Name: "af-south-1 (Africa - Cape Town)" },
    { Id: "ap-east-1", Name: "ap-east-1 (Asia Pacific - Hong Kong)" },
    { Id: "ap-southeast-3", Name: "ap-southeast-3 (Asia Pacific - Jakarta)" },
    { Id: "ap-south-1", Name: "ap-south-1 (Asia Pacific - Mumbai)" },
    { Id: "ap-northeast-3", Name: "ap-northeast-3 (Asia Pacific - Osaka)" },
    { Id: "ap-northeast-2", Name: "ap-northeast-2 (Asia Pacific - Seoul)" },
    { Id: "ap-southeast-1", Name: "ap-southeast-1 (Asia Pacific - Singapore)" },
    { Id: "ap-southeast-2", Name: "ap-southeast-2 (Asia Pacific - Sydney)" },
    { Id: "ap-northeast-1", Name: "ap-northeast-1 (Asia Pacific - Tokyo)" },
    { Id: "ca-central-1", Name: "ca-central-1 (Canada - Central)" },
    { Id: "eu-central-1", Name: "eu-central-1 (Europe - Frankfurt)" },
    { Id: "eu-west-1", Name: "eu-west-1 (Europe - Ireland)" },
    { Id: "eu-west-2", Name: "eu-west-2 (Europe - London)" },
    { Id: "eu-south-1", Name: "eu-south-1 (Europe - Milan)" },
    { Id: "eu-west-3", Name: "eu-west-3 (Europe - Paris)" },
    { Id: "eu-north-1", Name: "eu-north-1 (Europe - Stockholm)" },
    { Id: "me-south-1", Name: "me-south-1 (Middle East - Bahrain)" },
    { Id: "sa-east-1", Name: "sa-east-1 (South America - São Paulo)" },
];

interface AwsCloudConnectionDrawerDetails {
    type: "AWS";
    credentialType: "Account" | "Worker";
    accountId?: string;
    assumeRole: boolean;
    arn?: string;
    sessionName?: string;
    sessionDuration?: string;
    externalId?: string;
    regions: string[];
    accountIdValidationMessage?: string;
    regionValidationMessage?: string;
    arnValidationMessage?: string;
}

export interface AwsCloudConnectionDrawerDetailsProps {
    cloudConnectionItem: CloudConnectionItem;
    variables: VariableSetResource | undefined;
    connectionType: CloudConnectionUsageType;
    saveVariables: (variableSet: VariableSetResource, commitMessage: CommitMessageWithDetails | undefined) => Promise<VariableSetResource>;
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
    refreshVariables: () => void;
    haveVariablesChanged: (currentVariables: VariableSetResource) => Promise<boolean>;
    project: ProjectResource;
    gitRef?: GitRef;
}

function getDrawerDetails(cloudConnection: CloudConnectionItem, variables: VariableSetResource | undefined): AwsCloudConnectionDrawerDetails {
    const details: AwsCloudConnectionDrawerDetails = { type: "AWS", credentialType: "Account", assumeRole: false, regions: [] };
    if (variables) {
        const account = getVariable(VariableNames.Aws.Account, variables);
        if (account?.Value) {
            details.accountId = account.Value;
        }
        const assumeRoleArn = getVariable(VariableNames.Aws.RoleArn, variables);
        if (assumeRoleArn?.Value) {
            details.assumeRole = true;
            details.arn = assumeRoleArn.Value;
        }
        const assumeRoleSessionName = getVariable(VariableNames.Aws.RoleSessionName, variables);
        if (assumeRoleSessionName?.Value) {
            details.sessionName = assumeRoleSessionName.Value;
        }
        const assumeRoleSessionDuration = getVariable(VariableNames.Aws.RoleSessionDuration, variables);
        if (assumeRoleSessionDuration?.Value) {
            details.sessionDuration = assumeRoleSessionDuration.Value;
        }
        const assumeRoleExternalId = getVariable(VariableNames.Aws.RoleExternalId, variables);
        if (assumeRoleExternalId?.Value) {
            details.externalId = assumeRoleExternalId.Value;
        }
        const region = getVariable(VariableNames.Aws.Regions, variables);
        if (region?.Value) {
            details.regions = region.Value.split(",");
        }
    }
    return details;
}

const AwsCloudConnectionDrawer: React.FC<AwsCloudConnectionDrawerDetailsProps> = (props) => {
    const [drawerDetails, setDrawerDetails] = useState<AwsCloudConnectionDrawerDetails>(getDrawerDetails(props.cloudConnectionItem, props.variables));
    const [variablesChanged, setVariablesChanged] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<AccountResource[]>([]);
    const dispatchAction = useAnalyticCloudConnectionsDispatch();

    const refreshAccounts = async (): Promise<void> => {
        const accountsToSet = await repository.Accounts.all();
        setAccounts(accountsToSet);
    };

    const validateDrawer = (): boolean => {
        let valid = true;

        let accountIdValidationMessage: string | undefined = undefined;
        let regionValidationMessage: string | undefined = undefined;
        let arnValidationMessage: string | undefined = undefined;

        if (drawerDetails.credentialType === "Account" && !drawerDetails.accountId) {
            valid = false;
            accountIdValidationMessage = "Account ID is required";
        }
        if (props.connectionType === CloudConnectionUsageType.TargetDiscovery && !(drawerDetails?.regions.length > 0)) {
            valid = false;
            regionValidationMessage = "Region is required for Target Discovery";
        }
        if (drawerDetails.assumeRole && !drawerDetails.arn) {
            valid = false;
            arnValidationMessage = "ARN is required when assume role is selected";
        }

        if (!valid) {
            setDrawerDetails({ ...drawerDetails, accountIdValidationMessage: accountIdValidationMessage, regionValidationMessage: regionValidationMessage, arnValidationMessage: arnValidationMessage });
        }

        return valid;
    };

    async function SaveDrawerDetails(commitMessage: CommitMessageWithDetails | undefined) {
        if (props.variables) {
            if (drawerDetails.accountId) {
                setVariableValue(props.variables, VariableNames.Aws.Account, drawerDetails.accountId, VariableType.AmazonWebServicesAccount);
            }
            if (drawerDetails.regions.length > 0) {
                setVariableValue(props.variables, VariableNames.Aws.Regions, drawerDetails.regions.join(", "));
            }
            if (drawerDetails.arn) {
                setVariableValue(props.variables, VariableNames.Aws.RoleArn, drawerDetails.arn);
            }

            if (drawerDetails.sessionName) {
                setVariableValue(props.variables, VariableNames.Aws.RoleSessionName, drawerDetails.sessionName);
            }

            if (drawerDetails.sessionDuration) {
                setVariableValue(props.variables, VariableNames.Aws.RoleSessionDuration, drawerDetails.sessionDuration);
            }

            if (drawerDetails.externalId) {
                setVariableValue(props.variables, VariableNames.Aws.RoleExternalId, drawerDetails.externalId);
            }

            dispatchAction("Save Cloud Connections Drawer", {
                action: Action.Save,
                resource: "Cloud Connections",
                cloudProvider: "Aws",
            });

            await props.saveVariables(props.variables, commitMessage);
        }
    }

    return (
        <CloudConnectionsDrawer
            actionName={`AWS Cloud Connection`}
            open={props.showDrawer}
            cancel={() => {
                dispatchAction(`Close Cloud Connections Drawer`, {
                    action: Action.Cancel,
                    resource: "Cloud Connections",
                    cloudProvider: "Aws",
                });
                props.setShowDrawer(false);
                setDrawerDetails(getDrawerDetails(props.cloudConnectionItem, props.variables));
                if (variablesChanged) {
                    props.refreshVariables();
                }
                setVariablesChanged(false);
            }}
            okDisabled={variablesChanged}
            onSubmit={async (commitMessage) => {
                if (drawerDetails && props.variables) {
                    if (!validateDrawer()) return;
                    const varChanged = await props.haveVariablesChanged(props.variables);
                    setVariablesChanged(varChanged);
                    if (varChanged) {
                        return;
                    }
                    await SaveDrawerDetails(commitMessage);
                }

                props.setShowDrawer(false);
                setDrawerDetails(getDrawerDetails(props.cloudConnectionItem, props.variables));
            }}
            onLoad={refreshAccounts}
            setShowDrawer={props.setShowDrawer}
            project={props.project}
            gitRef={props.gitRef}
        >
            <Divider />
            {variablesChanged ? (
                <Callout title="Unable to save your Cloud Connection" type={CalloutType.Danger}>
                    Changes to the Cloud Connection could not be saved, because another user has made changes to the variables between when you started editing and when you saved your changes. Please close the drawer to reload Cloud Connections.
                </Callout>
            ) : (
                <></>
            )}
            <FormSection title="Credentials" help="Select how you want to supply credentials">
                <RadioButtonGroup
                    disabled={variablesChanged}
                    value={drawerDetails.credentialType}
                    onChange={(s: "Account" | "Worker" | undefined) => {
                        if (s && drawerDetails) {
                            setDrawerDetails({ ...drawerDetails, credentialType: s });
                        }
                    }}
                    accessibleName={"The AWS credential type to use for your cloud connection"}
                >
                    <RadioButton value={"Account"} label="Supply the AWS Key and Secret (easiest)" disabled={variablesChanged} />
                    <Note>This utilises an Octopus Account.</Note>
                    <RadioButton value={"Worker"} label="Use credentials from the worker (best practice)" disabled={variablesChanged} />
                    <Note>Where the worker is an EC2 instance, Octopus can assume the IAM role from the instance (most secure). This utilises a Worker Pool.</Note>
                </RadioButtonGroup>
            </FormSection>

            {drawerDetails.credentialType === "Account" && (
                <>
                    <Divider />
                    <FormSection title="Account" help="Select or connect your AWS account">
                        <AccountSelect
                            disabled={variablesChanged}
                            onRequestRefresh={refreshAccounts}
                            type={[AccountType.AmazonWebServicesAccount]}
                            value={drawerDetails.accountId ?? ""}
                            warning={drawerDetails.accountIdValidationMessage}
                            allowClear={true}
                            onChange={(accountId) => {
                                setDrawerDetails({ ...drawerDetails, accountId: accountId });
                            }}
                            items={accounts}
                        />
                    </FormSection>
                </>
            )}
            <FormSectionHeading title="IAM Roles" />
            <FormSection title="Assume a role" help="Assume a different AWS service role?">
                <RadioButtonGroup
                    disabled={variablesChanged}
                    value={drawerDetails.assumeRole ?? false}
                    onChange={(assume: boolean) => {
                        if (drawerDetails) {
                            setDrawerDetails({ ...drawerDetails, assumeRole: assume });
                        }
                    }}
                    accessibleName={"Aws Assume A Role"}
                >
                    <RadioButton value={false} label="No" disabled={variablesChanged} />
                    <RadioButton value={true} label="Yes" disabled={variablesChanged} />
                </RadioButtonGroup>
                {drawerDetails?.assumeRole ? (
                    <>
                        <Text label="ARN" value={drawerDetails.arn ?? ""} onChange={(text) => setDrawerDetails({ ...drawerDetails, arn: text })} error={drawerDetails.arnValidationMessage} disabled={variablesChanged} accessibleName={"Aws Role Arn"} />
                        <Text label="Session Name" value={drawerDetails.sessionName ?? ""} onChange={(text) => setDrawerDetails({ ...drawerDetails, sessionName: text })} disabled={variablesChanged} accessibleName={"Aws Role Session Name"} />
                        <Text
                            label="Session duration (in seconds)"
                            value={drawerDetails.sessionDuration ?? ""}
                            onChange={(text) => setDrawerDetails({ ...drawerDetails, sessionDuration: text })}
                            type={"number"}
                            disabled={variablesChanged}
                            accessibleName={"Aws Role Session Duration"}
                        />
                        <Note>If blank, defaults to 3600 seconds (1 hour).</Note>
                        <Text label="External ID" value={drawerDetails.externalId ?? ""} onChange={(text) => setDrawerDetails({ ...drawerDetails, externalId: text })} disabled={variablesChanged} accessibleName={"Aws Role External Id"} />
                    </>
                ) : (
                    <></>
                )}
            </FormSection>

            <Divider />
            <FormSection title="Regions" help="Specify the regions where your targets will be discovered">
                <RegionMultiSelect
                    disabled={variablesChanged}
                    value={drawerDetails.regions ?? []}
                    items={knownRegions}
                    label="Regions"
                    // Prevent address autocomplete which would be triggered because label is called Region
                    autoComplete="no"
                    addNewTemplate={(t) => `New region code '${t}'`}
                    renderChip={(i, d) => (
                        <DeletableChip onRequestDelete={d} deleteButtonAccessibleName={`Delete ${i.Name}`}>
                            {i.Name}
                        </DeletableChip>
                    )}
                    onNew={(t: string) => {
                        if (drawerDetails) {
                            setDrawerDetails((currentDrawerDetails) => {
                                return { ...currentDrawerDetails, regions: drawerDetails.regions.concat(t) };
                            });
                        }
                    }}
                    onChange={(values: string[]) => {
                        if (drawerDetails) {
                            setDrawerDetails({ ...drawerDetails, regions: values });
                        }
                    }}
                    error={drawerDetails.regionValidationMessage}
                    accessibleName={"AWS region select"}
                ></RegionMultiSelect>
            </FormSection>
        </CloudConnectionsDrawer>
    );
};

export default AwsCloudConnectionDrawer;
