import { useEffect, useState } from "react";
import * as React from "react";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import { Icon } from "~/components/IconButton/IconButton";
import IconButton from "~/components/IconButton/index";
import IconButtonList from "~/components/IconButtonList/index";
import InputWithActions from "~/components/InputWithActions/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import generateSlug from "~/primitiveComponents/form/Slugs/generateSlug";
import Text from "~/primitiveComponents/form/Text/Text";
import type { TextProps } from "~/primitiveComponents/form/Text/Text";

export type SlugEditorProps = TextProps & {
    originalSlug: string;
    name?: string | undefined;
    additionalWarningMessage?: string | undefined;
    prefix?: string | undefined;
};

const SlugEditor: React.FC<SlugEditorProps> = ({ onChange: onChangeProp, originalSlug, name, additionalWarningMessage, prefix, ...rest }) => {
    const [currentValue, setCurrentValue] = useState(rest.value);
    const [validSlug, setValidSlug] = useState(rest.value);
    const [canEdit, setCanEdit] = useState(false);

    // If the original slug changes, we've likely saved, disable the editor
    useEffect(() => {
        setCanEdit(false);
    }, [originalSlug]);

    // Ensure any input is converted to a slug, but allowing for trailing dashes
    const onChange = (input: string) => {
        const newCurrentValue = generateSlug(input, false);
        setCurrentValue(newCurrentValue);

        // Store the current value but as a valid slug
        const newValidSlug = generateSlug(newCurrentValue);
        setValidSlug(newValidSlug);
        onChangeProp(newValidSlug);
    };

    // Once we lose focus, update the value to the valid one
    const onBlur = () => {
        onChange(validSlug);
    };

    const onEditClick = () => setCanEdit(true);

    const onCancelEditClick = () => {
        setCanEdit(false);
        onChange(originalSlug);
    };

    const onUpdateClick = () => {
        if (name) {
            const newSlug = generateSlug(name);
            onChange(newSlug);
            setCanEdit(true);
        }
    };

    const renderActions = () => {
        const buttons: Array<React.ReactElement> = [];

        if (!canEdit) buttons.push(<IconButton accessibleName={`Change slug`} toolTipContent={"Change"} onClick={onEditClick} icon={Icon.Edit} />);
        if (canEdit) buttons.push(<IconButton accessibleName={`Cancel editing slug`} toolTipContent={"Cancel"} onClick={onCancelEditClick} icon={Icon.Cancel} />);
        if (name && generateSlug(name) !== validSlug) buttons.push(<IconButton accessibleName={"Update slug to match name"} toolTipContent={"Update"} onClick={onUpdateClick} icon={Icon.Refresh} />);

        buttons.push(<CopyToClipboardButton value={prefix ? `${prefix}${validSlug}` : validSlug} />);

        return <IconButtonList buttons={buttons} />;
    };

    const renderPrefix = () => {
        if (prefix) {
            return {
                startAdornment: (
                    <>
                        <code>{prefix}</code>
                        &nbsp;
                    </>
                ),
            };
        }

        return {};
    };

    return (
        <>
            <InputWithActions input={<Text {...rest} inputProps={renderPrefix()} value={currentValue} onChange={onChange} onBlur={onBlur} disabled={!canEdit} />} actions={renderActions()} />
            {originalSlug !== validSlug && (
                <Callout title={"Be careful when changing a slug"} type={CalloutType.Warning}>
                    {additionalWarningMessage && (
                        <span>
                            {additionalWarningMessage}
                            <br />
                        </span>
                    )}
                    <span>Modifying a slug may impact any references to it. Any scripts referencing the old slug will need to be updated manually.</span>
                </Callout>
            )}
        </>
    );
};

export default SlugEditor;
