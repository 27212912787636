import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import type { StepInputComponent } from "@octopusdeploy/step-ui";
import React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { BindableAccountSelector, getAccountSelectorSummary } from "~/components/StepPackageEditor/Inputs/Components/AccountSelector/BindableAccountSelector";
import { ContainerImageSelector, getContainerImageSelectorSummary } from "~/components/StepPackageEditor/Inputs/Components/ContainerImageSelector/ContainerImageSelector";
import { getRadioButtonsSummary, RadioButtons } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/RadioButtons/RadioButtons";
import { getInlineListSummary, InlineList } from "~/components/StepPackageEditor/Inputs/Components/InlineList/InlineList";
import { getListSummary, List } from "~/components/StepPackageEditor/Inputs/Components/List/List";
import { getStepNumberSummary, StepNumber } from "~/components/StepPackageEditor/Inputs/Components/Number/Number";
import { getPackageSelectorSummary, StepPackageSelector } from "~/components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelector";
import { getSensitiveContentSummary, Sensitive } from "~/components/StepPackageEditor/Inputs/Components/Sensitive/Sensitive";
import { getTextContentSummary, Text } from "~/components/StepPackageEditor/Inputs/Components/Text/Text";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import { exhaustiveCheck } from "~/utils/exhaustiveCheck";
import { getStepCheckboxContentSummary, StepCheckbox } from "./Inputs/Components/Checkbox/Checkbox";
import { getSelectSummary, StepPackageSelect } from "./Inputs/Components/DiscriminatorComponents/Select/Select";
import type { InputSummary } from "./Summary/InputSummary";

export function createSummaryFactory<StepInputs>(inputs: ObjectRuntimeInputs<StepInputs>, getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition, inputDependencies: StepInputDependencies) {
    return (component: StepInputComponent): InputSummary => {
        switch (component.type) {
            case "sensitive":
                return getSensitiveContentSummary(component, inputs);
            case "package":
                return getPackageSelectorSummary(component, inputs);
            case "container-image":
                return getContainerImageSelectorSummary(component, inputs);
            case "text":
                return getTextContentSummary(component, inputs);
            case "radio-buttons":
                return getRadioButtonsSummary(inputs, component, getInputSchema);
            case "list":
                return getListSummary(component, inputs);
            case "inline-list":
                return getInlineListSummary(component, inputs);
            case "checkbox":
                return getStepCheckboxContentSummary(component, inputs);
            case "select":
                return getSelectSummary(inputs, component, getInputSchema);
            case "number":
                return getStepNumberSummary(component, inputs);
            case "account":
                return getAccountSelectorSummary(component, inputs, inputDependencies.accounts);
            default:
                return exhaustiveCheck(component, `Section content type not yet implemented`);
        }
    };
}

function createRenderedComponentFactory<StepInputs>(
    inputs: ObjectRuntimeInputs<StepInputs>,
    inputDependencies: StepInputDependencies,
    setInputs: (inputs: ObjectRuntimeInputs<StepInputs>) => void,
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition,
    getFieldError: (name: PathToInput) => string,
    doBusyTask: DoBusyTask
): (component: StepInputComponent) => React.ReactNode | null {
    return (component: StepInputComponent): React.ReactNode | null => {
        const localNames = "localNames" in inputDependencies ? inputDependencies.localNames : undefined;
        switch (component.type) {
            case "sensitive":
                return <Sensitive<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} />;
            case "package":
                return <StepPackageSelector<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} dependencies={inputDependencies} getFieldError={getFieldError} />;
            case "container-image":
                return <ContainerImageSelector<StepInputs> setInputs={setInputs} inputs={inputs} configuredStepUIProps={component} getFieldError={getFieldError} dependencies={inputDependencies} />;
            case "text":
                return <Text<StepInputs> input={component.input} label={component.label} note={component.note} inputs={inputs} setInputs={setInputs} localNames={localNames} getFieldError={getFieldError} />;
            case "radio-buttons":
                return <RadioButtons<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getInputSchema={getInputSchema} getFieldError={getFieldError} />;
            case "list":
                return (
                    <List<StepInputs>
                        configuredStepUIProps={component}
                        inputs={inputs}
                        setInputs={setInputs}
                        getInputSchema={getInputSchema}
                        inputDependencies={inputDependencies}
                        getFieldError={getFieldError}
                        note={component.note}
                        doBusyTask={doBusyTask}
                    />
                );
            case "inline-list":
                return <InlineList<StepInputs> configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} getInputSchema={getInputSchema} localNames={localNames} note={component.note} />;
            case "checkbox":
                return <StepCheckbox<StepInputs> input={component.input} label={component.label} note={component.note} inputs={inputs} setInputs={setInputs} localNames={localNames} getFieldError={getFieldError} />;
            case "account":
                return (
                    <BindableAccountSelector<StepInputs>
                        inputs={inputs}
                        setInputs={setInputs}
                        getFieldError={getFieldError}
                        configuredStepUIProps={component}
                        getInputSchema={getInputSchema}
                        dependencies={inputDependencies}
                        localNames={localNames}
                        doBusyTask={doBusyTask}
                    />
                );
            case "select":
                return (
                    <StepPackageSelect<StepInputs>
                        inputs={inputs}
                        setInputs={setInputs}
                        getInputSchema={getInputSchema}
                        getFieldError={getFieldError}
                        input={component.input}
                        label={component.label}
                        note={component.note}
                        options={component.options}
                    />
                );
            case "number":
                return <StepNumber<StepInputs> getInputSchema={getInputSchema} configuredStepUIProps={component} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} localNames={localNames} />;
            default:
                return exhaustiveCheck(component, `Section content type not yet implemented`);
        }
    };
}

export function createRenderedComponentAndSummaryForStepFactory<StepInputs>(
    inputs: ObjectRuntimeInputs<StepInputs>,
    inputDependencies: StepInputDependencies,
    setInputs: (inputs: ObjectRuntimeInputs<StepInputs>) => void,
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition,
    getFieldError: (name: PathToInput) => string,
    doBusyTask: DoBusyTask
): (component: StepInputComponent) => RenderedComponentAndSummary {
    const getSummary = createSummaryFactory(inputs, getInputSchema, inputDependencies);
    const getRenderedComponent = createRenderedComponentFactory(inputs, inputDependencies, setInputs, getInputSchema, getFieldError, doBusyTask);
    return (component: StepInputComponent): RenderedComponentAndSummary => ({
        summary: getSummary(component),
        renderedComponent: getRenderedComponent(component),
    });
}
