import React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { InsightsReportResource } from "~/client/resources/insightsReportResource";
import { NavigationButton, NavigationButtonType } from "~/components/Button";

interface Props {
    report: InsightsReportResource;
}

export function DownloadReportCsvButton({ report }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    const onClick = () => dispatchAction("Click Insights Report Download CSV");

    return <NavigationButton type={NavigationButtonType.Ternary} href={`/api/${report.SpaceId}/insights/reports/${report.Id}/deployments/csv`} label="Download CSV" external onClick={onClick} />;
}
