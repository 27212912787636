/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */

import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { RunbookSnapshotResource, RunbookResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import NavigationButton, { NavigationButtonType } from "~/components/Button/NavigationButton";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { PermissionCheck } from "~/components/PermissionCheck";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import LastPublishedChip from "./LastPublishedChip";
import styles from "./PublishButton.module.less";
import { useRunbookContext } from "./RunbookContext";
import { isRunbookConsumerOnly } from "./RunbookOverviewLayout";

export const publishingExplainedElement = <span>Publishing makes the runbook available to consumers and triggers.</span>;
export const processTabErrorElement = <span>The runbook can not be published until the error on the Process tab is resolved.</span>;

interface PublishedRunbookSnapshotState {
    publishedRunbookSnapshot: RunbookSnapshotResource;
    isRunbookRunTemplateModified: boolean;
    lookupComplete: boolean;
    version: number | null;
}

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<PublishedRunbookSnapshotState>>) => {
    return {
        onPublishedRunbookSnapshotUpdated: (publishedRunbookSnapshot: RunbookSnapshotResource, isRunbookRunTemplateModified: boolean, lookupComplete: boolean, version: number | null) =>
            setState((current) => ({ ...current, publishedRunbookSnapshot, isRunbookRunTemplateModified, lookupComplete, version })),
    };
};

const usePublishedRunbookSnapshotSetup = (doBusyTask: DoBusyTask, runbook: RunbookResource, processVersion: number | null) => {
    const [state, setState] = React.useState<PublishedRunbookSnapshotState>({
        publishedRunbookSnapshot: null!,
        isRunbookRunTemplateModified: false,
        lookupComplete: false,
        version: null,
    });

    const updaters = getStateUpdaters(setState);

    const refreshPublishedRunbookSnapshot = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            let publishedRunbookSnapshot: RunbookSnapshotResource;
            let isRunbookRunTemplateModified = false;
            if (runbook.PublishedRunbookSnapshotId) {
                publishedRunbookSnapshot = await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId);
                const runbookRunTemplate = await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot);
                isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
            }
            updaters.onPublishedRunbookSnapshotUpdated(publishedRunbookSnapshot!, isRunbookRunTemplateModified, true, processVersion);
        },
        [runbook.PublishedRunbookSnapshotId, processVersion]
    );

    return {
        actions: { ...updaters, refreshActionTemplates: refreshPublishedRunbookSnapshot },
        state,
        setState,
    };
};

export interface PublishButtonProps {
    doBusyTask: DoBusyTask;
    isDisabled?: boolean;
}

export const PublishButton: React.FC<PublishButtonProps> = ({ doBusyTask, isDisabled }) => {
    const projectContext = useProjectContext();
    const runbookContext = useRunbookContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;

    const processContext = useOptionalProcessContext();
    const process = processContext?.state.model.process;
    const hasGitVariablesError = !!projectContext.state.gitVariablesHasError;

    // If a published version exists, load it and determine if there's been any changes since it was published.
    const value = usePublishedRunbookSnapshotSetup(doBusyTask, runbook!, process ? process.Version : null);
    if (!value.state.lookupComplete) {
        return <div>{StringHelper.ellipsis}</div>;
    }

    const toolTipContent = (
        <>
            {publishingExplainedElement} {hasGitVariablesError ? processTabErrorElement : null}
        </>
    );

    const publishedRunbookSnapshot = value.state.publishedRunbookSnapshot;
    const isRunbookRunTemplateModified = value.state.isRunbookRunTemplateModified;
    const showChangeSnapshotWarnings = !isRunbookConsumerOnly(project.Id);
    const showOutOfDate = isRunbookRunTemplateModified && showChangeSnapshotWarnings;

    const lastPublishedChip = <LastPublishedChip projectSlug={project.Slug} publishedRunbookSnapshot={publishedRunbookSnapshot} isOutOfDate={showOutOfDate} />;
    const publishButton = (
        <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
            <div className={styles.container}>
                <ToolTip content={toolTipContent}>
                    <NavigationButton label={"Publish..."} href={routeLinks.project(project.Slug).operations.runbook(runbook!.Id).runbookSnapshotCreate} type={NavigationButtonType.Ternary} disabled={isDisabled || hasGitVariablesError} />
                </ToolTip>
            </div>
        </PermissionCheck>
    );

    //TODO: OPS
    // - Consider moving most of this setup bits into a hook / function so we can test this independently from the component.
    // - See https://github.com/OctopusDeploy/OctopusDeploy/pull/4915#pullrequestreview-328664248

    if (publishedRunbookSnapshot && !isRunbookRunTemplateModified) {
        return lastPublishedChip;
    }
    if (publishedRunbookSnapshot && isRunbookRunTemplateModified) {
        return (
            <>
                {lastPublishedChip}
                {publishButton}
            </>
        );
    }
    return publishButton;
};

export default PublishButton;
