import * as React from "react";
import type { AnalyticActionDispatcher, AnalyticTrackedActionDispatcher, ActionEvent, AnalyticErrorCallback } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import AdvancedProjectSection from "~/areas/projects/components/Projects/AdvancedProjectSection";
import type { ResourcesById } from "~/client/repositories/basicRepository";
import type { ProjectResource, EnvironmentResource, LifecycleResource, ProjectGroupResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { session, repository } from "~/clientInstance";
import { ActionButton, ActionButtonType } from "~/components/Button/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { required, Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

interface CloneProjectDialogProps {
    clone: ProjectResource;
    projectCreated: (project: ProjectResource) => void;
}

type InternalCloneProjectDialogProps = CloneProjectDialogProps & {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
};

interface CloneProjectDialogState extends DataBaseComponentState {
    name: string;
    description: string;
    projectGroups: ProjectGroupResource[];
    projectGroupId: string | undefined;
    lifecycles: LifecycleResource[];
    selectedLifecycle: LifecycleResource | undefined;
    showLifecycleMap: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;

    showAdvanced: boolean;
}

class CloneProjectInternal extends DataBaseComponent<InternalCloneProjectDialogProps, CloneProjectDialogState> {
    constructor(props: InternalCloneProjectDialogProps) {
        super(props);
        this.state = {
            name: "",
            description: props.clone.Description,
            projectGroupId: props.clone.ProjectGroupId,
            selectedLifecycle: undefined,
            projectGroups: [],
            lifecycles: [],
            environmentsById: {},
            showLifecycleMap: false,
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [projectGroups, lifecycles, environmentsById] = await Promise.all([
                repository.ProjectGroups.all(),
                isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.all() : Promise.resolve([]),
                repository.Environments.allById(),
            ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map((l) => repository.Lifecycles.preview(l)));
            const lifecycle = lifecyclePreviews.find((x: LifecycleResource) => x.Id === this.props.clone.LifecycleId);
            const projectGroupId = this.props.clone.ProjectGroupId;

            this.setState({
                lifecycles: lifecyclePreviews,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecyclePreviews[0],
                projectGroupId,
            });
        });
    }

    async save() {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Project",
            };

            await this.props.trackAction("Clone Project", ev, async (cb: AnalyticErrorCallback) => {
                if (!this.state.projectGroupId) {
                    this.setValidationErrors("You need to select a project group");
                    cb("Missing Project Group");
                    return false;
                }

                if (!this.state.selectedLifecycle) {
                    this.setValidationErrors("You need to select a lifecycle");
                    cb("Missing Lifestyle");
                    return false;
                }

                const deploymentSettings = await repository.Projects.getDeploymentSettings(this.props.clone);
                const result = await repository.Projects.create(
                    {
                        Name: this.state.name,
                        Description: this.state.description,
                        ProjectGroupId: this.state.projectGroupId ?? this.props.clone.ProjectGroupId,
                        LifecycleId: this.state.selectedLifecycle?.Id ?? this.props.clone.LifecycleId,
                        ProjectConnectivityPolicy: deploymentSettings.ConnectivityPolicy,
                    },
                    { clone: this.props.clone.Id }
                );

                // refresh permissions to include the new projects
                if (session.currentUser) {
                    const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
                    session.refreshPermissions(permissionSet);
                }

                this.props.projectCreated(result);
                return true;
            });
        });

        return false;
    }

    render() {
        const showAdvancedButton = this.state.lifecycles.length <= 1;

        return (
            <SaveDialogLayout
                title="Clone Project"
                busy={this.state.busy}
                errors={this.errors}
                saveButtonDisabled={this.state.name.length === 0}
                saveButtonLabel={"Clone"}
                busyButtonLabel={"Cloning..."}
                onSaveClick={() => this.save()}
                onCancelClick={() => {
                    this.props.dispatchAction("Cancel cloning Project", { resource: "Project", action: Action.Cancel });
                    return true;
                }}
            >
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to clone a project
                        </Callout>
                    }
                >
                    {this.props.clone.IsVersionControlled && (
                        <>
                            <Callout title="Clone project is version controlled enabled" type={CalloutType.Warning}>
                                When cloning a version controlled enabled project, we will also clone the version control setting (the repository url and authentication settings). Please review these after cloning.
                            </Callout>
                            <div style={{ marginTop: "1rem" }} />
                        </>
                    )}
                    <Text label="New project name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true} />

                    {showAdvancedButton && (
                        <ActionButton
                            label={this.state.showAdvanced ? "Hide Advanced" : "Show Advanced"}
                            type={ActionButtonType.Ternary}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showAdvanced: !this.state.showAdvanced });
                            }}
                        />
                    )}
                    {(!showAdvancedButton || this.state.showAdvanced) && (
                        <AdvancedProjectSection {...this.state} onDescriptionChanged={this.handleDescriptionChanged} onProjectGroupChanged={(newValue) => this.setState({ projectGroupId: newValue })} onLifecycleChanged={this.handleLifeCycleChange} />
                    )}
                </PermissionCheck>
            </SaveDialogLayout>
        );
    }

    private handleLifeCycleChange = (value: string | undefined) => {
        const lifecycles = this.state.lifecycles?.filter((l) => l.Id === value) || [];
        this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0] : undefined });
    };

    private handleDescriptionChanged = (description: string) => {
        this.setState({ description });
    };
}

const CloneProject: React.FC<CloneProjectDialogProps> = (props) => {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();

    return <CloneProjectInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
};

export default CloneProject;
