/* eslint-disable @typescript-eslint/no-explicit-any */

import moment from "moment";
import * as React from "react";
import { TaskState } from "~/client/resources";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "../../../../primitiveComponents/dataDisplay/ToolTip/index";
import iconStyle from "./iconStyle";

interface TaskStatusIconProps {
    item: TaskStatusIconPropsItem;
    smallIcon?: boolean;
    iconOnly?: boolean;
}

interface TaskStatusIconPropsItem {
    HasPendingInterruptions: boolean;
    HasWarningsOrErrors: boolean;
    IsCompleted: boolean;
    LastUpdatedTime?: string;
    StartTime?: string | null;
    QueueTime?: string;
    QueueTimeExpiry?: string;
    Duration?: string;
    ErrorMessage?: string;
    State: TaskState;
}

function momentFromFilter(fromDate: any, toDate: any) {
    if (!fromDate) {
        return null;
    }

    const fromMoment = moment(fromDate);
    const toMoment = toDate ? moment(toDate) : moment();

    const diff = fromMoment.diff(toMoment);
    const duration = moment.duration(diff);
    const mins = duration.minutes();
    const hours = duration.hours();
    const days = Math.floor(duration.asDays());
    let result = mins === 1 ? "1 minute" : mins + " minutes";
    result = (hours !== 0 ? (hours === 1 ? "1 hour" : hours + " hours") + " and " : "") + result;
    result = (days !== 0 ? (days === 1 ? "1 day" : days + " days") + ", " : "") + result;
    return result;
}

function inspector(task: TaskStatusIconPropsItem, theme: OctopusTheme) {
    const pendingInterruptions = task.HasPendingInterruptions;
    const warningsOrErrors = task.HasWarningsOrErrors;
    const duration = task.Duration;
    const errorMessage = task.ErrorMessage;
    let description: string = task.State;
    let tip = "";
    let html = <em />;

    if (pendingInterruptions && !task.IsCompleted) {
        html = <em className="fa-solid fa-eye" />;
        description = "Waiting...";
        tip = "This task is waiting for manual intervention. Duration: " + duration;
    } else if (task.State === TaskState.Cancelling) {
        html = (
            <span style={{ position: "relative" }}>
                <em className="fa-solid fa-user" style={{ marginRight: "0.4rem" }} />
                <span className="status-overlay" style={{ position: "absolute", bottom: "-0.15rem", right: "-0.1rem", fontSize: "12px", borderRadius: "15px", backgroundColor: theme.darkBackground, width: "13px", height: "13px", marginTop: "0px" }}>
                    <em className="fa-solid fa-spinner fa-pulse" style={{ marginTop: "1px", position: "absolute", marginLeft: "1px" }} />
                </span>
            </span>
        );
        description = "Cancelling";
        tip = "Cancellation has been requested, attempting to stop the task.";
    } else if (task.State === TaskState.Queued) {
        html = <em className="fa-regular fa-clock" />;
        description = "Queued...";
        const timeQueued = moment(task.QueueTime).calendar();
        tip = "This task was queued to run " + timeQueued;
    } else if (task.State === TaskState.Executing) {
        html = <em className="fa-solid fa-spinner fa-pulse" />;
        tip = "This task is being executed by the Octopus Server. Duration: " + duration;
    } else if (task.State === TaskState.Failed) {
        html = <em className="fa-solid fa-xmark" />;
        tip = "An error occurred while processing this task";
        tip = errorMessage && errorMessage.length > 0 ? tip + ": " + errorMessage : tip + ". See the task details for more information.";
    } else if (task.State === TaskState.Canceled) {
        html = <em className="fa-solid fa-user-times" />;
        tip = "This task was canceled.";
        tip = errorMessage && errorMessage.length > 0 ? tip + " " + errorMessage : tip;
    } else if (task.State === TaskState.TimedOut) {
        html = <em className="fa-regular fa-clock" />;
        description = "Timed out";
        const queueTime = moment(task.QueueTime).calendar();
        const expiryTime = momentFromFilter(task.QueueTimeExpiry, task.QueueTime);
        const lastUpdated = moment(task.LastUpdatedTime).calendar();
        tip = "The task was scheduled to run " + queueTime + " within " + expiryTime + " but the Octopus Server " + "attempted to run the task " + lastUpdated + ". This can happen when the Octopus Server is too busy to process the task.";
    } else if (task.State === TaskState.Success) {
        if (warningsOrErrors) {
            html = (
                <span style={{ position: "relative" }}>
                    <em className="fa-solid fa-check" />
                    <span className="status-overlay" style={{ position: "absolute", bottom: "-8px", right: "-0.0625rem", fontSize: "10px" }}>
                        <em className="fa-solid fa-exclamation-triangle" />
                    </span>
                </span>
            );
            tip = "This task completed successfully, but warnings or errors were reported in the log. Duration: " + duration;
        } else {
            html = <em className="fa-solid fa-check" />;
            tip = "This task completed without any problems. Duration: " + duration;
        }
    }

    return {
        tip,
        html,
        description,
    };
}

const TaskStatusIcon: React.FC<TaskStatusIconProps> = (props) => {
    const theme = useOctopusTheme();

    const { tip, html, description } = inspector(props.item, theme);
    const { iconOnly } = props;

    const icon = <span className={iconStyle(props.item, props.smallIcon)}>{html}</span>;

    if (iconOnly) {
        return icon;
    }

    return <ToolTip content={tip}>{icon}</ToolTip>;
};

TaskStatusIcon.displayName = "TaskStatusIcon";
export { TaskStatusIcon, iconStyle, TaskStatusIconPropsItem };
