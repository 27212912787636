import cn from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import styles from "~/components/ProjectBasedActivation/styles.module.less";

interface ContentPanelProps {
    className?: string;
}

function ContentPanel({ className, children }: PropsWithChildren<ContentPanelProps>) {
    return <div className={cn(styles.contentPanel, styles.padded, className)}>{children}</div>;
}

export default ContentPanel;
