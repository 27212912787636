import React from "react";
import { useFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { FeedResource } from "~/client/resources/feedResource";
import type { PackageReference } from "~/client/resources/packageReference";
import { Permission } from "~/client/resources/permission";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { useKeyedItemAccess } from "~/components/KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "~/components/KeyAccessProvider/types";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { logger } from "~/utils/logging/logger";

interface DisplayFeedNameProps {
    pkg: PackageReference;
}

interface DisplayFeedNameContextProps extends KeyedItemProps {
    feeds: FeedResource[];
}

type Props = DisplayFeedNameProps & DisplayFeedNameContextProps;

interface DisplayFeedNameState extends DataBaseComponentState {
    feedResult: FeedResource | "Is Loading" | "No Permission";
}

class DisplayFeedNameInternal extends DataBaseComponent<Props, DisplayFeedNameState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            feedResult: "Is Loading",
        };
    }

    async componentDidMount() {
        if (!isAllowed({ permission: Permission.FeedView })) {
            this.setState({ feedResult: "No Permission" });
            return;
        }

        if (this.props.pkg.FeedId.includes("#{")) {
            return;
        }

        await this.doBusyTask(async () => {
            const feed = this.props.feeds.find((f) => f[this.props.itemKey] === this.props.pkg.FeedId);
            if (feed) {
                this.setState({ feedResult: feed });
            } else {
                logger.info("Failed to lookup feed for package {package}.", { package: this.props.pkg });
            }
        });
    }

    render() {
        const feedResult = this.state.feedResult;
        const pkg = this.props.pkg;

        if (feedResult === "Is Loading" || pkg.FeedId.includes("#{")) {
            return <em>{this.props.pkg.FeedId}</em>;
        } else if (feedResult === "No Permission") {
            return <strong>{`(Feed unknown: ${Permission.FeedView} permission required)`}</strong>;
        }

        return <strong>{feedResult.Name}</strong>;
    }
}

const DisplayFeedName: React.FC<DisplayFeedNameProps> = (props) => {
    const feeds = useFeedsFromContext();
    const itemKey = useKeyedItemAccess();
    return <DisplayFeedNameInternal {...props} feeds={feeds} itemKey={itemKey} />;
};

export { DisplayFeedName };
