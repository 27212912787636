import type { RouteArgs } from "~/client/resolver";
import type {
    RunbookRunPreviewRequests,
    DeploymentPromotionTarget,
    EnvironmentResource,
    NewRunbookResource,
    RunbookResource,
    ResourceCollection,
    RunbookRunPreviewResource,
    RunbookRunTemplateResource,
    RunbookSnapshotResource,
    RunbookSnapshotTemplateResource,
} from "~/client/resources";
import type { Client } from "../client";
import type { AllArgs } from "./basicRepository";
import BasicRepository from "./basicRepository";

type RunbookRepositoryListArgs = {
    skip?: number;
    take?: number;
    orderBy?: string;
} & RouteArgs;

type RunbookRepositoryAllArgs = {
    projectIds?: string[];
} & AllArgs;

class RunbookRepository extends BasicRepository<RunbookResource, NewRunbookResource, RunbookRepositoryListArgs, RunbookRepositoryAllArgs> {
    constructor(client: Client) {
        super("Runbooks", client);
    }
    getRunbookSnapshotTemplate(runbook: RunbookResource): Promise<RunbookSnapshotTemplateResource> {
        return this.client.get<RunbookSnapshotTemplateResource>(runbook.Links["RunbookSnapshotTemplate"]);
    }
    getRunbookRunTemplate(runbook: RunbookResource): Promise<RunbookRunTemplateResource> {
        return this.client.get<RunbookRunTemplateResource>(runbook.Links["RunbookRunTemplate"]);
    }
    getRunbookRunPreview(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<RunbookRunPreviewResource>(promotionTarget.Links["RunbookRunPreview"], { includeDisabledSteps: true });
    }
    getRunbookSnapshots(runbook: RunbookResource, args?: { skip?: number; take?: number } & RouteArgs): Promise<ResourceCollection<RunbookSnapshotResource>> {
        return this.client.get<ResourceCollection<RunbookSnapshotResource>>(runbook.Links["RunbookSnapshots"], args);
    }
    getRunbookEnvironments(runbook: RunbookResource): Promise<EnvironmentResource[]> {
        return this.client.get<EnvironmentResource[]>(runbook.Links["RunbookEnvironments"]);
    }
    runbookRunTenantPreviews(runbook: RunbookResource, runbookRunPreviews: RunbookRunPreviewRequests): Promise<RunbookRunPreviewResource[]> {
        return this.client.post(runbook.Links["RunbookRunTenantPreviews"], runbookRunPreviews);
    }
}

export default RunbookRepository;
