/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import styles from "~/areas/tasks/components/Task/TaskSummary/style.module.less";
import type { TaskResource, SpaceResource } from "~/client/resources/index";
import { session } from "~/clientInstance";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import { SimpleList } from "~/components/List/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import TaskDetails from "~/components/TaskDetails/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import routeLinks from "~/routeLinks";

interface TaskQueueCalloutProps {
    tasksAtLimit: TaskResource[];
    queueLength: number;
    space?: SpaceResource;
}

interface TaskLimitListProps {
    tasks: TaskResource[];
}

const renderRow = (item: TaskResource) => <TaskDetails task={item} stripTopBottomPadding={true} />;

const getTaskRoute = (item: TaskResource) => routeLinks.forSpace(item.SpaceId).task(item).root;

const TaskLimitList: React.FC<TaskLimitListProps> = ({ tasks }: TaskLimitListProps) => {
    const [open, setOpen] = React.useState(false);

    if (tasks.length === 0) {
        return null;
    }

    return (
        <div>
            <ActionButton onClick={() => setOpen(!open)} label={(open ? "HIDE" : "SHOW") + " TASKS ON NODE/CLUSTER"} type={ActionButtonType.Ternary} />
            {open && (
                <div className={styles.primaryBackground}>
                    <SimpleList<TaskResource> items={tasks} onRow={renderRow} onRowRedirectUrl={getTaskRoute} />
                </div>
            )}
        </div>
    );
};

function renderCalloutContent(queueLength: number, taskLimitReached: boolean, space?: SpaceResource): React.ReactNode {
    if (!queueLength && !taskLimitReached) {
        return null;
    }

    if (space && space.TaskQueueStopped) {
        return (
            <>
                Task processor for Space <strong>{space.Name}</strong> has been stopped. {contactSpaceManagerMessage(space)}
                <ExternalLink href="SpacesTaskQueue"> Learn More</ExternalLink>
            </>
        );
    }

    if (taskLimitReached) {
        return <>This task is queued behind {`${queueLength} other task${queueLength > 1 ? "s" : ""}`}, and should start once the node or cluster can process more tasks.</>;
    }

    if (queueLength <= 0) {
        return <>This task is next in the queue and will be executed as soon as possible.</>;
    }

    return <>This task is queued behind {`${queueLength} other task${queueLength > 1 ? "s" : ""}`}.</>;
}

function contactSpaceManagerMessage(space?: SpaceResource): React.ReactNode {
    const isSpaceManager = session.currentPermissions!.isSpaceManager(space!);

    return isSpaceManager ? (
        <>
            <InternalLink to={routeLinks.configuration.space(space!.Id)}> Re-enable the task processor </InternalLink> to continue executing tasks.
        </>
    ) : (
        <>Re-enable the task processor to continue executing tasks. Please contact your Space Manager for more information.</>
    );
}

function getTaskCalloutTitle(taskLimitReached: boolean): string {
    let title = "Task has not yet started.";

    if (taskLimitReached) {
        title = `${title} Task limit reached.`;
    }

    return title;
}

export default (props: TaskQueueCalloutProps) => {
    const { space, queueLength, tasksAtLimit } = props;
    const taskLimitReached = tasksAtLimit.length > 0;

    return (
        <Callout type={CalloutType.Information} title={getTaskCalloutTitle(taskLimitReached)}>
            {renderCalloutContent(queueLength, taskLimitReached, space)}
            <TaskLimitList tasks={tasksAtLimit} />
        </Callout>
    );
};
