/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable custom-portal-rules/no-restricted-imports */

import cn from "classnames";
import { List as MaterialList, ListItem } from "material-ui";
import * as React from "react";
import type { match } from "react-router";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import ListInternalLink from "~/components/Navigation/InternalLink/ListInternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import Divider from "~/primitiveComponents/dataDisplay/Divider/Divider";
import NumberedPagingBar from "../PagingBaseComponent/NumberedPagingBar";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import { Section } from "../Section/Section";
import styles from "./style.module.less";

interface SimpleListProps<R> {
    empty?: React.ReactNode;
    match?: match<{}>;
    items: R[];
    filterHintText?: string;
    autoFocusOnFilterSearch?: boolean;
    disableListItem?: boolean;
    pageSize?: number;
    listClassName?: string;
    selectedIndex?: number;
    hideItemPadding?: boolean;
    onRow(item: R, index: number): React.ReactNode;
    onRowRedirectUrl?(item: R): string | null;
    onFilter?(filter: string, item: R): boolean;
    onClick?(item: R): void;
}

interface SimpleListState {
    filter?: string;
    currentPageIndex: number;
}

//eslint-disable-next-line react/no-unsafe
export abstract class SimpleList<R> extends React.Component<SimpleListProps<R>, SimpleListState> {
    constructor(props: SimpleListProps<R>) {
        super(props);
        this.state = { currentPageIndex: 0 };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SimpleListProps<R>) {
        if (nextProps.pageSize) {
            const newLastPageIndex = nextProps.items.length === 0 ? 1 : Math.ceil(nextProps.items.length / nextProps.pageSize);
            if (newLastPageIndex < this.state.currentPageIndex) {
                this.setState({ currentPageIndex: newLastPageIndex });
            }
        }
    }

    onFilter(item: R): boolean {
        const filter = this.state.filter;
        if (!filter || filter === "" || !this.props.onFilter) {
            return true;
        }
        return this.props.onFilter(this.state.filter!, item);
    }

    renderEmpty() {
        return this.props.empty ? <Section>{this.props.empty}</Section> : <NoResults />;
    }

    render() {
        if (!this.props.items) {
            return null;
        }

        let filteredList = this.props.items.filter((item) => this.onFilter(item));
        const filteredListCount = filteredList.length;

        if (this.props.pageSize) {
            const firstItemIndex = this.props.pageSize * this.state.currentPageIndex;
            filteredList = filteredList.slice(firstItemIndex, firstItemIndex + this.props.pageSize);
        }

        return (
            <MaterialList className={this.props.listClassName}>
                {this.props.onFilter && this.renderFilterSearchComponents()}
                {filteredList.length === 0 && <div className={styles.emptyList}>{this.renderEmpty()}</div>}
                {filteredList.map((item, index) => this.buildItem(item, index))}
                {this.props.pageSize && (
                    <NumberedPagingBar currentPageIndex={this.state.currentPageIndex} totalItems={filteredListCount} pageSize={this.props.pageSize} onPageSelected={(skip, currentPageIndex) => this.setState({ currentPageIndex })} />
                )}
            </MaterialList>
        );
    }

    private buildItem(item: R, index: number) {
        const redirectUrl = getNavigationUrl(this.props, item);
        const nodeRow = this.props.onRow(item, index);
        const style = this.props.hideItemPadding ? { padding: "0 0 1px 0" } : {};
        const disabled = this.props.disableListItem || !redirectUrl;
        // This is a workaround as if you pass disableFocusRipple and disableTouchRipple into ListItem
        // when disabled === true an error will be written to console eg:
        // "Warning: React does not recognize the 'disableFocusRipple' prop on a DOM element.
        // If you intentionally want it to appear in the DOM as a custom attribute,
        // spell it as lowercase 'disablefocusripple' instead. If you accidentally passed it
        // from a parent component, remove it from the DOM element."
        const disableRippleProps = !disabled ? { disableTouchRipple: DISABLE_LEGACY_MUI_RIPPLES, disableFocusRipple: DISABLE_LEGACY_MUI_RIPPLES } : {};
        const nodes = [
            <ListItem
                key={0}
                className={cn(styles.simpleListItem, this.props.selectedIndex === index ? styles.selectedListItem : null)}
                style={style}
                disabled={disabled}
                containerElement={redirectUrl && <ListInternalLink to={redirectUrl} />}
                onClick={(_) => this.props.onClick && this.props.onClick(item)}
                {...disableRippleProps}
            >
                {nodeRow}
            </ListItem>,
        ];

        if (index < this.props.items.length - 1) {
            nodes.push(<Divider />);
        }

        return React.Children.toArray(nodes);
    }

    private renderFilterSearchComponents() {
        return (
            <Section>
                <FilterSearchBox placeholder="Filter..." onChange={(filter) => this.setState({ filter, currentPageIndex: 0 })} autoFocus={this.props.autoFocusOnFilterSearch} />
            </Section>
        );
    }
}
