import type { LogLevel, LogLevelsWithNoError, LogLevelsWithPossibleError, PropertyValue, PropertyValues } from "~/utils/logging/LogEvent";
import type { LoggingSink } from "~/utils/logging/LoggingSink";

type GetTokens<T> = T extends `${infer Pre}{${infer Token}}${infer Post}` ? Token | GetTokens<Post> : never;
type IgnoreEscapedTokens<T> = T extends `${infer Pre}{{${infer IgnoredToken}}}${infer Post}` ? IgnoreEscapedTokens<`${Pre}${Post}`> : T;
type FilterInvalidTokens<T> = T extends `${infer Pre}{${infer Post}` | `${infer Pre}}${infer Post}` | "" ? never : T;

type LogTemplateProperties<T> = FilterInvalidTokens<GetTokens<IgnoreEscapedTokens<T>>>;

export type LogTemplatePropertyValues<T extends string> = PropertyValues & {
    [key in LogTemplateProperties<T>]: PropertyValue;
};

type Log = <T extends string>(messageTemplate: T, ...messageProperties: LogTemplateProperties<T> extends never ? [PropertyValues?] : [LogTemplatePropertyValues<T>]) => void;
type LogWithError = <T extends string>(error: Error, messageTemplate: T, ...messageProperties: LogTemplateProperties<T> extends never ? [PropertyValues?] : [LogTemplatePropertyValues<T>]) => void;
type LogWithPossibleError = Log & LogWithError;
type UnstructuredLog = (logLevel: LogLevel, message: string, ...optionalParams: PropertyValue[]) => void;

export interface Logger {
    forContext: (propertyValues: PropertyValues) => Logger;
    verbose: Log;
    debug: Log;
    info: Log;
    warn: LogWithPossibleError;
    error: LogWithPossibleError;
    fatal: LogWithPossibleError;
    unstructuredLogEvent: UnstructuredLog;
}

export function createLogger(sink: LoggingSink, getContextualPropertyValues?: () => PropertyValues): Logger {
    function createLogLevelHandler(logLevel: LogLevelsWithNoError): Log {
        return (messageTemplate: string, propertyValues?: PropertyValues) => {
            sink.receiveLogEvent({
                logLevel,
                messageTemplate,
                propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues ?? {}),
                timestamp: new Date(),
            });
        };
    }

    function createLogLevelHandlerWithPossibleError(logLevel: LogLevelsWithPossibleError): LogWithPossibleError {
        function logWithPossibleError(error: Error, messageTemplate: string, propertyValues?: PropertyValues): void;
        function logWithPossibleError(messageTemplate: string, propertyValues?: PropertyValues): void;
        function logWithPossibleError(errorOrTemplate: Error | string, messageTemplateOrPropertyValues: string | PropertyValues | undefined, propertyValues?: PropertyValues) {
            if (typeof errorOrTemplate === "string") {
                if (typeof messageTemplateOrPropertyValues === "string") {
                    throw new Error("Expected the second argument to be a set of property values, but it wasn't");
                }
                sink.receiveLogEvent({
                    logLevel,
                    messageTemplate: errorOrTemplate,
                    propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, messageTemplateOrPropertyValues ?? {}),
                    timestamp: new Date(),
                    stack: new Error().stack,
                });
            } else {
                if (typeof messageTemplateOrPropertyValues !== "string") {
                    throw new Error("Expected the second argument to be a message template, but it wasn't");
                }
                sink.receiveLogEvent({
                    logLevel,
                    messageTemplate: messageTemplateOrPropertyValues,
                    propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues ?? {}),
                    timestamp: new Date(),
                    error: errorOrTemplate,
                    stack: new Error().stack,
                });
            }
        }

        return logWithPossibleError;
    }

    return {
        verbose: createLogLevelHandler("verbose"),
        debug: createLogLevelHandler("debug"),
        info: createLogLevelHandler("information"),
        warn: createLogLevelHandlerWithPossibleError("warning"),
        error: createLogLevelHandlerWithPossibleError("error"),
        fatal: createLogLevelHandlerWithPossibleError("fatal"),
        unstructuredLogEvent(logLevel: LogLevel, message, ...optionalParams) {
            sink.receiveLogEvent({
                logLevel,
                message,
                messageArguments: optionalParams,
                timestamp: new Date(),
                context: getContextualPropertyValues?.() ?? {},
            });
        },
        forContext: (propertyValues: PropertyValues) => {
            return createLogger(sink, () => mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues));
        },
    };
}

function mergePropertyValues(...propertyValues: PropertyValues[]): PropertyValues {
    return propertyValues.reduce((p, c) => ({ ...p, ...c }), {});
}
