import React from "react";
import ReactDiffViewer from "react-diff-viewer";
import { useOctopusTheme } from "~/components/Theme";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";

export interface DisplayDiffProps {
    oldValue: string;
    newValue: string;
    splitView?: boolean;
}

export const DisplayDiff: React.FC<DisplayDiffProps> = React.memo((props) => {
    const theme = useOctopusTheme();
    const palette = useThemePaletteType();
    const isDarkTheme = palette === "dark";

    const baseEditorColors = {
        diffViewerBackground: theme.primaryBackground,
        diffViewerColor: theme.primaryText,
        addedBackground: theme.diffAddedBackground,
        addedColor: theme.primaryText,
        removedBackground: theme.diffRemovedBackground,
        removedColor: theme.primaryText,
        wordAddedBackground: theme.diffWordAddedBackground,
        wordRemovedBackground: theme.diffWordRemovedBackground,
        addedGutterBackground: theme.diffWordAddedBackground,
        removedGutterBackground: theme.diffWordRemovedBackground,
        gutterBackground: theme.paper0,
        highlightBackground: theme.diffHighlight,
        highlightGutterBackground: theme.diffHighlight,
        codeFoldGutterBackground: theme.diffInfoBackground,
        codeFoldBackground: theme.diffInfoBackground,
        emptyLineBackground: theme.primaryBackground,
        gutterColor: theme.primaryText,
        addedGutterColor: theme.primaryText,
        removedGutterColor: theme.primaryText,
        codeFoldContentColor: theme.primaryText,
        diffViewerTitleBackground: theme.primaryBackground,
        diffViewerTitleColor: theme.primaryText,
        diffViewerTitleBorderColor: "#eee",
    };

    return (
        <ReactDiffViewer
            styles={{
                variables: {
                    dark: {
                        ...baseEditorColors,
                        addedGutterBackground: theme.successBackground,
                        removedGutterBackground: theme.dangerBackground,
                        addedBackground: theme.successBackground,
                        addedColor: theme.successText,
                        removedColor: theme.dangerText,
                        removedBackground: theme.dangerBackground,
                        wordRemovedBackground: theme.diffWordRemovedBackground,
                        codeFoldBackground: theme.primaryBackground,
                        codeFoldGutterBackground: theme.secondaryBackground,
                        gutterBackgroundDark: theme.paper3,
                    },
                    light: {
                        ...baseEditorColors,
                    },
                },
                gutter: {
                    "& pre": {
                        wordBreak: "keep-all",
                    },
                    "&:hover": {
                        color: theme.linkTextHover,
                    },
                },
                contentText: {
                    color: theme.SectionBodyText,
                },
                diffContainer: {
                    pre: {
                        lineHeight: "0.875rem",
                        background: "transparent",
                    },
                },
            }}
            useDarkTheme={isDarkTheme}
            oldValue={props.oldValue}
            newValue={props.newValue}
            splitView={props.splitView}
        />
    );
});

export default DisplayDiff;
