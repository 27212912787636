import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

export function InsightsEarlyAccessCallout() {
    return (
        <Callout title="Early Access" type={CalloutType.Warning}>
            This feature is still in development. We'd love to hear <ExternalLink href="InsightsFeedbackForm"> your feedback</ExternalLink> after using the Insights feature.
        </Callout>
    );
}
